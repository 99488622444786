import React, { memo } from 'react';

import { ID, UUID } from '../../../../../types';

import { IndexTableCell } from '../IndexTableCell';

interface IndexTableRowTagWithIDType {
  id: ID;
  uuid?: never;
  localizedName?: string;
  name?: string;
}

interface IndexTableRowTagWithUUIDType {
  id?: never;
  uuid: UUID;
  localizedName?: string;
  name?: string;
}

export type IndexTableRowTagType =
  | IndexTableRowTagWithIDType
  | IndexTableRowTagWithUUIDType;

interface IndexTableRowTagsProps {
  tags: IndexTableRowTagType[];
}

function IndexTableRowTags({ tags }: IndexTableRowTagsProps) {
  return (
    <IndexTableCell>
      <div className="flex flex-wrap">
        {tags.map((tag) => (
          <span
            key={tag.uuid || tag.id}
            className="inline-flex items-center px-2 py-0.5 m-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800 dark:text-gray-100 dark:bg-gray-700 dark:bg-opacity-50"
          >
            {tag.localizedName || tag.name}
          </span>
        ))}
      </div>
    </IndexTableCell>
  );
}

export default memo<IndexTableRowTagsProps>(IndexTableRowTags);
