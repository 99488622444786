import React from 'react';

import { ClassName } from '../../../../../types';

import { IndexTableCell } from '../IndexTableCell';

import { MoneyHelper } from '../../../../../helpers/MoneyHelper';

interface IndexTableCellMoneyProps {
  divClassName?: ClassName;
  useSuffix?: boolean;
  value: number;
}

function IndexTableCellMoney({
  divClassName,
  useSuffix = false,
  value
}: IndexTableCellMoneyProps) {
  return (
    <IndexTableCell
      divClassName={
        divClassName ||
        'flex items-center justify-end gap-1 text-gray-900 dark:text-gray-200 font-normal text-sm whitespace-nowrap'
      }
    >
      <MoneyHelper useSuffix={useSuffix} value={value} />
    </IndexTableCell>
  );
}

export default IndexTableCellMoney;
