import { UUID } from '../../../../../../../types';
import isEmpty from 'lodash/isEmpty';

import { ItemShowSidebarExpandableDescription } from '../../../../../../common/components/sidebars/ItemShowSidebar/components/ItemShowSidebarExpandableDescription';

import { UpdateProjectDescriptionPureModalButton } from '../../../../modalButtons/UpdateProjectDescriptionPureModalButton';

import { projectsKeys } from '../../../../../../../locales/keys';

export interface ProjectsIndexSidebarDescriptionProject {
  uuid: UUID;
  description?: string;
}

interface ProjectsIndexSidebarDescriptionProps {
  project: ProjectsIndexSidebarDescriptionProject;
}

function ProjectsIndexSidebarDescription({
  project
}: ProjectsIndexSidebarDescriptionProps) {
  if (!project) {
    return null;
  }

  return (
    <ItemShowSidebarExpandableDescription
      description={project.description}
      withoutTitle
    >
      <UpdateProjectDescriptionPureModalButton
        uuid={project.uuid}
        description={project.description}
        i18nText={
          isEmpty(project.description)
            ? projectsKeys.addDescription
            : projectsKeys.editDescription
        }
      />
    </ItemShowSidebarExpandableDescription>
  );
}

export default ProjectsIndexSidebarDescription;
