import * as yup from 'yup';
import toNumber from 'lodash/toNumber';

import { formsErrors } from '../../../../../../../locales/keys';

export const updateProjectsAmountsValidationSchema = yup.object().shape({
  projects: yup.array().of(
    yup.object().shape({
      estimateCost: yup
        .number()
        .transform((_, value) => (value === '' ? undefined : toNumber(value)))
        .min(0, formsErrors.estimateCost.nonNegative)
        .required(formsErrors.required)
    })
  )
});
