import { ProjectSidebarActionsBlockProject } from './ProjectSidebarActionsBlock.types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { ProjectSidebarCreateBill } from '../ProjectSidebarCreateBill';

import { Translate } from '../../../../../helpers/Translate';

import { ProjectsPermissions } from '../../../projectsConstants';
import { formsFields } from '../../../../../locales/keys';

interface ProjectSidebarActionsBlockProps {
  project: ProjectSidebarActionsBlockProject;
}

function ProjectSidebarActionsBlock({
  project
}: ProjectSidebarActionsBlockProps) {
  const currentUser = useCurrentUser();

  const withCreateBillButton = currentUser.hasPermissions(
    ProjectsPermissions.READ_PROJECT_CREATE_BILL_BUTTON
  );

  if (!withCreateBillButton) {
    return null;
  }

  return (
    <div className="p-4">
      <h6 className="mb-3 font-light uppercase">
        <Translate id={formsFields.actionPlural} />:
      </h6>
      <div className="space-y-1 -mx-2">
        {withCreateBillButton ? (
          <ProjectSidebarCreateBill
            project={project}
            projectNanoId={project.nanoId}
          />
        ) : null}
      </div>
    </div>
  );
}

export default ProjectSidebarActionsBlock;
