import size from 'lodash/size';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ProjectsIndexPageBatchActionsHeaderProject } from './ProjectsIndexPageBatchActionsHeader.types';

import { OnUncheckAll } from '../../../../../common/hooks/useTableCheckable';

import { ProjectsIndexPageBatchActionsHeaderButtons } from './components/ProjectsIndexPageBatchActionsHeaderButtons';

import { PopoverPlacement } from '../../../../../helpers/Popover/popoverConstants';
import { DropdownHelper } from '../../../../../helpers/dropdowns/DropdownHelper';
import { Translate } from '../../../../../helpers/Translate';
import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { words } from '../../../../../locales/keys';

interface ProjectsIndexPageBatchActionsHeaderProps {
  checkedProjects: ProjectsIndexPageBatchActionsHeaderProject[];
  onUncheckAll: OnUncheckAll;
}

function ProjectsIndexPageBatchActionsHeader({
  checkedProjects,
  onUncheckAll
}: ProjectsIndexPageBatchActionsHeaderProps) {
  return (
    <div
      id="page-header-batch"
      className="flex h-16 z-20 relative flex-1 px-4 justify-between shadow-xl dark:glow-xl border-b border-gray-200 dark:border-gray-700"
    >
      <div className="flex-1 flex space-x-5 items-center justify-between">
        <div className="flex items-center space-x-5">
          <PureTooltipIconButtonHelper
            className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            tooltipI18nText={words.unselectAllItems}
            icon={IconsEnum.CROSS}
            iconClassName="h-6 w-6"
            onClick={onUncheckAll}
          />
          <div className="font-medium">
            <Translate id={words.selected} />
            {': '}
            <span id="batch-header-counter">{size(checkedProjects)}</span>
          </div>
        </div>
        <div className="flex items-center">
          <div className="items-center hidden sm:flex">
            <ProjectsIndexPageBatchActionsHeaderButtons
              checkedProjects={checkedProjects}
            />
          </div>
          <DropdownHelper
            className="relative sm:hidden"
            buttonClassName="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
            iconClassName="h-6 w-6"
            dropdownPlacement={PopoverPlacement.BOTTOM_START}
          >
            <ProjectsIndexPageBatchActionsHeaderButtons
              checkedProjects={checkedProjects}
              dropdown
            />
          </DropdownHelper>
        </div>
      </div>
    </div>
  );
}

export default ProjectsIndexPageBatchActionsHeader;
