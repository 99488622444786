import {
  UUID,
  CreatedAt,
  FetchItemsSort,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsGqlQuery,
  FetchItemsCacheKey,
  ID,
  UUIDFilter,
  NanoIDFilter
} from '../../types';
import { ProjectNanoID, ProjectUUID } from '../projects/projectsTypes';

import {
  UserUUID,
  UserFullName,
  UserRoleName,
  UserImageFile,
  UserImageUUID,
  UserID,
  UserNanoID,
  UserFinanceRoleName
} from '../users/usersTypes';

export type ProjectMemberID = ID;
export type ProjectMemberUUID = UUID;
export type ProjectMemberTrackedTasksTime = string | null;
export type ProjectMemberTrackedTime = string | null;
export type ProjectMemberCreatedAt = CreatedAt;
export type ProjectMemberNotificationsEnabled = boolean;

export type ProjectMemberUserID = UserID;
export type ProjectMemberUserUUID = UserUUID;
export type ProjectMemberUserNanoID = UserNanoID;
export type ProjectMemberUserFullName = UserFullName;
export type ProjectMemberUserRoleName = UserRoleName;
export type ProjectMemberUserFinanceRoleName = UserFinanceRoleName;
export type ProjectMemberUserImageUUID = UserImageUUID;
export type ProjectMemberUserImageFile = UserImageFile;
export type ProjectMemberProjectUuid = ProjectUUID;
export type ProjectMemberProjectNanoID = ProjectNanoID;

export enum ProjectMemberFields {
  ID = 'id',
  UUID = 'uuid',
  CREATED_AT = 'createdAt'
}

export interface FetchProjectMembersFilters {
  projectUuid?: UUIDFilter;
  projectNanoId?: NanoIDFilter;
  createdAt?: string;
}

export enum FetchProjectMembersSortableFields {
  ID = 'id',
  CREATED_AT = 'createdAt'
}

export enum FetchProjectMembersSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchProjectMembersPage = FetchItemsPage;
export type FetchProjectMembersLimit = FetchItemsLimit;
export type FetchProjectMembersSort = FetchItemsSort;

export type FetchProjectMembersGqlQuery = FetchItemsGqlQuery;

export type FetchProjectMembersCacheKey = FetchItemsCacheKey;
