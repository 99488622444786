import {
  FetchProjectMembersFilters,
  FetchProjectMembersSort,
  FetchProjectMembersPage,
  FetchProjectMembersLimit,
  FetchProjectMembersGqlQuery,
  FetchProjectMembersCacheKey
} from '../../projectMembersTypes';

import {
  INITIAL_PROJECT_MEMBERS_FILTERS,
  INITIAL_PROJECT_MEMBERS_SORT,
  INITIAL_PROJECT_MEMBERS_PAGE,
  INITIAL_PROJECT_MEMBERS_LIMIT
} from '../../projectMembersConstants';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

interface ProjectMembersOptions {
  cacheKey: FetchProjectMembersCacheKey;
  query: FetchProjectMembersGqlQuery;
  initialFilters?: FetchProjectMembersFilters;
  initialSort?: FetchProjectMembersSort;
  initialPage?: FetchProjectMembersPage;
  initialLimit?: FetchProjectMembersLimit;
  options?: {
    cacheTime?: number;
  };
}

const scope = 'projectMembers';

function useProjectMembers<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_PROJECT_MEMBERS_FILTERS,
  initialSort = INITIAL_PROJECT_MEMBERS_SORT,
  initialPage = INITIAL_PROJECT_MEMBERS_PAGE,
  initialLimit = INITIAL_PROJECT_MEMBERS_LIMIT,
  options = {}
}: ProjectMembersOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useIndexQuery<ItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options,
    query,
    scope
  });

  return {
    projectMembersData: data,
    projectMembers: items,
    projectMembersError: itemsError,
    projectMembersTotalCount: itemsTotalCount,
    projectMembersFetched: isFetched,
    projectMembersLoading: isLoading,
    projectMembersIsPlaceholderData: isPlaceholderData,
    projectMembersFilters: currentFilters,
    projectMembersSort: currentSort,
    projectMembersPage: currentPage,
    projectMembersLimit: currentLimit,
    filterProjectMembers: filterItems,
    clearProjectMembersFilters: clearItemsFilters,
    sortProjectMembers: sortItems,
    paginateProjectMembers: paginateItems,
    limitProjectMembers: limitItems,
    prefetchProjectMembers: prefetchItems
  };
}

export default useProjectMembers;
