import React from 'react';
import { Controller, FieldPath } from 'react-hook-form';

import {
  ItemsListFieldList,
  ItemsListFieldListRequiredProps
} from './components/ItemsListFieldList';

import { ItemsListFieldRequiredProps } from './ItemsListField.types';

interface ItemsListFieldProps<T> {
  name: FieldPath<T>;
  disabled?: boolean;
}

function ItemsListField<T>({
  control,
  disabled,
  name,
  labelFor,
  i18nLabel,
  label,
  labelClassName,
  i18nPlaceholder,
  placeholder,
  addButtonI18nText,
  addButtonClassName
}: ItemsListFieldProps<T> &
  ItemsListFieldRequiredProps<T> &
  ItemsListFieldListRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ItemsListFieldList
          value={value as string[]}
          disabled={disabled}
          labelFor={labelFor}
          i18nLabel={i18nLabel}
          label={label}
          labelClassName={labelClassName}
          i18nPlaceholder={i18nPlaceholder}
          placeholder={placeholder}
          addButtonI18nText={addButtonI18nText}
          addButtonClassName={addButtonClassName}
          onChange={onChange}
        />
      )}
    />
  );
}

export default ItemsListField;
