import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { UpdateProjectsAmountsCacheKeys } from '../../../projectsTypes/useUpdateProjectsAmounts.types';

import { useUpdateProjectsAmountsForm } from '../../forms/UpdateProjectsAmountsForm/hooks/useUpdateProjectsAmountsForm';

import { UpdateProjectsAmountsForm } from '../../forms/UpdateProjectsAmountsForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { words, projectsKeys, stringsKeys } from '../../../../../locales/keys';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { UpdateProjectsAmountsModalButtonProjects } from './UpdateProjectsAmountsModalButton.types';

interface UpdateProjectsAmountsModalButtonProjectsProps {
  cacheKeys: UpdateProjectsAmountsCacheKeys;
  className?: ClassName;
  disabled?: IsDisabled;
  i18nText?: I18nText;
  i18nTitle?: I18nText;
  icon?: IconsEnum;
  projects: UpdateProjectsAmountsModalButtonProjects;
  tooltipSingleton?: boolean;
}

const UPDATE_TASKS_AMOUNTS_FORM = 'update-projects-amounts-form';

function UpdateProjectsAmountsModalButton({
  cacheKeys,
  className,
  disabled,
  i18nText,
  i18nTitle,
  icon,
  projects,
  tooltipSingleton
}: UpdateProjectsAmountsModalButtonProjectsProps) {
  const {
    fields,
    handleInitUpdateProjectsAmountsForm,
    handleUpdateProjectsAmounts,
    amountsErrorsMessages,
    register,
    registerCommonAmount,
    setAllAmounts,
    updateProjectsAmountsErrorMessage,
    updateProjectsAmountsLoading,
    updateProjectsAmountsReset
  } = useUpdateProjectsAmountsForm({
    cacheKeys,
    projects
  });

  return (
    <FormModalButton
      className={className}
      disabled={disabled}
      form={UPDATE_TASKS_AMOUNTS_FORM}
      i18nSubmitText={words.update}
      i18nText={i18nText}
      i18nTitle={i18nTitle || projectsKeys.changeEstimateCost}
      icon={icon}
      iconClassName="h-6 w-6"
      isLoading={updateProjectsAmountsLoading}
      onClose={updateProjectsAmountsReset}
      onOpen={handleInitUpdateProjectsAmountsForm}
      onSubmit={handleUpdateProjectsAmounts}
      tooltipI18nText={stringsKeys.updateCost}
      tooltipPlacement={TooltipPlacement.BOTTOM}
      tooltipSingleton={tooltipSingleton}
    >
      <UpdateProjectsAmountsForm
        fields={fields}
        form={UPDATE_TASKS_AMOUNTS_FORM}
        amountsErrorsMessages={amountsErrorsMessages}
        isLoading={updateProjectsAmountsLoading}
        register={register}
        registerCommonAmount={registerCommonAmount}
        setAllAmounts={setAllAmounts}
      />

      <div className="px-4">
        <AlertMessage message={updateProjectsAmountsErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateProjectsAmountsModalButton;
