import React from 'react';

import {
  SimpleModalButton,
  SimpleModalButtonDefaultProps
} from '../../../../../helpers/buttons/SimpleModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { billsKeys } from '../../../../../locales/keys';

import { PayBillsModalButtonBill } from './components/PayBillsModalButtonBill';

import { PayBillModalButtonBillItem } from '../PayBillModalButton';

import { I18nText } from '../../../../../types';

interface PayBillsModalButtonProps {
  bills: PayBillModalButtonBillItem[];
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

function PayBillsModalButton({
  bills,
  className,
  i18nText,
  icon,
  iconClassName,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton,
  onMouseEnter
}: PayBillsModalButtonProps & SimpleModalButtonDefaultProps) {
  return (
    <SimpleModalButton
      className={className}
      i18nText={i18nText}
      modalSize="sm"
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      icon={icon}
      iconClassName={iconClassName}
      onMouseEnter={onMouseEnter}
      withoutButtons
      i18nTitle={billsKeys.payBills}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      tooltipSingleton={tooltipSingleton}
      disabled={!bills.length}
      allowPropagation
    >
      <div className="p-4">
        <div className="flex flex-col flex-1 -my-4">
          <div className="divide-dashed divide-y w-full">
            {bills?.map((bill) => (
              <PayBillsModalButtonBill bill={bill} key={bill.id} />
            ))}
          </div>
        </div>
      </div>
    </SimpleModalButton>
  );
}

export default PayBillsModalButton;
