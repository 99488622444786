import { useItemDueDateValidationRules } from '../../../../../../common/forms/hooks/useItemDueDateValidationRules';

import { formsErrors } from '../../../../../../../locales/keys';

import { ProjectsPermissions } from '../../../../../projectsConstants';

const estimateCostRules = {
  validate: {
    isValid: (value: number) => {
      if (!value) {
        return true;
      }

      if (value < 0) {
        return formsErrors.estimateCost.nonNegative;
      }

      return true;
    }
  }
};

const nameRules = { required: formsErrors.projectName.required };

function useCreateProjectValidationRules() {
  const implementationDateRules = useItemDueDateValidationRules({
    action: ProjectsPermissions.CREATE_PROJECT_IMPLEMENTATION_TIME,
    cantBeBlank: false
  });

  return {
    estimateCostRules,
    implementationDateRules,
    nameRules
  };
}

export default useCreateProjectValidationRules;
