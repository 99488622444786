import { FiltersPopoverTypesEnum, IsLoading } from '../../../../../types';

import { SearchFilterNanoID } from '../../../../searchFilters/searchFiltersTypes';

import {
  FetchProjectsClearProjectsFilters,
  FetchProjectsFilterProjects,
  FetchProjectsFilters
} from '../../../projectsTypes';

import { useIndexPageFiltersPopover } from '../../../../common/hooks/useIndexPageFiltersPopover';

import { FilterProjectsForm } from '../../forms/FilterProjectsForm';

const filtersProjectsPopoverTargetId = 'projects-filters-popover-button';

interface FilterProjectsPopoverProps {
  clearProjectsFilters: FetchProjectsClearProjectsFilters;
  filterProjects: FetchProjectsFilterProjects;
  isLoading?: IsLoading;
  onPopoverClose?: () => void;
  onResetFilters?: () => void;
  onCreateSearchFilter?: (searchFilterNanoId: SearchFilterNanoID) => void;
  projectsFilters: FetchProjectsFilters;
}

function FilterProjectsPopover({
  clearProjectsFilters,
  filterProjects,
  isLoading,
  onPopoverClose,
  onResetFilters,
  onCreateSearchFilter,
  projectsFilters
}: FilterProjectsPopoverProps) {
  const {
    filtersSaving,
    handleFiltersChange,
    handleFiltersClear,
    handleFiltersSave,
    popoverDivRef
  } = useIndexPageFiltersPopover<FetchProjectsFilters>({
    popoverType: FiltersPopoverTypesEnum.ACTIVE_FILTERS,
    onFiltersChange: filterProjects,
    onClosePopover: onPopoverClose,
    onFiltersClear: clearProjectsFilters,
    onResetFilters,
    onCreateSearchFilter,
    targetId: filtersProjectsPopoverTargetId
  });

  return (
    <div
      className="absolute flex left-0 max-h-screen overflow-hidden pb-32 md:pb-16 pointer-events-none pt-14 px-4 top-0 w-full"
      ref={popoverDivRef}
    >
      <FilterProjectsForm
        afterSubmit={onPopoverClose}
        filterProjects={handleFiltersChange}
        isLoading={isLoading || filtersSaving}
        onClear={handleFiltersClear}
        onSaveFilters={handleFiltersSave}
        projectsFilters={projectsFilters}
      />
    </div>
  );
}

export default FilterProjectsPopover;
