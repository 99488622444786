import { memo, useMemo } from 'react';
import cl from 'classnames';

interface ProgressProps {
  min: number;
  max: number;
}

function Progress({ min, max }: ProgressProps) {
  const progressWidthStyle = useMemo(
    () => ({ width: max === 0 ? 0 : `${(min / max) * 100.0}%` }),
    [max, min]
  );

  return max ? (
    <div className="relative w-full h-1 rounded overflow-hidden bg-gray-200 dark:bg-gray-700">
      <div
        className={cl(
          'h-full',
          min > 0 && min === max
            ? 'bg-green-500'
            : 'bg-gray-400 dark:bg-gray-400'
        )}
        style={progressWidthStyle}
      />
    </div>
  ) : null;
}

export default memo<ProgressProps>(Progress);
