import React, { Fragment } from 'react';

import { IsLoading, PathUrl, UUID } from '../../../../../../../types';

import { TaskStatusOptions } from '../../../../../../tasks/tasksTypes';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { UpdateTaskStatusModalButton } from '../../../../../../tasks/components/modalButtons/UpdateTaskStatusModalButton';
import { ToggleItemNotificationsEnabledButton } from '../../components/buttons/ToggleItemNotificationsEnabledButton';
import { UpdateTaskTagIdsModalButton } from '../../../../../../tasks/components/modalButtons/UpdateTaskTagIdsModalButton';
import { UpdateProjectTagIdsModalButton } from '../../../../../../projects/components/modalButtons/UpdateProjectTagIdsModalButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';
import { Icon } from '../../../../../../../helpers/Icon';
import { Status } from '../../../../../../../helpers/Status';

import { ProjectsPermissions } from '../../../../../../projects/projectsConstants';
import { TasksPermissions } from '../../../../../../tasks/tasksConstants';

import { ItemIndexSidebarInfoItem } from './ItemIndexSidebarInfo.types';

interface ItemIndexSidebarInfoProps {
  isLoading: IsLoading;
  item: ItemIndexSidebarInfoItem;
  onToggleItemNotificationsEnabled: ({ uuid }: { uuid: UUID }) => void;
  scope: 'projects' | 'tasks';
  showLinkHref: PathUrl;
}

function ItemIndexSidebarInfo({
  isLoading,
  item,
  onToggleItemNotificationsEnabled,
  scope,
  showLinkHref
}: ItemIndexSidebarInfoProps) {
  return (
    <div className="p-4">
      <div className="space-y-3">
        <div>
          <h3 className="font-semibold break-words text-lg leading-snug">
            <NextPureLinkHelper href={showLinkHref} text={item.name} />
          </h3>
        </div>

        <div className="flex items-center space-x-2">
          <div>
            <ToggleItemNotificationsEnabledButton
              disabled={isLoading}
              item={item}
              onToggleItemNotificationsEnabled={
                onToggleItemNotificationsEnabled
              }
            />
          </div>

          {scope === 'tasks' ? (
            <CheckPermissions action={TasksPermissions.READ_TASK_STATUS}>
              <UpdateTaskStatusModalButton
                status={item.status as TaskStatusOptions}
                uuid={item.uuid}
              />
            </CheckPermissions>
          ) : (
            <Fragment>
              <CheckPermissions
                action={ProjectsPermissions.READ_PROJECT_STATUS}
              >
                <Status status={item.status} />
              </CheckPermissions>
              <CheckPermissions
                action={ProjectsPermissions.READ_PROJECT_TASKS_CLIENT_STATUS}
              >
                <Status status={item.tasksClientStatus} />
              </CheckPermissions>
            </Fragment>
          )}

          {item.burning ? <Icon icon={IconsEnum.BURNING} /> : null}
        </div>

        <div>
          {scope === 'tasks' ? (
            <CheckPermissions action={TasksPermissions.READ_TASK_TAG_IDS}>
              <UpdateTaskTagIdsModalButton
                selectedTags={item.tags}
                uuid={item.uuid}
              />
            </CheckPermissions>
          ) : (
            <CheckPermissions action={ProjectsPermissions.READ_PROJECT_TAG_IDS}>
              <UpdateProjectTagIdsModalButton
                uuid={item.uuid}
                selectedTags={item.tags}
              />
            </CheckPermissions>
          )}
        </div>
      </div>
    </div>
  );
}

export default ItemIndexSidebarInfo;
