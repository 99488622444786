import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { SearchFilterNanoID } from '../../../searchFilters/searchFiltersTypes';

import { ProjectPath } from '../../ProjectPath';

function useFilteredProjectsRoutes() {
  const {
    query: { slug },
    push
  } = useRouter();

  const handleResetFilters = useCallback<() => void>(
    () => push(ProjectPath.index()),
    [push]
  );

  const handleCreateSearchFilter = useCallback<
    (searchFilterNanoId: SearchFilterNanoID) => void
  >(
    (searchFilterNanoId) => push(ProjectPath.filters(searchFilterNanoId)),
    [push]
  );

  return {
    filterNanoId: slug as SearchFilterNanoID,
    handleCreateSearchFilter,
    handleResetFilters
  };
}

export default useFilteredProjectsRoutes;
