import { UserID } from '../../../users/usersTypes';
import { inviteUsersToProjectsGqlQuery, ProjectID } from '../../projectsTypes';
import {
  InviteUsersToProjectsError,
  InviteUsersToProjectsResponse
} from './useInviteUsersToProjects.types';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { ProjectCache } from '../../ProjectCache';

interface InviteUsersToProjectsOptions {
  query: inviteUsersToProjectsGqlQuery;
}

interface InviteUsersToTasksInput {
  projectIds: ProjectID[];
  userIds: UserID[];
}

const action = 'inviteUsersToProjects';

const cacheKeys = [ProjectCache.indexCacheKey()];

function useInviteUsersToProjects({ query }: InviteUsersToProjectsOptions) {
  const {
    updateQuery,
    updateQueryData,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading,
    updateQueryReset
  } = useUpdateQuery<
    InviteUsersToTasksInput,
    InviteUsersToProjectsResponse,
    InviteUsersToProjectsError,
    undefined
  >({ action, cacheKeys, query });

  return {
    inviteUsersToProjects: updateQuery,
    inviteUsersToProjectsData: updateQueryData,
    inviteUsersToProjectsError: updateQueryError,
    inviteUsersToProjectsErrorMessage: updateQueryErrorMessage,
    inviteUsersToProjectsLoading: updateQueryLoading,
    inviteUsersToProjectsReset: updateQueryReset
  };
}

export default useInviteUsersToProjects;
