import React from 'react';
import cl from 'classnames';

import { ClassName } from '../../../../../types';

import { IconsEnum } from '../../../../../assets/icons/types';

import { IndexTableCell } from '../IndexTableCell';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

interface IndexTableCellFavoriteProps {
  disabled?: boolean;
  addTdClassName?: ClassName;
  favorite: boolean;
  onToggleFavorite?: () => void;
}

function IndexTableCellFavorite({
  disabled,
  favorite,
  addTdClassName,
  onToggleFavorite
}: IndexTableCellFavoriteProps) {
  return (
    <IndexTableCell tdClassName={cl('px-3 py-2 w-px relative', addTdClassName)}>
      <div className="flex items-center justify-center absolute inset-0">
        <PureIconButtonHelper
          className="focus:ring-offset-0 inline-flex items-center p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
          disabled={disabled}
          iconClassName={
            favorite
              ? 'h-5 w-5 text-yellow-500'
              : 'h-5 w-5 text-gray-400 group-hover:text-gray-600 dark:text-gray-600 dark:group-hover:text-gray-400 stroke-2'
          }
          icon={favorite ? IconsEnum.FILLED_STAR : IconsEnum.EMPTY_STAR}
          onClick={onToggleFavorite}
        />
      </div>
    </IndexTableCell>
  );
}

export default IndexTableCellFavorite;
