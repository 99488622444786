import { gql } from 'graphql-request';

export const CREATE_PROJECTS_BILLS_QUERY = gql`
  mutation CreateProjectsBills(
    $bill: CreateProjectsBillsBillInput!
    $projectIds: [ID!]!
  ) {
    createProjectsBills(input: { bill: $bill, projectIds: $projectIds }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
