import cl from 'classnames';

import { AfterSubmitFunc, IsLoading } from '../../../../../types';

import {
  FetchProjectsClearProjectsFilters,
  FetchProjectsFilterProjects,
  FetchProjectsFilters,
  FetchProjectsFilterFields
} from '../../../projectsTypes';

import {
  CreateSearchFilterFunc,
  SearchFilterFields
} from '../../../../searchFilters/searchFiltersTypes';

import { FilterProjectsFormData } from './FilterProjectsForm.types';

import { useFilterProjectsForm } from './hooks/useFilterProjectsForm';

import { searchFiltersColorsSelectDataItems } from '../../../../searchFilters/searchFiltersConstants';

import { IndexTableFilterForm } from '../../../../common/components/filterForms/IndexTableFilterForm';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { ColorsSelectField } from '../../../../../helpers/FormFields/ColorsSelectField';
import { IndexTableFilterFormFieldGroup } from '../../../../common/components/filterForms/IndexTableFilterForm/helpers/IndexTableFilterFormFieldGroup';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { ProjectsStatusesCheckboxesField } from '../../../helpers/ProjectsStatusesCheckboxesField';
import { RangeInputField } from '../../../../../helpers/FormFields/RangeInputField';
import { TagsMultiSelectField } from '../../../../tags/helpers/TagsMultiSelectField';
import { UsersListPopoverField } from '../../../../../helpers/FormFields/UsersListPopoverField';
import { YesNoAnyRadioButtonsField } from '../../../../../helpers/FormFields/YesNoAnyRadioButtonsField';

import {
  formsFields,
  projectsKeys,
  usersKeys,
  tagsKeys,
  words
} from '../../../../../locales/keys';

import { ProjectsPermissions } from '../../../projectsConstants';

interface FilterProjectsFormProps {
  afterSubmit: AfterSubmitFunc;
  filterProjects: FetchProjectsFilterProjects;
  isLoading?: IsLoading;
  onClear: FetchProjectsClearProjectsFilters;
  onSaveFilters?: CreateSearchFilterFunc<FetchProjectsFilters>;
  projectsFilters: FetchProjectsFilters;
}

function FilterProjectsForm({
  afterSubmit,
  filterProjects,
  isLoading,
  onClear,
  onSaveFilters,
  projectsFilters
}: FilterProjectsFormProps) {
  const {
    control,
    errors,
    handleFilterProjectsSubmit,
    isEditingUsers,
    isFormEmpty,
    onCancel,
    onGoBack,
    registerName,
    registerRankedName,
    saveFiltersMode,
    setValue,
    toggleIsEditingUsers,
    toggleSaveFiltersMode
  } = useFilterProjectsForm({
    afterSubmit,
    filterProjects,
    onClear,
    onSaveFilters,
    projectsFilters
  });

  return (
    <IndexTableFilterForm
      i18nCancelText={saveFiltersMode ? words.cancel : words.clear}
      i18nGoBackHeaderText={saveFiltersMode ? words.saveFilter : usersKeys.add}
      i18nSubmitText={saveFiltersMode ? words.saveFilter : words.search}
      isLoading={isLoading}
      onCancel={onCancel}
      onGoBack={onGoBack}
      onSaveFilters={saveFiltersMode ? null : toggleSaveFiltersMode}
      onSubmit={handleFilterProjectsSubmit}
      saveDisabled={isFormEmpty}
      withoutFooter={isEditingUsers}
    >
      {saveFiltersMode ? (
        <div id="save-filter-form" className="py-6 flex-col space-y-6 text-sm">
          <IndexTableFilterFormFieldGroup
            i18nGroupLabel={formsFields.filterName}
          >
            <InputField
              disabled={isLoading}
              error={errors?.name?.message}
              i18nPlaceholder={formsFields.filterName}
              inputWrapperClassName="mt-1 relative"
              labelClassName="font-light uppercase"
              name={registerName.name}
              onChange={registerName.onChange}
              ref={registerName.ref}
              type="text"
            />
          </IndexTableFilterFormFieldGroup>
          <IndexTableFilterFormFieldGroup i18nGroupLabel={formsFields.colorTag}>
            <ColorsSelectField
              colors={searchFiltersColorsSelectDataItems}
              control={control}
              disabled={isLoading}
              name={SearchFilterFields.COLORS}
            />
          </IndexTableFilterFormFieldGroup>
        </div>
      ) : null}

      <div
        id="filters-group"
        className={cl('py-6 flex flex-col space-y-6 text-sm', {
          hidden: saveFiltersMode
        })}
      >
        <CheckPermissions
          action={ProjectsPermissions.READ_PROJECTS_RANKED_NAME_FILTER}
        >
          <IndexTableFilterFormFieldGroup
            i18nGroupLabel={projectsKeys.projectName}
            isHidden={isEditingUsers}
          >
            <InputField
              disabled={isLoading}
              i18nPlaceholder={projectsKeys.searchByProjectName}
              inputWrapperClassName="mt-1 relative"
              labelClassName="font-light uppercase"
              name={registerRankedName.name}
              onChange={registerRankedName.onChange}
              ref={registerRankedName.ref}
              type="text"
            />
          </IndexTableFilterFormFieldGroup>
        </CheckPermissions>
        <UsersListPopoverField<FilterProjectsFormData>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          control={control}
          i18nLabel={projectsKeys.team}
          isEditingUsers={isEditingUsers}
          name={FetchProjectsFilterFields.PERFORMER_IDS}
          onToggleIsEditingUsers={toggleIsEditingUsers}
        />
        <IndexTableFilterFormFieldGroup
          isHidden={isEditingUsers}
          i18nGroupLabel={projectsKeys.status}
        >
          <ProjectsStatusesCheckboxesField<FilterProjectsFormData>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            control={control}
            inputWrapperClassName="flex flex-col sm:flex-row sm:flex-wrap -mb-2"
            name={FetchProjectsFilterFields.STATUS}
          />
        </IndexTableFilterFormFieldGroup>
        <CheckPermissions
          action={ProjectsPermissions.READ_PROJECTS_TAG_IDS_FILTER}
        >
          <IndexTableFilterFormFieldGroup
            isHidden={isEditingUsers}
            i18nGroupLabel={tagsKeys.plural}
          >
            <TagsMultiSelectField<FilterProjectsFormData>
              isLoading={false}
              control={control}
              initialTagIds={projectsFilters?.tagIds}
              name={FetchProjectsFilterFields.TAG_IDS}
              multi
              setValue={setValue}
            />
          </IndexTableFilterFormFieldGroup>
        </CheckPermissions>
        <IndexTableFilterFormFieldGroup
          isHidden={isEditingUsers}
          i18nGroupLabel={formsFields.createdAt}
        >
          <RangeInputField<FilterProjectsFormData>
            control={control}
            name={FetchProjectsFilterFields.CREATED_AT}
            type="date"
          />
        </IndexTableFilterFormFieldGroup>
        <IndexTableFilterFormFieldGroup
          isHidden={isEditingUsers}
          i18nGroupLabel={formsFields.implementationDate}
        >
          <RangeInputField<FilterProjectsFormData>
            control={control}
            name={FetchProjectsFilterFields.IMPLEMENTATION_DATE}
            type="date"
          />
        </IndexTableFilterFormFieldGroup>
        <CheckPermissions
          action={ProjectsPermissions.READ_PROJECTS_BURNING_FILTER}
        >
          <IndexTableFilterFormFieldGroup
            isHidden={isEditingUsers}
            i18nGroupLabel={formsFields.burning}
          >
            <YesNoAnyRadioButtonsField<FilterProjectsFormData>
              control={control}
              name={FetchProjectsFilterFields.BURNING}
            />
          </IndexTableFilterFormFieldGroup>
        </CheckPermissions>
      </div>
    </IndexTableFilterForm>
  );
}

export default FilterProjectsForm;
