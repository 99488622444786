import { UserID } from '../../../users/usersTypes';

import { ProjectNanoID, ProjectUUID } from '../../projectsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface InviteUsersToProjectOptions {
  query: string;
}

export interface InviteUsersToProjectResponse<InviteUsersToProjectRecordType> {
  inviteUsersToProject: {
    status: string;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: InviteUsersToProjectRecordType;
    errors: InviteUsersToProjectError;
  };
}

export interface InviteUsersToProjectInput {
  uuid: ProjectUUID;
  userIds: UserID[];
}

export interface InviteUsersToProjectError {
  fullMessages: string[] | null;
}

const action = 'inviteUsersToProject';

const scope = 'project';

const pluralScope = 'projects';

function useInviteUsersToProject<InviteUsersToProjectRecordType>({
  query
}: InviteUsersToProjectOptions) {
  const {
    updateQuery,
    updateQueryData,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading,
    updateQueryReset
  } = useUpdateQuery<
    InviteUsersToProjectInput,
    InviteUsersToProjectResponse<InviteUsersToProjectRecordType>,
    InviteUsersToProjectError,
    InviteUsersToProjectRecordType
  >({ action, pluralScope, scope, query });

  return {
    inviteUsersToProject: updateQuery,
    inviteUsersToProjectData: updateQueryData,
    inviteUsersToProjectError: updateQueryError,
    inviteUsersToProjectErrorMessage: updateQueryErrorMessage,
    inviteUsersToProjectLoading: updateQueryLoading,
    inviteUsersToProjectReset: updateQueryReset
  };
}

export default useInviteUsersToProject;
