import { useCallback } from 'react';

import { ClassName } from '../../../../../types';

import { UpdatePaginatedProjectsItemCacheAction } from '../../../projectsTypes';

import {
  TOGGLE_PROJECT_FAVORITE_QUERY,
  ToggleProjectFavoriteQueryResponse
} from '../../../queries/toggleProjectFavorite.query';

import { useToggleProjectFavorite } from '../../../hooks/useToggleProjectFavorite';

import { IndexTableCellFavorite } from '../../../../common/components/tables/IndexTableCellFavorite';

import { ProjectsIndexTableCellFavoriteProject } from './ProjectsIndexTableCellFavorite.types';

import { ProjectCache } from '../../../ProjectCache';

interface ProjectsIndexTableCellFavoriteProps {
  project: ProjectsIndexTableCellFavoriteProject;
  addTdClassName?: ClassName;
  updateProjectCache?: UpdatePaginatedProjectsItemCacheAction<ProjectsIndexTableCellFavoriteProject>;
}

function ProjectsIndexTableCellFavorite({
  project,
  addTdClassName,
  updateProjectCache
}: ProjectsIndexTableCellFavoriteProps) {
  const handleToggleFavoriteSuccess = useCallback<
    (responseRecord: ToggleProjectFavoriteQueryResponse) => void
  >(
    (record) => {
      if (record && updateProjectCache) {
        updateProjectCache({
          selector: (item) => item.nanoId === record.nanoId,
          updateFunction: () => ({
            favorite: record.favorite
          })
        });
      }
    },
    [updateProjectCache]
  );

  const { toggleProjectFavorite, toggleProjectFavoriteLoading } =
    useToggleProjectFavorite<ToggleProjectFavoriteQueryResponse>({
      query: TOGGLE_PROJECT_FAVORITE_QUERY,
      cacheKeys: [ProjectCache.indexCacheKey()],
      onSuccess: updateProjectCache ? handleToggleFavoriteSuccess : undefined
    });

  const handleToggleProjectFavorite = () =>
    toggleProjectFavorite({ uuid: project.uuid });

  return (
    <IndexTableCellFavorite
      addTdClassName={addTdClassName}
      disabled={toggleProjectFavoriteLoading}
      favorite={project.favorite}
      onToggleFavorite={handleToggleProjectFavorite}
    />
  );
}

export default ProjectsIndexTableCellFavorite;
