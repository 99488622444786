import {
  CreateProjectsBillsCacheKeys,
  CreateProjectsBillsGqlError,
  CreateProjectsBillsGqlQuery,
  CreateProjectsBillsGqlStatus,
  ProjectID
} from '../../projectsTypes';
import { CreateProjectsBillsModalButtonBill } from '../../components/modalButtons/CreateProjectsBillsModalButton/CreateProjectsBillsModalButton.types';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface CreateProjectsBillsInput {
  projectIds: ProjectID[];
  bill: CreateProjectsBillsModalButtonBill;
}

interface CreateProjectsBillsResponse {
  createProjectsBills: {
    status: CreateProjectsBillsGqlStatus;
    errors: CreateProjectsBillsErrors;
  };
}

interface CreateProjectsBillsErrors {
  fullMessages: CreateProjectsBillsGqlError;
}

interface CreateProjectsBillsOptions {
  query: CreateProjectsBillsGqlQuery;
  cacheKeys: CreateProjectsBillsCacheKeys;
}

const action = 'createProjectsBills';

function useCreateProjectsBills({
  query,
  cacheKeys
}: CreateProjectsBillsOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateProjectsBillsInput,
    CreateProjectsBillsResponse,
    CreateProjectsBillsErrors,
    undefined
  >({
    action,
    cacheKeys,
    query
  });

  return {
    createProjectsBillsData: createQueryData,
    createProjectsBillsError: createQueryError,
    createProjectsBillsLoading: createQueryLoading,
    createProjectsBillsErrorMessage: createQueryErrorMessage,
    createProjectsBills: createQuery,
    createProjectsBillsReset: createQueryReset
  };
}

export default useCreateProjectsBills;
