import {
  CheckboxGroupField,
  CheckboxGroupFieldProps
} from '../../../../helpers/FormFields/CheckboxGroupField';

import { projectsStatuses } from '../../projectsConstants';

type ProjectsStatusesCheckboxesFieldProps<T> = Omit<
  CheckboxGroupFieldProps<T>,
  'items'
>;

function ProjectsStatusesCheckboxesField<T>({
  checkboxClassName,
  control,
  disabled,
  i18nLabel,
  inputWrapperClassName,
  labelClassName,
  name
}: ProjectsStatusesCheckboxesFieldProps<T>) {
  const projectItems = projectsStatuses.map(({ value, i18nLabel }) => ({
    id: value,
    i18nLabel
  }));

  return (
    <CheckboxGroupField
      checkboxClassName={checkboxClassName}
      control={control}
      disabled={disabled}
      i18nLabel={i18nLabel}
      inputWrapperClassName={inputWrapperClassName}
      items={projectItems}
      labelClassName={labelClassName}
      name={name}
    />
  );
}

export default ProjectsStatusesCheckboxesField;
