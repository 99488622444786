import { ProjectNanoID } from '../../../../../projectsTypes';

import { useToggleProjectNotificationsEnabled } from '../../../../../hooks/useToggleProjectNotificationsEnabled';

import {
  ItemIndexSidebarInfo,
  ItemIndexSidebarInfoItem
} from '../../../../../../common/components/sidebars/ItemIndexSidebar/components/ItemIndexSidebarInfo';

import { ProjectPath } from '../../../../../ProjectPath';

import { TOGGLE_PROJECT_NOTIFICATIONS_ENABLED_QUERY } from '../../../../../queries/toggleProjectNotificationsEnabled.query';
import { ProjectCache } from '../../../../../ProjectCache';

interface ProjectsIndexSidebarInfoProps {
  project: ItemIndexSidebarInfoItem;
}

const projectsScope = 'projects';

function ProjectsIndexSidebarInfo({ project }: ProjectsIndexSidebarInfoProps) {
  const {
    toggleProjectNotificationsEnabled,
    toggleProjectNotificationsEnabledLoading
  } = useToggleProjectNotificationsEnabled({
    query: TOGGLE_PROJECT_NOTIFICATIONS_ENABLED_QUERY,
    cacheKeys: [ProjectCache.showCacheKey()]
  });

  return (
    <ItemIndexSidebarInfo
      showLinkHref={ProjectPath.show(project.nanoId as ProjectNanoID)}
      isLoading={toggleProjectNotificationsEnabledLoading}
      item={project}
      onToggleItemNotificationsEnabled={toggleProjectNotificationsEnabled}
      scope={projectsScope}
    />
  );
}

export default ProjectsIndexSidebarInfo;
