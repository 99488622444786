import React, { ReactNode } from 'react';

import {
  IsFetched,
  ErrorMessage,
  IsPlaceholderData,
  I18nText,
  PathUrl,
  PositionStyleEnum
} from '../../../../../types';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSidebarSkeleton } from '../../../../../helpers/LoadingSidebarSkeleton';
import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { TooltipDropdownHelper } from '../../../../../helpers/dropdowns/TooltipDropdownHelper';

import { IconsEnum } from '../../../../../assets/icons/types';

import { words } from '../../../../../locales/keys';

interface ItemIndexSidebarProps {
  itemError: ErrorMessage;
  itemFetched: IsFetched;
  itemIsPlaceholderData: IsPlaceholderData;
  children: ReactNode;
  viewLinkHref?: PathUrl;
  viewLinkI18nText?: I18nText;
  onClose: () => void;
  dropdownContent?: ReactNode;
}

function ItemIndexSidebar({
  itemError,
  itemFetched,
  itemIsPlaceholderData,
  children,
  viewLinkHref,
  viewLinkI18nText,
  onClose,
  dropdownContent
}: ItemIndexSidebarProps) {
  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex-shrink-0 h-16 border-b border-gray-200 dark:border-gray-700 hidden" />
      <div className="flex-1 overflow-hidden relative">
        <div className="absolute inset-0 overflow-y-auto overflow-x-hidden py-4 text-sm">
          <AlertMessage addClassName="m-4" message={itemError} />
          <LoadingSidebarSkeleton loaded={itemIsPlaceholderData || itemFetched}>
            {children}
          </LoadingSidebarSkeleton>
        </div>
      </div>
      <div className="block sm:hidden border-gray-200 border-t dark:border-gray-700 flex-shrink px-2 py-3">
        <div className="justify-between flex space-x-2">
          <PureButtonHelper
            className="font-medium hover:bg-gray-200 items-center px-4 py-2 rounded-md text-gray-700 text-sm dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0"
            i18nText="words.close"
            onClick={onClose}
          />
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            {viewLinkHref ? (
              <NextPureLinkHelper
                className="relative inline-flex items-center px-4 py-2 rounded-l-md bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 focus:z-10"
                href={viewLinkHref}
                i18nText={viewLinkI18nText}
              />
            ) : null}
            <TooltipDropdownHelper
              buttonClassName="relative inline-flex items-center px-2 py-2 rounded-r-md border-l border-blue-400 bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 focus:z-10 focus:outline-none"
              icon={IconsEnum.CHEVRON_UP}
              popoverClassName="relative min-w-48 z-10 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700"
              popoverPositionStyle={PositionStyleEnum.fixed}
              tooltipI18nText={words.openOptions}
            >
              {dropdownContent}
            </TooltipDropdownHelper>
          </span>
        </div>
      </div>
    </div>
  );
}

export default ItemIndexSidebar;
