import { Fragment } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { ProjectsIndexPageBatchActionsHeaderButtonsProjects } from './ProjectsIndexPageBatchActionsHeaderButtons.types';

import { InviteUsersToProjectsModalButton } from '../../../../modalButtons/InviteUsersToProjectsModalButton';
import { CreateProjectsBillsModalButton } from '../../../../modalButtons/CreateProjectsBillsModalButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { ProjectsPermissions } from '../../../../../projectsConstants';
import { projectsKeys, stringsKeys } from '../../../../../../../locales/keys';
import { UpdateProjectsAmountsModalButton } from '../../../../modalButtons/UpdateProjectsAmountsModalButton';
import { ProjectCache } from '../../../../../ProjectCache';
import { TooltipSingletonSourceWrapper } from '../../../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

const projectsCacheKeys = [ProjectCache.indexCacheKey()];

interface ProjectsIndexPageBatchActionsHeaderButtonsProps {
  checkedProjects: ProjectsIndexPageBatchActionsHeaderButtonsProjects;
  dropdown?: boolean;
}

function ProjectsIndexPageBatchActionsHeaderButtons({
  checkedProjects,
  dropdown
}: ProjectsIndexPageBatchActionsHeaderButtonsProps) {
  return (
    <TooltipSingletonSourceWrapper
      placement={TooltipPlacement.BOTTOM}
      withArrow
    >
      {dropdown ? (
        <Fragment>
          <CheckPermissions
            action={
              ProjectsPermissions.READ_PROJECTS_BATCH_INVITE_USERS_TO_PROJECTS_BUTTON
            }
          >
            <InviteUsersToProjectsModalButton
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              projects={checkedProjects}
              i18nText={projectsKeys.inviteUser}
              tooltipSingleton
            />
          </CheckPermissions>
          <CheckPermissions
            action={
              ProjectsPermissions.READ_PROJECTS_BATCH_UPDATE_AMOUNTS_BUTTON
            }
          >
            <UpdateProjectsAmountsModalButton
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              projects={checkedProjects}
              cacheKeys={projectsCacheKeys}
              i18nText={stringsKeys.updateCost}
              tooltipSingleton
            />
          </CheckPermissions>
          <CheckPermissions
            action={
              ProjectsPermissions.READ_PROJECTS_BATCH_CREATE_PROJECTS_BILLS_BUTTON
            }
          >
            <CreateProjectsBillsModalButton
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              projects={checkedProjects}
              i18nText={projectsKeys.sendInvoice}
              tooltipSingleton
            />
          </CheckPermissions>
        </Fragment>
      ) : (
        <Fragment>
          <CheckPermissions
            action={
              ProjectsPermissions.READ_PROJECTS_BATCH_INVITE_USERS_TO_PROJECTS_BUTTON
            }
          >
            <InviteUsersToProjectsModalButton
              className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              projects={checkedProjects}
              icon={IconsEnum.USER_ADD_OUTLINE}
              tooltipSingleton
            />
          </CheckPermissions>
          <CheckPermissions
            action={
              ProjectsPermissions.READ_PROJECTS_BATCH_UPDATE_AMOUNTS_BUTTON
            }
          >
            <UpdateProjectsAmountsModalButton
              className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              projects={checkedProjects}
              icon={IconsEnum.CASH_OUTLINE}
              cacheKeys={projectsCacheKeys}
              tooltipSingleton
            />
          </CheckPermissions>
          <CheckPermissions
            action={
              ProjectsPermissions.READ_PROJECTS_BATCH_CREATE_PROJECTS_BILLS_BUTTON
            }
          >
            <CreateProjectsBillsModalButton
              className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              projects={checkedProjects}
              icon={IconsEnum.RECEIPT_REFUND_OUTLINE}
              tooltipSingleton
            />
          </CheckPermissions>
        </Fragment>
      )}
    </TooltipSingletonSourceWrapper>
  );
}

export default ProjectsIndexPageBatchActionsHeaderButtons;
