import React, { Fragment } from 'react';

import { ProjectsIndexSidebarDropdownProject } from './ProjectsIndexSidebarDropdown.types';

import { ProjectsDropdownAddTaskButton } from './components/ProjectsDropdownAddTaskButton';
import { ProjectsDropdownSendInvoiceButton } from './components/ProjectsDropdownSendInvoiceButton';
import { ProjectsDropdownLeaveProjectButton } from './components/ProjectsDropdownLeaveProjectButton';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { ProjectsPermissions } from '../../../../../projectsConstants';

interface ProjectsIndexSidebarDropdownProps {
  project: ProjectsIndexSidebarDropdownProject;
}

function ProjectsIndexSidebarDropdown({
  project
}: ProjectsIndexSidebarDropdownProps) {
  const currentUser = useCurrentUser();

  const isUserInProject = project.members.find(
    (member) => member.uuid === currentUser.uuid
  );

  return (
    <Fragment>
      <ProjectsDropdownAddTaskButton projectNanoId={project.nanoId} />
      <CheckPermissions
        action={ProjectsPermissions.READ_PROJECT_SEND_INVOICE_BUTTON}
      >
        <ProjectsDropdownSendInvoiceButton project={project} />
      </CheckPermissions>
      {isUserInProject && (
        <CheckPermissions
          action={ProjectsPermissions.READ_PROJECT_LEAVE_BUTTON}
        >
          <ProjectsDropdownLeaveProjectButton projectUuid={project.uuid} />
        </CheckPermissions>
      )}
    </Fragment>
  );
}

export default ProjectsIndexSidebarDropdown;
