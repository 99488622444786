import React from 'react';
import toNumber from 'lodash/toNumber';

import { ProjectID, ProjectNanoID } from '../../../../projects/projectsTypes';

import { IconsEnum } from '../../../../../assets/icons/types';

import {
  CREATE_BILL_IN_PROJECT_QUERY,
  CreateBillInProjectQueryResponse
} from '../../../queries/createBillInProject.query';

import { useCreateBillInProject } from '../../../hooks/useCreateBillInProject';

import { useCreateBillForm } from '../../forms/CreateBillForm/hooks/useCreateBillForm';

import { CreateBillForm } from '../../forms/CreateBillForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { invoicesKeys } from '../../../../../locales/keys';

import { ProjectCache } from '../../../../projects/ProjectCache';

const CREATE_PROJECT_BILL_FORM = 'create-project-bill-form';

interface CreateProjectBillModalButtonProps {
  projectId: ProjectID;
  projectNanoId: ProjectNanoID;
  className?: string;
  icon?: IconsEnum;
}

function CreateProjectBillModalButton({
  projectId,
  projectNanoId,
  className,
  icon
}: CreateProjectBillModalButtonProps) {
  const {
    control,
    registerFields,
    validationErrors,
    resetCreateBillForm,
    handleSubmitReactHookForm,
    watchAccount
  } = useCreateBillForm({ type: 'project' });

  const {
    createBillInProjectReset,
    createBillInProjectLoading,
    createBillInProjectErrorMessage,
    createBillInProject
  } = useCreateBillInProject<CreateBillInProjectQueryResponse>({
    query: CREATE_BILL_IN_PROJECT_QUERY,
    cacheKeys: [
      ProjectCache.billsCacheKey(projectNanoId),
      ProjectCache.messagesCacheKey(projectNanoId)
    ]
  });

  return (
    <FormModalButton
      className={
        className ||
        'py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 w-full'
      }
      form={CREATE_PROJECT_BILL_FORM}
      i18nTitle={invoicesKeys.send}
      i18nText={invoicesKeys.send}
      i18nSubmitText={invoicesKeys.send}
      i18nTextClassName="font-medium"
      icon={icon}
      iconClassName="h-6 w-6 p-0.5 transform -scale-x-1 mr-2"
      onOpen={resetCreateBillForm}
      isLoading={createBillInProjectLoading}
      onClose={createBillInProjectReset}
      onSubmit={handleSubmitReactHookForm({
        dirtyFieldsOnly: false,
        onSubmit: async (data) =>
          createBillInProject({
            ...data,
            projectId,
            amount: toNumber(data.amount)
          })
      })}
    >
      <CreateBillForm
        control={control}
        form={CREATE_PROJECT_BILL_FORM}
        isLoading={createBillInProjectLoading}
        type="project"
        registerAmount={registerFields.registerAmount}
        registerBody={registerFields.registerBody}
        amountValidationError={validationErrors.amountValidationError}
        bodyValidationError={validationErrors.bodyValidationError}
        watchAccount={watchAccount}
      />

      <div className="px-4">
        <AlertMessage message={createBillInProjectErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default CreateProjectBillModalButton;
