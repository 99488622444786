import { gql } from 'graphql-request';

export const TOGGLE_PROJECT_NOTIFICATIONS_ENABLED_QUERY = gql`
  mutation ToggleProjectNotificationsEnabled($uuid: ID!) {
    toggleProjectNotificationsEnabled(input: { uuid: $uuid }) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        notificationsEnabled
      }
    }
  }
`;
