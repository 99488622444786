import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import {
  FetchProjectsClearProjectsFilters,
  FetchProjectsFilterProjects,
  FetchProjectsFilters,
  FetchProjectsFilterFields
} from '../../../../../projectsTypes';
import { AfterSubmitFunc } from '../../../../../../../types';
import { CreateSearchFilterFunc } from '../../../../../../searchFilters/searchFiltersTypes';
import { FilterProjectsFormData } from '../../FilterProjectsForm.types';

import { useIndexPageFiltersForm } from '../../../../../../common/hooks/useIndexPageFiltersForm';

import { DateFilterTypeConverter } from '../../../../../../../utils/DateFilterTypeConverter';

import { formsErrors } from '../../../../../../../locales/keys';
import { SearchFiltersScopes } from '../../../../../../searchFilters/searchFiltersConstants';

interface FilterPermissionsFormOptions {
  afterSubmit: AfterSubmitFunc;
  filterProjects: FetchProjectsFilterProjects;
  projectsFilters: FetchProjectsFilters;
  onClear: FetchProjectsClearProjectsFilters;
  onSaveFilters: CreateSearchFilterFunc<FetchProjectsFilters>;
}

function useFilterProjectsForm({
  afterSubmit,
  filterProjects,
  projectsFilters,
  onClear,
  onSaveFilters
}: FilterPermissionsFormOptions) {
  const {
    control,
    errors,
    handleSubmitIndexPageFiltersForm,
    isEditingUsers,
    isFormEmpty,
    onCancel,
    onGoBack,
    register,
    saveFiltersMode,
    setValue,
    toggleIsEditingUsers,
    toggleSaveFiltersMode
  } = useIndexPageFiltersForm<FilterProjectsFormData>({
    defaultValues: {
      burning: projectsFilters.burning,
      colors: [],
      createdAt: DateFilterTypeConverter.toLocalDateRange(
        projectsFilters.createdAt
      ),
      implementationDate: DateFilterTypeConverter.toLocalDateRange(
        projectsFilters.implementationDate
      ),
      performerIds: projectsFilters.performerIds,
      status: projectsFilters.status,
      name: '',
      rankedName: projectsFilters.rankedName,
      tagIds: []
    },
    onClear
  });

  const handleFilterProjectsSubmit = useMemo(
    () =>
      handleSubmitIndexPageFiltersForm({
        dirtyFieldsOnly: false,
        onSubmit: (data: FilterProjectsFormData) => {
          const cleanDataFields = {
            createdAt: DateFilterTypeConverter.toISODateTimeRange(
              data.createdAt
            ),
            performerIds: isEmpty(data.performerIds)
              ? undefined
              : data.performerIds,
            implementationDate: DateFilterTypeConverter.toISODateTimeRange(
              data.implementationDate
            ),
            rankedName: data.rankedName ? data.rankedName : undefined,
            status: isEmpty(data.status?.in) ? undefined : data.status,
            tagIds: isEmpty(data.tagIds)
              ? undefined
              : data.tagIds?.map((t) => t.value)
          };

          const saveFiltersData = {
            data: {
              ...projectsFilters,
              ...omit(data, 'name', 'colors'),
              ...cleanDataFields
            },
            name: data.name,
            colors: isEmpty(data.colors) ? undefined : data.colors,
            scope: SearchFiltersScopes.PROJECTS_INDEX
          };

          const filterProjectsData = omit(
            {
              ...projectsFilters,
              ...data,
              ...cleanDataFields
            },
            'name',
            'colors'
          );

          saveFiltersMode
            ? onSaveFilters(saveFiltersData)
            : filterProjects(filterProjectsData);

          afterSubmit?.();
        }
      }),
    [
      afterSubmit,
      filterProjects,
      handleSubmitIndexPageFiltersForm,
      onSaveFilters,
      projectsFilters,
      saveFiltersMode
    ]
  );

  return {
    control,
    errors,
    handleFilterProjectsSubmit,
    isEditingUsers,
    isFormEmpty,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    registerName: register(
      'name',
      saveFiltersMode
        ? {
            required: formsErrors.required
          }
        : undefined
    ),
    registerRankedName: register(FetchProjectsFilterFields.RANKED_NAME),
    saveFiltersMode,
    setValue,
    toggleIsEditingUsers,
    toggleSaveFiltersMode,
    onCancel,
    onGoBack
  };
}

export default useFilterProjectsForm;
