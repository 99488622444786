import { ClassName, I18nText } from '../../../../../types';

import { ProjectDescription, ProjectUUID } from '../../../projectsTypes';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useUpdateProjectDescriptionForm } from '../../forms/UpdateProjectDescriptionForm/hooks/useUpdateProjectDescriptionForm';

import { UpdateProjectDescriptionForm } from '../../forms/UpdateProjectDescriptionForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { projectsKeys, words } from '../../../../../locales/keys';

interface UpdateProjectDescriptionPureModalButtonProps {
  className?: ClassName;
  buttonChildrenClassName?: ClassName;
  description: ProjectDescription;
  i18nPlaceholder?: I18nText;
  i18nTitle?: I18nText;
  i18nText?: I18nText;
  uuid: ProjectUUID;
  icon?: IconsEnum;
  iconClassName?: ClassName;
}

const UPDATE_PROJECT_DESCRIPTION_PURE_FORM =
  'update-project-description-pure-form';

function UpdateProjectDescriptionPureModalButton({
  className,
  description,
  i18nPlaceholder,
  i18nTitle,
  i18nText,
  uuid,
  icon,
  iconClassName
}: UpdateProjectDescriptionPureModalButtonProps) {
  const {
    handleInitUpdateProjectDescriptionForm,
    handleUpdateProjectDescription,
    registerDescription,
    updateProjectDescriptionErrorMessage,
    updateProjectDescriptionLoading,
    updateProjectDescriptionReset
  } = useUpdateProjectDescriptionForm({
    description,
    uuid
  });

  return (
    <FormModalButton
      className={
        className ||
        'p-0 inline-flex items-center whitespace-nowrap leading-5 focus:ring-base text-current hover:underline underline'
      }
      form={UPDATE_PROJECT_DESCRIPTION_PURE_FORM}
      i18nSubmitText={words.save}
      i18nTitle={i18nTitle || projectsKeys.description}
      i18nText={i18nText}
      isLoading={updateProjectDescriptionLoading}
      icon={icon}
      iconClassName={iconClassName}
      onOpen={handleInitUpdateProjectDescriptionForm}
      onClose={updateProjectDescriptionReset}
      onSubmit={handleUpdateProjectDescription}
    >
      <UpdateProjectDescriptionForm
        form={UPDATE_PROJECT_DESCRIPTION_PURE_FORM}
        i18nPlaceholder={i18nPlaceholder || projectsKeys.addDescription}
        isLoading={updateProjectDescriptionLoading}
        registerDescription={registerDescription}
      />

      <div className="px-4">
        <AlertMessage message={updateProjectDescriptionErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateProjectDescriptionPureModalButton;
