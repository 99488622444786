import { useMemo } from 'react';

import { FieldPath } from 'react-hook-form';

import {
  TransactionTagID,
  TransactionTagLocalizedName
} from '../../transactionTagsTypes';
import {
  SelectDefaultValueType,
  SelectProps
} from '../../../../helpers/Select';

import {
  FETCH_TRANSACTION_TAG_NAMES_QUERY,
  FetchTransactionTagNamesQueryResponse
} from '../../queries/fetchTransactionTagsNames.query';

import { usePaginatedTransactionTags } from '../../hooks/usePaginatedTransactionTags';

import {
  SelectField,
  SelectFieldRequiredProps
} from '../../../../helpers/FormFields/SelectField';

import { TransactionTagCache } from '../../TransactionTagCache';

interface TransactionTagsSelectField<
  FormDataType,
  ValueType = SelectDefaultValueType
> extends Omit<SelectProps<ValueType>, 'onChange' | 'value' | 'options'> {
  name: FieldPath<FormDataType>;
  error?: string | null;
}

function TransactionTagsSelectField<
  FormDataType,
  ValueType = SelectDefaultValueType
>({
  i18nPlaceholder,
  name,
  control,
  disabled,
  i18nLabel,
  error,
  labelClassName
}: TransactionTagsSelectField<FormDataType, ValueType> &
  SelectFieldRequiredProps<FormDataType>) {
  const { transactionTags, transactionTagsError, transactionTagsLoading } =
    usePaginatedTransactionTags<FetchTransactionTagNamesQueryResponse>({
      cacheKey: TransactionTagCache.indexCacheKey(),
      query: FETCH_TRANSACTION_TAG_NAMES_QUERY
    });

  const transactionTagsData = useMemo<
    { value: TransactionTagID; label: TransactionTagLocalizedName }[]
  >(() => {
    return transactionTags.map((transactionTag) => ({
      value: transactionTag.id,
      label: transactionTag.localizedName
    }));
  }, [transactionTags]);

  return (
    <SelectField
      name={name}
      control={control}
      disabled={transactionTagsLoading || disabled}
      i18nLabel={i18nLabel}
      labelClassName={
        labelClassName ||
        'block text-sm font-medium text-gray-700 dark:text-gray-300'
      }
      options={transactionTagsData}
      i18nPlaceholder={i18nPlaceholder}
      error={transactionTagsError || error}
    />
  );
}

export default TransactionTagsSelectField;
