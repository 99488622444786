import { gql } from 'graphql-request';

export const UPDATE_PROJECTS_AMOUNTS_QUERY = gql`
  mutation UpdateProjectsAmounts($amounts: [UpdateProjectsAmountsInput!]!) {
    updateProjectsAmounts(input: { amounts: $amounts }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
