import React, { ChangeEventHandler, useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';

import { useTranslate } from '../../../../../common/hooks/useTranslate';

import { PureIconButtonHelper } from '../../../../buttons/PureIconButtonHelper';

import { ItemsListFieldListInputRequiredProps } from './ItemsListFieldListInput.types';

interface ItemsListFieldListInputProps {
  name?: string;
  id: string;
  value: string;
  onChange: (id: string, value: string) => void;
  onRemove: (id: string) => void;
}

function ItemsListFieldListInput({
  name,
  id,
  value,
  i18nPlaceholder,
  placeholder,
  onChange,
  onRemove
}: ItemsListFieldListInputProps & ItemsListFieldListInputRequiredProps) {
  const { t } = useTranslate();

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => onChange(id, e.target.value),
    [id, onChange]
  );

  const handleRemove = useCallback<() => void>(
    () => onRemove(id),
    [id, onRemove]
  );
  return (
    <div className="flex space-x-1">
      <div className="flex-1">
        <div>
          <div className="relative">
            <input
              id={name}
              className="basic-input"
              placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
              name={`item-${id}`}
              value={value}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="flex-shrink-0">
        <PureIconButtonHelper
          className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
          icon={IconsEnum.MINUS_SM_SOLID}
          onClick={handleRemove}
        />
      </div>
    </div>
  );
}

export default ItemsListFieldListInput;
