import { IconsEnum } from '../../../../../assets/icons/types';

import { useCreateProjectForm } from '../../forms/CreateProjectForm/hooks/useCreateProjectForm';

import { CreateProjectForm } from '../../forms/CreateProjectForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { projectsKeys, words } from '../../../../../locales/keys';

const CREATE_PROJECT_FORM = 'create-project-form';

function CreateProjectModalButton() {
  const {
    validationErrors,
    registerFields,
    handleCreateProject,
    resetCreateProjectForm,
    createProjectReset,
    createProjectLoading,
    createProjectErrorMessage,
    control
  } = useCreateProjectForm();

  return (
    <FormModalButton
      className="inline-flex items-center px-2 sm:px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm hover:shadow-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 dark:focus:ring-gray-100 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900"
      form={CREATE_PROJECT_FORM}
      i18nTitle={projectsKeys.create}
      i18nText={words.create}
      i18nTextClassName="hidden sm:inline"
      i18nSubmitText={projectsKeys.create}
      icon={IconsEnum.PLUS}
      iconClassName="w-5 h-5 sm:hidden"
      onOpen={resetCreateProjectForm}
      isLoading={createProjectLoading}
      onClose={createProjectReset}
      onSubmit={handleCreateProject}
    >
      <CreateProjectForm
        form={CREATE_PROJECT_FORM}
        isLoading={createProjectLoading}
        control={control}
        registerName={registerFields.registerName}
        registerEstimateCost={registerFields.registerEstimateCost}
        registerImplementationDate={registerFields.registerImplementationDate}
        nameValidationError={validationErrors.nameValidationError}
        estimateCostValidationError={
          validationErrors.estimateCostValidationError
        }
        implementationDateValidationError={
          validationErrors.implementationDateValidationError
        }
        registerDescription={registerFields.registerDescription}
      />

      <div className="px-4">
        <AlertMessage message={createProjectErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default CreateProjectModalButton;
