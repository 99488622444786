import { useCallback, useMemo } from 'react';

import {
  YesNoAnyRadioButtons,
  YesNoAnyRadioButtonsValue
} from '../../../../YesNoAnyRadioButtons';

interface YesNoAnyRadioButtonsControlProps {
  name: string;
  onChange: (value?: boolean | null) => void;
  value?: boolean;
}

function YesNoAnyRadioButtonsControl({
  name,
  onChange,
  value
}: YesNoAnyRadioButtonsControlProps) {
  const handleChange = useCallback<(value: YesNoAnyRadioButtonsValue) => void>(
    (value) => {
      switch (value) {
        case YesNoAnyRadioButtonsValue.YES:
          onChange(true);
          break;
        case YesNoAnyRadioButtonsValue.NO:
          onChange(false);
          break;
        case YesNoAnyRadioButtonsValue.ANY:
          onChange(null);
          break;
      }
    },
    [onChange]
  );

  const yesNoAnyRadioButtonsValue = useMemo(() => {
    switch (value) {
      case true:
        return YesNoAnyRadioButtonsValue.YES;
      case false:
        return YesNoAnyRadioButtonsValue.NO;
      default:
        return YesNoAnyRadioButtonsValue.ANY;
    }
  }, [value]);

  return (
    <YesNoAnyRadioButtons
      name={name}
      value={yesNoAnyRadioButtonsValue}
      onChange={handleChange}
    />
  );
}

export default YesNoAnyRadioButtonsControl;
