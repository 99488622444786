import { gql } from 'graphql-request';

import { ProjectNanoID, ProjectUUID, ProjectFavorite } from '../projectsTypes';

export interface ToggleProjectFavoriteQueryResponse {
  uuid: ProjectUUID;
  nanoId: ProjectNanoID;
  favorite: ProjectFavorite;
}

export const TOGGLE_PROJECT_FAVORITE_QUERY = gql`
  mutation ToggleProjectFavorite($uuid: ID!) {
    toggleProjectFavorite(input: { uuid: $uuid }) {
      errors {
        fullMessages
      }
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
        favorite
      }
    }
  }
`;
