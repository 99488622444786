import { IconsEnum } from '../../../../../../../assets/icons/types';

import { CreateTaskModalButton } from '../../../../../../tasks/components/modalButtons/CreateTaskModalButton';

import { TasksStatusesIndicator } from '../../../../../../tasks/helpers/TasksStatusesIndicator';
import { Translate } from '../../../../../../../helpers/Translate';
import { tasksKeys } from '../../../../../../../locales/keys';

import { ProjectsIndexSidebarTasksProject } from './ProjectsIndexSidebarTasks.types';

interface ProjectsIndexSidebarTasksProps {
  project: ProjectsIndexSidebarTasksProject;
}

function ProjectsIndexSidebarTasks({
  project
}: ProjectsIndexSidebarTasksProps) {
  return (
    <div className="p-4">
      <div className="flex justify-between mb-2">
        <div>
          <h6 className="font-light uppercase">
            <Translate id="models.tasks.plural" />
          </h6>
        </div>
        <div className="flex space-x-1">
          <span>{project.performedTasksCount}</span> <span>/</span>
          <span>{project.activeTasksCount}</span>
        </div>
      </div>
      <div className="-mx-2 my-2">
        <CreateTaskModalButton
          className="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 w-full"
          icon={IconsEnum.PLUS_SM_SOLID}
          i18nText={tasksKeys.add}
          projectNanoId={project.nanoId}
        />
      </div>
      <TasksStatusesIndicator
        acceptanceTasksCount={project.acceptanceTasksCount}
        canceledTasksCount={project.canceledTasksCount}
        definitionTasksCount={project.definitionTasksCount}
        implementationTasksCount={project.implementationTasksCount}
        incompleteSpecificationTasksCount={
          project.incompleteSpecificationTasksCount
        }
        performedTasksCount={project.performedTasksCount}
        qaTasksCount={project.qaTasksCount}
      />
    </div>
  );
}

export default ProjectsIndexSidebarTasks;
