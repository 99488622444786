import React, { Fragment, useCallback, useState } from 'react';
import compact from 'lodash/compact';
import first from 'lodash/first';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import values from 'lodash/values';
import cl from 'classnames';

import { IconsEnum } from '../../../../../assets/icons/types';

import { PureIconButtonHelper } from '../../../../buttons/PureIconButtonHelper';

import { ItemsListFieldListInput } from '../ItemsListFieldListInput';

import { Translate } from '../../../../Translate';
import { generateNanoId } from '../../../../../utils/generateNanoId';

import { ItemsListFieldListRequiredProps } from './ItemsListFieldList.types';

interface ItemsListFieldListState {
  [id: string]: string;
}

interface ItemsListFieldListProps {
  value: string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
}

function ItemsListFieldList({
  value,
  disabled,
  labelFor,
  i18nLabel,
  label,
  labelClassName,
  addButtonI18nText,
  addButtonClassName,
  i18nPlaceholder,
  onChange
}: ItemsListFieldListProps & ItemsListFieldListRequiredProps) {
  const [items, setItems] = useState<ItemsListFieldListState>(
    fromPairs<string>(value.map((item) => [`item-${generateNanoId()}`, item]))
  );

  const handleInputChange = useCallback<
    (id: string, inputValue: string) => void
  >(
    (id: string, inputValue: string) => {
      const updatedItems = {
        ...items,
        [id]: inputValue
      };

      onChange(
        compact<string>(
          values(updatedItems).map((item) => (item === '' ? null : item))
        )
      );

      setItems(updatedItems);
    },
    [items, setItems, onChange]
  );

  const handleInputRemove = useCallback<(id: string) => void>(
    (id: string) => {
      const updatedItems = omit(items, id);

      onChange(
        compact<string>(
          values(updatedItems).map((item) => (item === '' ? null : item))
        )
      );

      setItems(updatedItems);
    },
    [items, setItems, onChange]
  );

  const handleAddItem = useCallback<() => void>(
    () =>
      setItems((prevState) => ({
        ...prevState,
        [`item-${generateNanoId()}`]: ''
      })),
    [setItems]
  );

  const itemsKeys = keys(items);

  return (
    <Fragment>
      <div className="mb-2">
        {i18nLabel || label ? (
          <label
            htmlFor={labelFor}
            className={cl(
              labelClassName ||
                'block text-sm font-medium text-gray-700 dark:text-gray-300'
            )}
          >
            {i18nLabel ? <Translate id={i18nLabel} /> : label}
          </label>
        ) : null}
        <div className="space-y-2 mt-1">
          {itemsKeys.map((id) => (
            <ItemsListFieldListInput
              key={id}
              name={first(itemsKeys) === id ? labelFor : null}
              id={id}
              value={items[id]}
              i18nPlaceholder={i18nPlaceholder}
              onChange={handleInputChange}
              onRemove={handleInputRemove}
            />
          ))}
        </div>
      </div>
      <div>
        <PureIconButtonHelper
          className={
            addButtonClassName ||
            'py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
          }
          icon={IconsEnum.PLUS_SM_SOLID}
          iconClassName="h-6 w-6 p-0.5"
          disabled={disabled}
          i18nText={addButtonI18nText}
          onClick={handleAddItem}
        />
      </div>
    </Fragment>
  );
}

export default ItemsListFieldList;
