import {
  ToggleProjectNotificationsEnabledGqlStatus,
  ToggleProjectNotificationsEnabledCacheKeys,
  ToggleProjectNotificationsEnabledGqlError,
  ToggleProjectNotificationsEnabledGqlQuery
} from '../../projectsTypes/ToggleProjectNotificationsEnabled.types';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';
import { ProjectNanoID, ProjectUUID } from '../../projectsTypes';

interface ToggleProjectNotificationsEnabledOptions {
  query: ToggleProjectNotificationsEnabledGqlQuery;
  cacheKeys?: ToggleProjectNotificationsEnabledCacheKeys;
}
export interface ToggleProjectNotificationsEnabledResponse<
  ToggleProjectNotificationsEnabledRecordType
> {
  toggleProjectNotificationsEnabled: {
    status: ToggleProjectNotificationsEnabledGqlStatus;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: ToggleProjectNotificationsEnabledRecordType;
    errors: ToggleProjectNotificationsEnabledError;
  };
}

export interface ToggleProjectNotificationsEnabledInput {
  uuid: ProjectUUID;
}

export interface ToggleProjectNotificationsEnabledError {
  fullMessages: ToggleProjectNotificationsEnabledGqlError;
}

const action = 'toggleProjectNotificationsEnabled';

function useToggleProjectNotificationsEnabled<
  ToggleProjectNotificationsEnabledRecordType
>({ query, cacheKeys }: ToggleProjectNotificationsEnabledOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleProjectNotificationsEnabledInput,
    ToggleProjectNotificationsEnabledResponse<ToggleProjectNotificationsEnabledRecordType>,
    ToggleProjectNotificationsEnabledError,
    ToggleProjectNotificationsEnabledRecordType
  >({ action, cacheKeys, query });

  return {
    toggleProjectNotificationsEnabledData: updateQueryData,
    toggleProjectNotificationsEnabledError: updateQueryError,
    toggleProjectNotificationsEnabledLoading: updateQueryLoading,
    toggleProjectNotificationsEnabledErrorMessage: updateQueryErrorMessage,
    toggleProjectNotificationsEnabled: updateQuery,
    toggleProjectNotificationsEnabledReset: updateQueryReset
  };
}

export default useToggleProjectNotificationsEnabled;
