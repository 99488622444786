import { memo } from 'react';
import cl from 'classnames';

import { Translate } from '../../../../helpers/Translate';

interface TasksStatusesIndicatorProps {
  acceptanceTasksCount: number;
  canceledTasksCount: number;
  definitionTasksCount: number;
  implementationTasksCount: number;
  incompleteSpecificationTasksCount: number;
  performedTasksCount: number;
  qaTasksCount: number;
}

function TasksStatusesIndicator({
  acceptanceTasksCount,
  canceledTasksCount,
  definitionTasksCount,
  implementationTasksCount,
  incompleteSpecificationTasksCount,
  performedTasksCount,
  qaTasksCount
}: TasksStatusesIndicatorProps) {
  const totalTasksCount =
    acceptanceTasksCount +
    canceledTasksCount +
    definitionTasksCount +
    implementationTasksCount +
    incompleteSpecificationTasksCount +
    performedTasksCount +
    qaTasksCount;

  const tasksStatuses = [
    {
      i18nStatusText: 'models.statuses.types.acceptance',
      tasksCount: acceptanceTasksCount,
      colorClassName: 'bg-green-400 dark:bg-green-600'
    },
    {
      i18nStatusText: 'models.statuses.types.canceled',
      tasksCount: canceledTasksCount,
      colorClassName: 'bg-red-500 dark:bg-red-700'
    },
    {
      i18nStatusText: 'models.statuses.types.definition',
      tasksCount: definitionTasksCount,
      colorClassName: 'bg-gray-300 dark:bg-gray-700'
    },
    {
      i18nStatusText: 'models.statuses.types.implementation',
      tasksCount: implementationTasksCount,
      colorClassName: 'bg-blue-500 dark:bg-blue-700'
    },
    {
      i18nStatusText: 'models.statuses.types.incompleteSpecification',
      tasksCount: incompleteSpecificationTasksCount,
      colorClassName: 'bg-yellow-500 dark:bg-yellow-700'
    },
    {
      i18nStatusText: 'models.statuses.types.performed',
      tasksCount: performedTasksCount,
      colorClassName: 'bg-green-500 dark:bg-green-700'
    },
    {
      i18nStatusText: 'models.statuses.types.qa',
      tasksCount: qaTasksCount,
      colorClassName: 'bg-purple-500 dark:bg-purple-700'
    }
  ];

  return totalTasksCount ? (
    <>
      <div className="h-2 flex space-x-0.5 rounded-full overflow-hidden mb-3">
        {tasksStatuses.map(({ i18nStatusText, tasksCount, colorClassName }) =>
          tasksCount ? (
            <div
              className={colorClassName}
              key={i18nStatusText}
              style={{
                width: `${(tasksCount / totalTasksCount) * 100.0}%`
              }}
            />
          ) : null
        )}
      </div>

      <div className="flex flex-wrap -my-0.5 -mx-2">
        {tasksStatuses.map(({ i18nStatusText, tasksCount, colorClassName }) =>
          tasksCount ? (
            <div
              className="items-center inline-flex space-x-1 my-0.5 mx-2"
              key={i18nStatusText}
            >
              <span
                className={cl('inline-block p-1 rounded-full', colorClassName)}
              />
              <span>
                <Translate id={i18nStatusText} />
              </span>
            </div>
          ) : null
        )}
      </div>
    </>
  ) : null;
}

export default memo(TasksStatusesIndicator);
