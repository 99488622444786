import React from 'react';

import {
  I18nText,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';

import { Form } from '../../../../../helpers/Form';
import { TextAreaField } from '../../../../../helpers/FormFields/TextAreaField';
interface UpdateProjectDescriptionFormProps {
  form: string;
  i18nPlaceholder?: I18nText;
  isLoading?: IsLoading;
  registerDescription: RegisterFormFieldType<HTMLTextAreaElement>;
}

function UpdateProjectDescriptionForm({
  form,
  i18nPlaceholder,
  isLoading,
  registerDescription
}: UpdateProjectDescriptionFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <div className="">
          <div className="">
            <TextAreaField
              disabled={isLoading}
              i18nPlaceholder={i18nPlaceholder}
              labelClassName="font-light uppercase"
              name={registerDescription.name}
              onChange={registerDescription.onChange}
              ref={registerDescription.ref}
              rows={6}
            />
          </div>
        </div>
      </div>
    </Form>
  );
}

export default UpdateProjectDescriptionForm;
