import { Fragment, ReactNode } from 'react';
import range from 'lodash/range';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

interface LoadingTableSkeletonProps {
  children?: ReactNode;
  loaded?: boolean;
  rows?: number;
  cells?: number;
}

function LoadingTableSkeleton({
  children,
  loaded,
  rows = 5,
  cells = 4
}: LoadingTableSkeletonProps) {
  if (loaded) return <Fragment>{children}</Fragment>;

  const cellItems = range(cells).map((key) => (
    <Skeleton
      key={key}
      className="mx-0.5 sm:mx-4 flex justify-center"
      containerClassName="flex justify-start items-center w-full"
    />
  ));

  return (
    <div className="xl:mx-4 border-t border-b xl:border bg-white dark:bg-gray-900 dark:border-gray-700 xl:rounded-lg rounded-md dark:divide-gray-800 divide-gray-200 divide-y overflow-hidden">
      <div className="flex xl:px-4 justify-between items-center h-12 bg-gray-50 dark:bg-gray-850">
        <Skeleton
          height={0}
          width={50}
          className="mx-0.5 sm:mx-4 flex justify-center"
          containerClassName="flex justify-start items-center flex-shrink"
        />
        {cellItems}
      </div>
      {range(rows).map((key) => (
        <div
          key={key}
          className="flex xl:px-4 justify-between items-center h-16"
        >
          <Skeleton
            circle={true}
            height={50}
            width={50}
            className="flex justify-center"
            containerClassName="mx-0.5 sm:mx-4 flex justify-center flex-shrink"
          />
          {cellItems}
        </div>
      ))}
    </div>
  );
}

export default LoadingTableSkeleton;
