import React from 'react';

import { ProjectNanoID } from '../../../projectsTypes';

import { CreateProjectBillModalButton } from '../../../../bills/components/modalButtons/CreateProjectBillModalButton';

import { ProjectSidebarCreateBillProject } from './ProjectSidebarCreateBill.types';

import { IconsEnum } from '../../../../../assets/icons/types';

interface ProjectSidebarCreateBillProps {
  project: ProjectSidebarCreateBillProject;
  projectNanoId: ProjectNanoID;
}

function ProjectSidebarCreateBill({
  project,
  projectNanoId
}: ProjectSidebarCreateBillProps) {
  return (
    <CreateProjectBillModalButton
      projectId={project.id}
      projectNanoId={projectNanoId}
      icon={IconsEnum.RECEIPT_REFUND}
      className="flex items-center w-full py-2 pl-2 pr-4 bg-gray-100 dark:bg-gray-850 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md text-sm focus:ring-offset-0"
    />
  );
}

export default ProjectSidebarCreateBill;
