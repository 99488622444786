import { Currencies } from '../../../../../../../types';

import { CreateProjectsBillsFormData } from '../../CreateProjectsBillsForm.types';
import { ProjectBillFields, ProjectID } from '../../../../../projectsTypes';

import { CREATE_PROJECTS_BILLS_QUERY } from '../../../../../queries/createProjectsBills.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useCreateProjectsBills } from '../../../../../hooks/useCreateProjectsBills';

import { ProjectCache } from '../../../../../ProjectCache';
import { createProjectsBillsFormSchema } from './useCreateProjectsBillsForm.schema';

const createProjectsBillsFormData: CreateProjectsBillsFormData = {
  account: 'payoneer',
  allocableUser: 'client',
  buttons: 'card',
  currency: Currencies.USD
};

interface useCreateProjectsBillsOptions {
  projectIds: ProjectID[];
}

function useCreateProjectsBillsForm({
  projectIds
}: useCreateProjectsBillsOptions) {
  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    watch
  } = useReactHookForm<CreateProjectsBillsFormData>({
    defaultValues: createProjectsBillsFormData,
    isModalForm: true,
    schema: createProjectsBillsFormSchema
  });

  const {
    createProjectsBillsLoading,
    createProjectsBillsErrorMessage,
    createProjectsBills,
    createProjectsBillsReset
  } = useCreateProjectsBills({
    query: CREATE_PROJECTS_BILLS_QUERY,
    cacheKeys: [ProjectCache.indexCacheKey()]
  });

  return {
    handleCreateProjectsBills: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data) =>
        //console.log(data)
        createProjectsBills({
          projectIds,
          bill: data
        })
    }),
    validationErrors: {
      amountValidationError: errors?.amount?.message,
      bodyValidationError: errors?.body?.message
    },
    registerFields: {
      registerAmount: register(ProjectBillFields.AMOUNT),
      registerBody: register(ProjectBillFields.BODY)
    },
    watchAccount: watch(ProjectBillFields.ACCOUNT),
    control,
    resetCreateProjectsBillForm: resetForm,
    createProjectsBillsErrorMessage,
    createProjectsBillsLoading,
    createProjectsBillsReset
  };
}

export default useCreateProjectsBillsForm;
