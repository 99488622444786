import React, { memo } from 'react';

import { Locale } from '../../../../../types';

import { Icon } from '../../../../../helpers/Icon';
import { IconsEnum } from '../../../../../assets/icons/types';

import { IndexTableCell } from '../IndexTableCell';

import { DateHelper } from '../../../../../helpers/DateHelper';

interface IndexTableRowDateBurningProps {
  burning?: boolean;
  date: string;
  locale?: Locale;
}

function IndexTableRowDateBurning({
  burning,
  date,
  locale
}: IndexTableRowDateBurningProps) {
  return (
    <IndexTableCell>
      <div className="text-gray-900 dark:text-gray-200 whitespace-nowrap text-sm">
        <span>
          <DateHelper date={date} locale={locale} />
        </span>
        {burning ? <Icon icon={IconsEnum.BURNING} /> : null}
      </div>
    </IndexTableCell>
  );
}

export default memo<IndexTableRowDateBurningProps>(IndexTableRowDateBurning);
