import { gql } from 'graphql-request';

import { ProjectDescription, ProjectUUID } from '../projectsTypes';

export interface UpdateProjectDescriptionQueryResponse {
  uuid: ProjectUUID;
  description: ProjectDescription;
}

export const UPDATE_PROJECT_DESCRIPTION_QUERY = gql`
  mutation UpdateProjectDescription($uuid: ID!, $description: String!) {
    updateProjectDescription(
      input: { uuid: $uuid, description: $description }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        description
      }
      errors {
        description
        fullMessages
      }
    }
  }
`;
