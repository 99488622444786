import { gql } from 'graphql-request';

import {
  ProjectMemberUUID,
  ProjectMemberTrackedTasksTime,
  ProjectMemberTrackedTime,
  ProjectMemberCreatedAt,
  ProjectMemberUserID,
  ProjectMemberUserUUID,
  ProjectMemberUserFullName,
  ProjectMemberUserImageUUID,
  ProjectMemberUserImageFile,
  ProjectMemberProjectUuid,
  ProjectMemberNotificationsEnabled,
  ProjectMemberID,
  ProjectMemberProjectNanoID,
  ProjectMemberUserNanoID,
  ProjectMemberUserFinanceRoleName
} from '../projectMembersTypes';

export interface FetchProjectMembersQueryResponse {
  id: ProjectMemberID;
  uuid: ProjectMemberUUID;
  trackedTasksTime: ProjectMemberTrackedTasksTime;
  trackedTime: ProjectMemberTrackedTime;
  createdAt: ProjectMemberCreatedAt;
  notificationsEnabled: ProjectMemberNotificationsEnabled;
  user: {
    id: ProjectMemberUserID;
    uuid: ProjectMemberUserUUID;
    nanoId: ProjectMemberUserNanoID;
    fullName: ProjectMemberUserFullName;
    financeRoleName: ProjectMemberUserFinanceRoleName;
    image: {
      uuid: ProjectMemberUserImageUUID;
      file: ProjectMemberUserImageFile;
    };
  };
  project: {
    uuid: ProjectMemberProjectUuid;
    nanoId: ProjectMemberProjectNanoID;
  };
}

export const FETCH_PROJECT_MEMBERS_QUERY = gql`
  query ProjectMembers(
    $filters: ProjectMembersFilters
    $sort: [ProjectMembersSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    projectMembers(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        trackedTasksTime
        trackedTime
        createdAt
        notificationsEnabled
        user {
          id
          uuid
          nanoId
          fullName
          financeRoleName
          image {
            uuid
            file
          }
        }
        project {
          uuid
          nanoId
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
