import { useCallback } from 'react';

import { ProjectID } from '../../../../../projectsTypes';

import { INVITE_USERS_TO_PROJECTS_QUERY } from '../../../../../queries/inviteUsersToProjects.query';

import { useSelectedUsers } from '../../../../../../common/modalButtons/InviteUsersModalButton/hooks/useSelectedUsers';
import {
  useInviteUsersToProjects,
  InviteUsersToProjectsResponse
} from '../../../../../hooks/useInviteUsersToProjects';

import { raiseError } from '../../../../../../../utils/raiseError';

interface InviteUsersToProjectsModalButtonOptions {
  projectIds: ProjectID[];
}

function useInviteUsersToProjectsModalButton({
  projectIds
}: InviteUsersToProjectsModalButtonOptions) {
  const {
    selectedUsers,
    selectedUserIds,
    handleSelectUser,
    handleDeselectUser,
    handleDeselectAllUsers
  } = useSelectedUsers();

  const {
    inviteUsersToProjects,
    inviteUsersToProjectsErrorMessage,
    inviteUsersToProjectsLoading
  } = useInviteUsersToProjects({ query: INVITE_USERS_TO_PROJECTS_QUERY });

  const handleInviteUsersToProjects = useCallback<
    () => Promise<InviteUsersToProjectsResponse | void>
  >(
    () =>
      inviteUsersToProjects({
        projectIds,
        userIds: selectedUserIds
      })
        .catch((reason) => raiseError(reason))
        .then(() => Promise.resolve()),
    [inviteUsersToProjects, projectIds, selectedUserIds]
  );

  return {
    inviteUsersToProjectsErrorMessage,
    inviteUsersToProjectsLoading,
    handleDeselectAllUsers,
    handleDeselectUser,
    handleInviteUsersToProjects,
    handleSelectUser,
    selectedUserIds,
    selectedUsers
  };
}

export default useInviteUsersToProjectsModalButton;
