import {
  CreateBillInProjectGqlQuery,
  CreateBillInProjectGqlStatus,
  BillGqlError,
  BillUUID,
  BillProjectID,
  BillAmount,
  FetchBillsCacheKey,
  BillNanoID
} from '../../billsTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface CreateBillInProjectInput {
  projectId: BillProjectID;
  amount: BillAmount;
}

interface CreateBillInProjectResponse<CreateBillInProjectRecordType> {
  createBillInProject: {
    status: CreateBillInProjectGqlStatus;
    recordUuid: BillUUID;
    recordNanoId: BillNanoID;
    record: CreateBillInProjectRecordType;
    errors: CreateBillInProjectErrors;
  };
}

interface CreateBillInProjectErrors {
  projectId: BillGqlError;
  fullMessages: BillGqlError;
}

interface CreateBillInProjectOptions {
  query: CreateBillInProjectGqlQuery;
  cacheKeys?: FetchBillsCacheKey[];
}

const action = 'createBillInProject';

function useCreateBillInProject<CreateBillInProjectRecordType>({
  query,
  cacheKeys
}: CreateBillInProjectOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateBillInProjectInput,
    CreateBillInProjectResponse<CreateBillInProjectRecordType>,
    CreateBillInProjectErrors,
    CreateBillInProjectRecordType
  >({
    action,
    cacheKeys,
    query
  });

  return {
    createBillInProjectData: createQueryData,
    createBillInProjectError: createQueryError,
    createBillInProjectLoading: createQueryLoading,
    createBillInProjectErrorMessage: createQueryErrorMessage,
    createBillInProject: createQuery,
    createBillInProjectReset: createQueryReset
  };
}

export default useCreateBillInProject;
