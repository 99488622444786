import { ProjectEstimateCost, ProjectID } from '../../projectsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';
import {
  UpdateProjectsAmountsCacheKeys,
  UpdateProjectsAmountsGqlError,
  UpdateProjectsAmountsGqlQuery,
  UpdateProjectsAmountsGqlStatus
} from '../../projectsTypes/useUpdateProjectsAmounts.types';

interface UpdateProjectsAmountsOptions {
  query: UpdateProjectsAmountsGqlQuery;
  cacheKeys: UpdateProjectsAmountsCacheKeys;
}

export interface UpdateProjectsAmountsResponse {
  updateProjectsAmounts: {
    status: UpdateProjectsAmountsGqlStatus;
    errors: UpdateProjectsAmountsError;
  };
}

interface UpdateProjectsAmountsInput {
  amount: ProjectEstimateCost;
  projectId: ProjectID;
}

export interface UpdateProjectsAmountsMutationInput {
  amounts: UpdateProjectsAmountsInput[];
}

export interface UpdateProjectsAmountsError {
  fullMessages: UpdateProjectsAmountsGqlError;
}

const action = 'updateProjectsAmounts';

function useUpdateProjectsAmounts({
  query,
  cacheKeys
}: UpdateProjectsAmountsOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateProjectsAmountsMutationInput,
    UpdateProjectsAmountsResponse,
    UpdateProjectsAmountsError,
    undefined
  >({ action, cacheKeys, query });

  return {
    updateProjectsAmountsData: updateQueryData,
    updateProjectsAmountsError: updateQueryError,
    updateProjectsAmountsLoading: updateQueryLoading,
    updateProjectsAmountsErrorMessage: updateQueryErrorMessage,
    updateProjectsAmounts: updateQuery,
    updateProjectsAmountsReset: updateQueryReset
  };
}

export default useUpdateProjectsAmounts;
