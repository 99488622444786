import React from 'react';
import filter from 'lodash/filter';
import first from 'lodash/first';
import slice from 'lodash/slice';
import size from 'lodash/size';

import { I18nText } from '../../../../../types';

import { IndexTableCell } from '../IndexTableCell';
import { UsersSimpleModalButton } from '../../../../users/components/buttons/UsersSimpleModalButton';

import { UserAvatar } from '../../../helpers/UserAvatar';

import { IndexTableCellUsersUser } from './IndexTableCellUsers.types';

interface IndexTableCellUsersProps {
  ignoreUser?: IndexTableCellUsersUser;
  users: IndexTableCellUsersUser[];
  i18nModalTitle: I18nText;
}

const xsUsersCount = 2;

function IndexTableCellUsers({
  users,
  ignoreUser,
  i18nModalTitle
}: IndexTableCellUsersProps) {
  const actualUsers = ignoreUser
    ? filter(users, (user) => user.uuid !== ignoreUser.uuid)
    : users;
  const restUsersCount = size(actualUsers) - xsUsersCount - 1;

  return (
    <IndexTableCell>
      <UsersSimpleModalButton
        users={users}
        i18nModalTitle={i18nModalTitle}
        buttonChildren={
          <div className="flex items-center space-x-1.5">
            <div className="flex items-center flex-shrink-0 -space-x-1">
              <UserAvatar type="xl" user={first(actualUsers)} />
              {slice(actualUsers, xsUsersCount - 1, xsUsersCount + 1).map(
                (user) => (
                  <UserAvatar key={user.uuid} type="xs" user={user} />
                )
              )}
            </div>
            {restUsersCount > 0 ? (
              <span className="flex-shrink-0 text-xs leading-5 font-medium">
                +{restUsersCount}
              </span>
            ) : null}
          </div>
        }
      />
    </IndexTableCell>
  );
}

export default IndexTableCellUsers;
