import {
  FetchToggleProjectFavoriteCacheKey,
  ProjectNanoID,
  ProjectUUID
} from '../../projectsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleProjectFavoriteOptions<ToggleProjectFavoriteRecordType> {
  query: string;
  cacheKeys?: FetchToggleProjectFavoriteCacheKey[];
  onSuccess?: (responseRecord: ToggleProjectFavoriteRecordType) => void;
}

export interface ToggleProjectFavoriteResponse<
  ToggleProjectFavoriteRecordType
> {
  toggleProjectFavorite: {
    status: string;
    record: ToggleProjectFavoriteRecordType;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    errors: ToggleProjectFavoriteError;
  };
}

export interface ToggleProjectFavoriteInput {
  uuid: ProjectUUID;
}

export interface ToggleProjectFavoriteError {
  fullMessages: string[] | null;
}

const action = 'toggleProjectFavorite';

function useToggleProjectFavorite<ToggleProjectFavoriteRecordType>({
  query,
  cacheKeys,
  onSuccess
}: ToggleProjectFavoriteOptions<ToggleProjectFavoriteRecordType>) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleProjectFavoriteInput,
    ToggleProjectFavoriteResponse<ToggleProjectFavoriteRecordType>,
    ToggleProjectFavoriteError,
    ToggleProjectFavoriteRecordType
  >({ action, cacheKeys, query, onSuccess });

  return {
    toggleProjectFavoriteData: updateQueryData,
    toggleProjectFavoriteError: updateQueryError,
    toggleProjectFavoriteLoading: updateQueryLoading,
    toggleProjectFavoriteErrorMessage: updateQueryErrorMessage,
    toggleProjectFavorite: updateQuery,
    toggleProjectFavoriteReset: updateQueryReset
  };
}

export default useToggleProjectFavorite;
