import reverse from 'lodash/reverse';
import toNumber from 'lodash/toNumber';

import { ProjectFields } from '../../../../../projectsTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { CREATE_PROJECT_QUERY } from '../../../../../queries/createProject.query';

import { useCreateProject } from '../../../../../hooks/useCreateProject';
import { useCreateProjectValidationRules } from '../useCreateProjectValidationRules';

import { dateFnsConvert } from '../../../../../../../utils/dateFnsConvert';

import { ProjectCache } from '../../../../../ProjectCache';

import { CreateProjectFormData } from '../../CreateProjectForm.types';

import { getEndOfDay } from '../../../../../../../utils/getEndOfDay';

import { ProjectsPermissions } from '../../../../../projectsConstants';

const defaultProjectValues: CreateProjectFormData = {
  name: '',
  description: '',
  implementationDate: getEndOfDay(new Date()),
  taskNames: [],
  fileAttachmentIds: []
};

function useCreateProjectForm() {
  const currentUser = useCurrentUser();

  const { control, errors, handleSubmitReactHookForm, register, resetForm } =
    useReactHookForm<CreateProjectFormData>({
      defaultValues: {
        ...defaultProjectValues,
        implementationDate: currentUser.hasPermissions(
          ProjectsPermissions.CREATE_PROJECT_IMPLEMENTATION_TIME
        )
          ? defaultProjectValues.implementationDate
          : dateFnsConvert.toDate(defaultProjectValues.implementationDate)
      },
      isModalForm: true
    });

  const { estimateCostRules, implementationDateRules, nameRules } =
    useCreateProjectValidationRules();

  const {
    createProjectReset,
    createProjectLoading,
    createProjectErrorMessage,
    createProject
  } = useCreateProject({
    query: CREATE_PROJECT_QUERY,
    cacheKeys: [ProjectCache.indexCacheKey()]
  });

  return {
    validationErrors: {
      nameValidationError: errors?.name?.message,
      estimateCostValidationError: errors?.estimateCost?.message,
      implementationDateValidationError: errors?.implementationDate?.message
    },
    control,
    resetCreateProjectForm: resetForm,
    createProjectReset,
    createProjectErrorMessage,
    createProjectLoading,
    handleCreateProject: handleSubmitReactHookForm({
      onSubmit: async (data) =>
        createProject({
          ...data,
          taskNames: reverse(data.taskNames),
          estimateCost: toNumber(data.estimateCost),
          implementationDate: data.implementationDate
            ? dateFnsConvert.toDateTimeWithTimezone(
                currentUser.hasPermissions(
                  ProjectsPermissions.CREATE_PROJECT_IMPLEMENTATION_TIME
                )
                  ? data.implementationDate
                  : getEndOfDay(data.implementationDate)
              )
            : undefined
        }),
      dirtyFieldsOnly: false
    }),
    registerFields: {
      registerName: register(ProjectFields.NAME, nameRules),
      registerEstimateCost: register(
        ProjectFields.ESTIMATE_COST,
        estimateCostRules
      ),
      registerImplementationDate: register(
        ProjectFields.IMPLEMENTATION_DATE,
        implementationDateRules
      ),
      registerDescription: register(ProjectFields.DESCRIPTION)
    }
  };
}

export default useCreateProjectForm;
