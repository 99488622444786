import React, { Fragment, memo, useCallback } from 'react';
import cl from 'classnames';
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';

import { SHOW_DEBUG } from '../../../../../config';

import { ClassName, ID, Locale, NanoID, UUID } from '../../../../../types';

import { IndexTableCell } from '../../tables/IndexTableCell';

import { DateHelper } from '../../../../../helpers/DateHelper';
import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';
import { Translate } from '../../../../../helpers/Translate';
import { MarkdownHelper } from '../../../../../helpers/MarkdownHelper';

import { IconsEnum } from '../../../../../assets/icons/types';

import { words } from '../../../../../locales/keys';

interface IndexTableRowNameDescriptionDateProps {
  href: string;
  itemId: ID;
  itemUuid: UUID;
  itemNanoId?: NanoID;
  date: string;
  description?: string;
  locale?: Locale;
  name: string;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  isTruncate?: boolean;
  onEditMouseOver?: (itemNanoId: UUID | NanoID) => void;
  showDate?: boolean;
}

function IndexTableRowNameDescriptionDate({
  href,
  itemId,
  itemUuid,
  itemNanoId,
  date,
  description,
  locale,
  name,
  icon,
  iconClassName,
  isTruncate,
  onEditMouseOver,
  showDate = false
}: IndexTableRowNameDescriptionDateProps) {
  const handleEditButtonMouseOver = useCallback<() => void>(
    () => onEditMouseOver?.(itemNanoId || itemUuid),
    [itemNanoId, itemUuid, onEditMouseOver]
  );

  return (
    <IndexTableCell
      tdClassName={cl(isTruncate ? 'px-3 py-2 h-16 relative' : 'h-16 relative')}
      divClassName={cl(
        isTruncate
          ? 'absolute inset-0 px-3 py-2 flex items-center rounded-md focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100'
          : 'px-3 py-2 flex items-center rounded-md focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100'
      )}
    >
      <div
        className={cl('w-60 sm:w-80 xl:w-96 2xl:w-112', {
          'min-w-full': !isTruncate
        })}
      >
        <div
          className={cl({
            truncate: isTruncate
          })}
        >
          <NextPureLinkHelper
            className="text-sm sm:text-base font-semibold break-words hover:underline focus:underline focus:outline-none dark:text-gray-200 text-gray-900"
            href={href}
            text={name}
            icon={icon}
            iconClassName={iconClassName}
            onMouseEnter={handleEditButtonMouseOver}
          />
        </div>
        <div className="text-xs truncate text-gray-500 space-x-2.5">
          {differenceInBusinessDays(new Date(), new Date(date)) < 2 ? (
            <span className="bg-pink-500 dark:bg-pink-600 text-white text-xs font-medium py-0.5 px-1.5 rounded-md">
              <Translate id={words.new} />
            </span>
          ) : null}
          {showDate ? (
            <span className="italic">
              <DateHelper date={date} locale={locale} />
            </span>
          ) : null}
          {SHOW_DEBUG ? (
            <Fragment>
              {itemId} {itemUuid}
            </Fragment>
          ) : null}
          {description ? (
            <span className="text-gray-500">
              <MarkdownHelper source={description} asPlainText />
            </span>
          ) : null}
        </div>
      </div>
    </IndexTableCell>
  );
}

export default memo<IndexTableRowNameDescriptionDateProps>(
  IndexTableRowNameDescriptionDate
);
