import filter from 'lodash/filter';
import includes from 'lodash/includes';

import {
  ErrorMessage,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';
import { CreateProjectsBillsFormData } from './CreateProjectsBillsForm.types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import {
  SelectField,
  SelectFieldRequiredProps
} from '../../../../../helpers/FormFields/SelectField';
import { TextAreaField } from '../../../../../helpers/FormFields/TextAreaField';
import { TransactionTagsSelectField } from '../../../../transactionTags/helpers/TransactionTagsSelectField';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import {
  billsKeys,
  formsFields,
  transactionTagsKeys,
  words
} from '../../../../../locales/keys';
import { BillsPermissions } from '../../../../bills/billsConstants';
import { ProjectBillFields } from '../../../projectsTypes';

const currencies = [
  { value: 'USD', i18nLabel: words.usd },
  { value: 'RUB', i18nLabel: words.rub },
  { value: 'UAH', i18nLabel: words.uah }
];

const projectBillTypes = [
  {
    value: 'payoneer',
    i18nLabel: billsKeys.types.payoneer,
    permission: BillsPermissions.READ_PAYONEER_BILL_TYPE
  },
  {
    value: '2checkout',
    i18nLabel: billsKeys.types.twoCheckout,
    permission: BillsPermissions.READ_2CHECKOUT_BILL_TYPE
  },
  {
    value: 'fondy',
    i18nLabel: billsKeys.types.fondy,
    permission: BillsPermissions.READ_FONDY_BILL_TYPE
  },
  {
    value: 'stripe',
    i18nLabel: billsKeys.types.stripe,
    permission: BillsPermissions.READ_STRIPE_BILL_TYPE
  },
  {
    value: 'easypay',
    i18nLabel: billsKeys.types.easypay,
    permission: BillsPermissions.READ_EASYPAY_BILL_TYPE
  },
  {
    value: 'invoice',
    i18nLabel: billsKeys.types.invoice,
    permission: BillsPermissions.READ_INVOICE_BILL_TYPE
  },
  {
    value: 'system',
    i18nLabel: billsKeys.types.system,
    permission: BillsPermissions.READ_SYSTEM_BILL_TYPE
  },
  {
    value: 'debit_account',
    i18nLabel: billsKeys.types.debitAccount,
    permission: BillsPermissions.READ_DEBIT_ACCOUNT_BILL_TYPE
  },
  {
    value: 'cancel_debit_account',
    i18nLabel: billsKeys.types.cancelDebitAccount,
    permission: BillsPermissions.READ_CANCEL_DEBIT_ACCOUNT_BILL_TYPE
  },
  {
    value: 'bonus',
    i18nLabel: billsKeys.types.bonus,
    permission: BillsPermissions.READ_BONUS_BILL_TYPE
  },
  {
    value: 'cancel_bonus',
    i18nLabel: billsKeys.types.cancelBonus,
    permission: BillsPermissions.READ_CANCEL_BONUS_BILL_TYPE
  }
];

const billTypesWithAllocableUser = ['2checkout', 'fondy', 'stripe', 'easypay'];

const billTypesWithButtons = ['2checkout', 'fondy', 'stripe', 'easypay'];

const allocableUsers = [
  { value: 'client', i18nLabel: words.client },
  { value: 'system', i18nLabel: words.system }
];

const buttons = [
  { value: 'card', i18nLabel: billsKeys.buttonTypes.card },
  { value: 'account', i18nLabel: billsKeys.buttonTypes.account },
  { value: 'card_account', i18nLabel: billsKeys.buttonTypes.cardAccount }
];

interface CreateProjectsBillsFormProps {
  form: string;
  isLoading: IsLoading;
  registerAmount: RegisterFormFieldType<HTMLInputElement>;
  registerBody: RegisterFormFieldType<HTMLTextAreaElement>;
  amountValidationError: ErrorMessage;
  bodyValidationError: ErrorMessage;
  watchAccount: string;
}

function CreateProjectsBillsForm({
  form,
  control,
  isLoading,
  registerAmount,
  registerBody,
  watchAccount,
  amountValidationError,
  bodyValidationError
}: CreateProjectsBillsFormProps &
  SelectFieldRequiredProps<CreateProjectsBillsFormData>) {
  const currentUser = useCurrentUser();

  const actualProjectBillsTypes = filter(projectBillTypes, (projectBillType) =>
    currentUser.hasPermissions(projectBillType.permission)
  );

  return (
    <Form id={form}>
      <div className="p-4">
        <div className="flex flex-col gap-4">
          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <InputField
                disabled={isLoading}
                error={amountValidationError}
                i18nLabel={formsFields.amount}
                inputWrapperClassName="relative mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                name={registerAmount.name}
                onChange={registerAmount.onChange}
                ref={registerAmount.ref}
                type="text"
              />
            </div>
            <div className="w-full sm:w-auto sm:flex-1">
              <SelectField
                control={control}
                disabled={isLoading}
                i18nLabel={formsFields.currency}
                name={ProjectBillFields.CURRENCY}
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                options={currencies}
                i18nPlaceholder={billsKeys.selectCurrency}
              />
            </div>
          </div>
          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <SelectField
                control={control}
                disabled={isLoading}
                i18nLabel={formsFields.account}
                name={ProjectBillFields.ACCOUNT}
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                options={actualProjectBillsTypes}
                i18nPlaceholder={billsKeys.selectAccount}
              />
            </div>
            <CheckPermissions
              action={BillsPermissions.READ_INVOICE_FORM_ALLOCABLE_USER_FIELD}
            >
              {includes(billTypesWithAllocableUser, watchAccount) ? (
                <div className="w-full sm:w-auto sm:flex-1">
                  <SelectField
                    control={control}
                    disabled={isLoading}
                    i18nLabel={billsKeys.selectSource}
                    name={ProjectBillFields.ALLOCABLE_USER}
                    labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                    options={allocableUsers}
                    i18nPlaceholder={billsKeys.selectSource}
                  />
                </div>
              ) : null}
            </CheckPermissions>
            {includes(billTypesWithButtons, watchAccount) ? (
              <div className="w-full sm:w-auto sm:flex-1">
                <SelectField
                  control={control}
                  disabled={isLoading}
                  i18nLabel={billsKeys.selectButtons}
                  name={ProjectBillFields.BUTTONS}
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  options={buttons}
                  i18nPlaceholder={billsKeys.selectButtons}
                />
              </div>
            ) : null}
          </div>
          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <TextAreaField
                name={registerBody.name}
                ref={registerBody.ref}
                onChange={registerBody.onChange}
                error={bodyValidationError}
                i18nLabel={formsFields.description}
                i18nPlaceholder={words.descriptionOptional}
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                disabled={isLoading}
                rows={2}
              />
            </div>
          </div>
          <CheckPermissions
            action={BillsPermissions.READ_INVOICE_FORM_TRANSACTION_TAGS_FIELD}
          >
            <div className="w-full space-y-4">
              <TransactionTagsSelectField
                control={control}
                disabled={isLoading}
                name={ProjectBillFields.TRANSACTION_TAG_ID}
                i18nLabel={transactionTagsKeys.select}
                i18nPlaceholder={transactionTagsKeys.select}
              />
            </div>
          </CheckPermissions>
        </div>
      </div>
    </Form>
  );
}

export default CreateProjectsBillsForm;
