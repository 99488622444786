import { gql } from 'graphql-request';

export const INVITE_USERS_TO_PROJECTS_QUERY = gql`
  mutation InviteUsersToProjects($projectIds: [ID!]!, $userIds: [ID!]!) {
    inviteUsersToProjects(
      input: { projectIds: $projectIds, userIds: $userIds }
    ) {
      errors {
        fullMessages
      }
      status
    }
  }
`;
