import {
  ErrorMessage,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';

import { ProjectFields } from '../../../projectsTypes';
import { CreateProjectFormData } from './CreateProjectForm.types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { TextAreaField } from '../../../../../helpers/FormFields/TextAreaField';
import {
  ItemsListField,
  ItemsListFieldRequiredProps
} from '../../../../../helpers/FormFields/ItemsListField';
import {
  DropzoneField,
  DropzoneFieldRequiredProps
} from '../../../../../helpers/FormFields/DropzoneField';
import { Translate } from '../../../../../helpers/Translate';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import {
  attachmentsKeys,
  formsFields,
  projectsKeys,
  tasksKeys,
  words
} from '../../../../../locales/keys';
import { ProjectsPermissions } from '../../../projectsConstants';

interface CreateProjectFormProps {
  estimateCostValidationError?: ErrorMessage;
  form: string;
  implementationDateValidationError?: ErrorMessage;
  isLoading?: IsLoading;
  nameValidationError?: ErrorMessage;
  registerDescription: RegisterFormFieldType<HTMLTextAreaElement>;
  registerEstimateCost: RegisterFormFieldType<HTMLInputElement>;
  registerImplementationDate: RegisterFormFieldType<HTMLInputElement>;
  registerName: RegisterFormFieldType<HTMLInputElement>;
}

function CreateProjectForm({
  form,
  control,
  isLoading,
  nameValidationError,
  estimateCostValidationError,
  implementationDateValidationError,
  registerDescription,
  registerEstimateCost,
  registerImplementationDate,
  registerName
}: CreateProjectFormProps &
  ItemsListFieldRequiredProps<CreateProjectFormData> &
  DropzoneFieldRequiredProps<CreateProjectFormData>) {
  const currentUser = useCurrentUser();

  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <div className="flex flex-col gap-4">
          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <InputField
                disabled={isLoading}
                error={nameValidationError}
                i18nLabel={projectsKeys.name}
                inputWrapperClassName="relative mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                name={registerName.name}
                onChange={registerName.onChange}
                ref={registerName.ref}
                type="text"
              />
            </div>
          </div>

          <CheckPermissions
            action={ProjectsPermissions.CREATE_PROJECT_DESCRIPTION}
          >
            <div className="flex gap-3 flex-wrap">
              <div className="w-full sm:w-auto sm:flex-1">
                <TextAreaField
                  disabled={isLoading}
                  i18nLabel={formsFields.description}
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  textAreaWrapperClassName="mt-1"
                  name={registerDescription.name}
                  onChange={registerDescription.onChange}
                  ref={registerDescription.ref}
                  rows={5}
                />
              </div>
            </div>
          </CheckPermissions>

          <CheckPermissions
            action={ProjectsPermissions.CREATE_PROJECT_ESTIMATE_COST}
          >
            <div className="flex gap-3 flex-wrap">
              <div className="w-full sm:w-auto sm:flex-1">
                <InputField
                  disabled={isLoading}
                  error={estimateCostValidationError}
                  i18nLabel={words.draftBudget}
                  inputClassName="basic-input number-appearance-none"
                  inputWrapperClassName="relative mt-1"
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  name={registerEstimateCost.name}
                  onChange={registerEstimateCost.onChange}
                  ref={registerEstimateCost.ref}
                  type="number"
                />
                <p className="text-gray-500 italic text-xs mt-1 mx-1">
                  <Translate
                    id={
                      projectsKeys.optionalPleaseProvideARawEstimationOnProjectBudget
                    }
                  />
                </p>
              </div>
            </div>
          </CheckPermissions>
          <CheckPermissions
            action={ProjectsPermissions.CREATE_PROJECT_IMPLEMENTATION_DATE}
          >
            <div className="flex gap-3 flex-wrap">
              <div className="w-full sm:w-auto sm:flex-1">
                <InputField
                  disabled={isLoading}
                  error={implementationDateValidationError}
                  i18nLabel={formsFields.implementationDate}
                  inputWrapperClassName="relative mt-1"
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  name={registerImplementationDate.name}
                  onChange={registerImplementationDate.onChange}
                  ref={registerImplementationDate.ref}
                  showErrorIcon={false}
                  type={
                    currentUser.hasPermissions(
                      ProjectsPermissions.CREATE_PROJECT_IMPLEMENTATION_TIME
                    )
                      ? 'datetime-local'
                      : 'date'
                  }
                />
                <p className="text-gray-500 italic text-xs mt-1 mx-1">
                  <Translate
                    id={
                      projectsKeys.optionalWhenYouExpectTheProjectToBeFinished
                    }
                  />
                </p>
              </div>
            </div>
          </CheckPermissions>
        </div>
      </div>

      <CheckPermissions action={ProjectsPermissions.CREATE_PROJECT_TASK_NAMES}>
        <div className="p-4">
          <ItemsListField
            control={control}
            name={ProjectFields.TASK_NAMES}
            labelFor="create-project-add-tasks"
            i18nLabel={projectsKeys.tasks}
            i18nPlaceholder={tasksKeys.name}
            addButtonI18nText={tasksKeys.add}
            disabled={isLoading}
          />
        </div>
      </CheckPermissions>

      <CheckPermissions
        action={ProjectsPermissions.CREATE_PROJECT_FILE_ATTACHMENT_IDS}
      >
        <div className="p-4">
          <DropzoneField
            control={control}
            name={ProjectFields.FILE_ATTACHMENT_IDS}
            type="fileAttachments"
            labelFor="create-project-add-files"
            i18nLabel={attachmentsKeys.plural}
            disabled={isLoading}
          />
        </div>
      </CheckPermissions>
    </Form>
  );
}

export default CreateProjectForm;
