import {
  FetchProjectCacheKey,
  ProjectDescription,
  ProjectGqlError,
  ProjectNanoID,
  ProjectUUID,
  UpdateProjectGqlQuery,
  UpdateProjectGqlStatus
} from '../../projectsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateProjectDescriptionOptions {
  query: UpdateProjectGqlQuery;
  cacheKeys?: FetchProjectCacheKey[];
}

export interface UpdateProjectDescriptionResponse<
  UpdateProjectDescriptionRecordType
> {
  updateProjectDescription: {
    status: UpdateProjectGqlStatus;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: UpdateProjectDescriptionRecordType;
    errors: UpdateProjectDescriptionError;
  };
}

export interface UpdateProjectDescriptionInput {
  uuid: ProjectUUID;
  description: ProjectDescription;
}

export interface UpdateProjectDescriptionError {
  description: ProjectGqlError;
  fullMessages: ProjectGqlError;
}

const action = 'updateProjectDescription';

function useUpdateProjectDescription<UpdateProjectDescriptionRecordType>({
  query,
  cacheKeys
}: UpdateProjectDescriptionOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateProjectDescriptionInput,
    UpdateProjectDescriptionResponse<UpdateProjectDescriptionRecordType>,
    UpdateProjectDescriptionError,
    UpdateProjectDescriptionRecordType
  >({ action, cacheKeys, query });

  return {
    updateProjectDescriptionData: updateQueryData,
    updateProjectDescriptionError: updateQueryError,
    updateProjectDescriptionLoading: updateQueryLoading,
    updateProjectDescriptionErrorMessage: updateQueryErrorMessage,
    updateProjectDescription: updateQuery,
    updateProjectDescriptionReset: updateQueryReset
  };
}

export default useUpdateProjectDescription;
