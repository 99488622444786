import { gql } from 'graphql-request';
import {
  TransactionTagID,
  TransactionTagLocalizedName
} from '../transactionTagsTypes';

export interface FetchTransactionTagNamesQueryResponse {
  id: TransactionTagID;
  localizedName: TransactionTagLocalizedName;
}

export const FETCH_TRANSACTION_TAG_NAMES_QUERY = gql`
  query TransactionTags(
    $filters: TransactionTagsFilters
    $sort: [TransactionTagsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    transactionTags(
      filters: $filters
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      paginationInfo {
        nextPage
        totalCount
      }
      nodes {
        id
        localizedName
      }
    }
  }
`;
