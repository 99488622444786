import { gql } from 'graphql-request';

import { ProjectUUID, ProjectUnpaidBillsSum } from '../projectsTypes';
import {
  BillAccount,
  BillAmount,
  BillBody,
  BillCreatedAt,
  BillCurrency,
  BillID,
  BillPayable,
  BillPayerFullName,
  BillPayerNanoID,
  BillPayerPayoneerCheckoutAddress,
  BillPayerUUID,
  BillPaymentData,
  BillPaymentForm,
  BillPaymentUrl,
  BillStatus,
  BillUUID
} from '../../bills/billsTypes';

export interface FetchProjectBillsQueryResponse {
  uuid: ProjectUUID;
  unpaidBillsSum: ProjectUnpaidBillsSum;
  unpaidBills: {
    id: BillID;
    uuid: BillUUID;
    account: BillAccount;
    amount: BillAmount;
    currency: BillCurrency;
    body: BillBody;
    status: BillStatus;
    createdAt: BillCreatedAt;
    payable: BillPayable;
    paymentForm: BillPaymentForm;
    paymentUrl: BillPaymentUrl;
    paymentData: BillPaymentData;
    payer: {
      uuid: BillPayerUUID;
      nanoId: BillPayerNanoID;
      fullName: BillPayerFullName;
      payoneerCheckoutAddress: BillPayerPayoneerCheckoutAddress;
    };
  }[];
}

export const FETCH_PROJECT_BILLS_QUERY = gql`
  query ProjectBills($uuid: ID!) {
    project(uuid: $uuid) {
      uuid
      unpaidBillsSum
      unpaidBills {
        id
        uuid
        amount
        currency
        account
        currency
        body
        status
        createdAt
        payable
        payer {
          uuid
          nanoId
          fullName
          payoneerCheckoutAddress
        }
      }
    }
  }
`;
