import { useMemo } from 'react';

import {
  FetchProjectGqlQuery,
  ProjectNanoID,
  ProjectUUID
} from '../../projectsTypes';

import { FetchProjectCacheKey } from '../../projectsTypes';

import { useShowQuery } from '../../../common/hooks/base/reactQuery/useShowQuery';

interface ProjectResponse<ItemType> {
  project: ItemType | null;
}

interface ProjectOptions<ItemType> {
  projects: ItemType[];
  cacheKey: FetchProjectCacheKey;
  query: FetchProjectGqlQuery;
  uuid: ProjectUUID | ProjectNanoID;
  options?: {
    cacheTime?: number;
    enabledPlaceholder?: boolean;
  };
}

interface UseProjectItem {
  uuid: ProjectUUID;
}

const itemKey = 'project';

function useProject<ItemType extends UseProjectItem>({
  projects,
  cacheKey,
  query,
  uuid,
  options = {}
}: ProjectOptions<ItemType>) {
  const placeholderData = useMemo<ProjectResponse<ItemType>>(() => {
    const project = projects.find((project) => project.uuid === uuid);

    return project ? { project } : null;
  }, [projects, uuid]);

  const {
    item,
    itemErrorData,
    itemError,
    itemFetched,
    itemLoading,
    itemIsPlaceholderData
  } = useShowQuery<ProjectResponse<ItemType>, ItemType>({
    cacheKey,
    itemKey,
    query,
    uuid,
    placeholderData,
    options
  });

  return {
    project: item,
    projectErrorData: itemErrorData,
    projectError: itemError,
    projectFetched: itemFetched,
    projectLoading: itemLoading,
    projectIsPlaceholderData: itemIsPlaceholderData
  };
}

export default useProject;
