import React, { memo } from 'react';

import {
  FetchProjectsCheckedAll,
  FetchProjectsOnCheckAll,
  FetchProjectsSort,
  FetchProjectsSortProjects,
  FetchProjectsSortableFields
} from '../../../projectsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { IndexTableHeader } from '../../../../common/components/tables/IndexTableHeader';
import { IndexTableHeaderCell } from '../../../../common/components/tables/IndexTableHeaderCell';
import { IndexTableHeaderEmptyCell } from '../../../../common/components/tables/IndexTableHeaderEmptyCell';
import { IndexTableHeaderSortableCell } from '../../../../common/components/tables/IndexTableHeaderSortableCell';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { formsFields, tagsKeys, words } from '../../../../../locales/keys';

import { ProjectsPermissions } from '../../../projectsConstants';

interface ProjectsIndexTableHeaderProps {
  editable: boolean;
  projectsSort: FetchProjectsSort;
  checkedAll: FetchProjectsCheckedAll;
  sortProjects: FetchProjectsSortProjects;
  onCheckAll: FetchProjectsOnCheckAll;
}

function ProjectsIndexTableHeader({
  editable,
  projectsSort,
  checkedAll,
  sortProjects,
  onCheckAll
}: ProjectsIndexTableHeaderProps) {
  const currentUser = useCurrentUser();

  return (
    <IndexTableHeader
      editable={editable}
      checkedAll={checkedAll}
      withoutCheckboxes={
        !currentUser.hasPermissions(
          ProjectsPermissions.READ_PROJECTS_TABLE_CHECKBOXES
        )
      }
      onCheckAll={onCheckAll}
      sortableField={FetchProjectsSortableFields.ID}
      currentSort={projectsSort}
      sortItems={sortProjects}
    >
      <IndexTableHeaderEmptyCell />
      <IndexTableHeaderSortableCell
        i18nText={formsFields.name}
        sortableField={FetchProjectsSortableFields.NAME}
        currentSort={projectsSort}
        onSort={sortProjects}
      />
      <IndexTableHeaderEmptyCell />
      <IndexTableHeaderCell i18nText={formsFields.team} />
      <IndexTableHeaderSortableCell
        i18nText={formsFields.implementationDate}
        sortableField={FetchProjectsSortableFields.IMPLEMENTATION_DATE}
        currentSort={projectsSort}
        onSort={sortProjects}
      />
      <IndexTableHeaderSortableCell
        i18nText={formsFields.status}
        sortableField={FetchProjectsSortableFields.STATUS}
        currentSort={projectsSort}
        onSort={sortProjects}
      />
      <CheckPermissions
        action={ProjectsPermissions.READ_PROJECT_TASKS_AMOUNTS_SUM}
      >
        <IndexTableHeaderCell i18nText={words.expenses} />
      </CheckPermissions>
      <CheckPermissions
        action={ProjectsPermissions.READ_PROJECT_TASKS_CLIENT_PREPAYMENTS_SUM}
      >
        <IndexTableHeaderCell i18nText={formsFields.clientPrepayment} />
      </CheckPermissions>
      <CheckPermissions action={ProjectsPermissions.READ_PROJECT_ESTIMATE_COST}>
        <IndexTableHeaderSortableCell
          i18nText={words.budget}
          sortableField={FetchProjectsSortableFields.ESTIMATE_COST}
          currentSort={projectsSort}
          onSort={sortProjects}
          textAlignEnd
        />
      </CheckPermissions>
      <CheckPermissions action={ProjectsPermissions.READ_PROJECT_TAGS}>
        <IndexTableHeaderCell i18nText={tagsKeys.plural} />
      </CheckPermissions>
      <IndexTableHeaderSortableCell
        i18nText={formsFields.created}
        sortableField={FetchProjectsSortableFields.CREATED_AT}
        currentSort={projectsSort}
        onSort={sortProjects}
      />
    </IndexTableHeader>
  );
}

export default memo<ProjectsIndexTableHeaderProps>(ProjectsIndexTableHeader);
