import { Control, Controller, FieldPath } from 'react-hook-form';

import { YesNoAnyRadioButtonsControl } from './components/YesNoAnyRadioButtonsControl';

interface YesNoAnyRadioButtonsFieldProps<T> {
  control: Control<T>;
  name: FieldPath<T>;
}

function YesNoAnyRadioButtonsField<T>({
  control,
  name
}: YesNoAnyRadioButtonsFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <YesNoAnyRadioButtonsControl
          name={name}
          onChange={onChange}
          value={value as boolean}
        />
      )}
    />
  );
}

export default YesNoAnyRadioButtonsField;
