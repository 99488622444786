import React, { ReactNode, useState } from 'react';
import size from 'lodash/size';
import map from 'lodash/map';

import { I18nText } from '../../../../../types';
import { UsersSimpleModalButtonUserItem } from './UsersSimpleModalButton.types';

import { UsersSimpleModalButtonUser } from './components/UsersSimpleModalButtonUser';

import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';
import { Tooltip } from '../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { Translate } from '../../../../../helpers/Translate';

import { words } from '../../../../../locales/keys';

interface UsersSimpleModalButtonProps {
  users: UsersSimpleModalButtonUserItem[];
  i18nModalTitle: I18nText;
  buttonChildren: ReactNode;
}

const visibleUsersLimit = 3;

function UsersSimpleModalButton({
  users,
  i18nModalTitle,
  buttonChildren
}: UsersSimpleModalButtonProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const tooltipUserNamesAsString = users
    .slice(0, visibleUsersLimit)
    .map(({ fullName }) => fullName)
    .join(', ');
  const isMoreUsersTextVisible = size(users) > visibleUsersLimit;
  const moreUsersCount = size(users) - visibleUsersLimit;

  const tooltipContent = (
    <div className="text-center space-y-1.5">
      <div>
        {tooltipUserNamesAsString}{' '}
        {isMoreUsersTextVisible ? (
          <span className="inline-block">
            <Translate id={words.and} /> +{moreUsersCount}
          </span>
        ) : null}
      </div>
      {isMoreUsersTextVisible ? (
        <div className="font-medium">
          <Translate id={words.viewAllMembers} />
        </div>
      ) : null}
    </div>
  );

  return (
    <div ref={setReferenceTooltipElement}>
      <SimpleModalButton
        className="group dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm"
        i18nTitle={i18nModalTitle}
        withoutButtons
        modalSize="sm"
        childrenClassName="flex-1 overflow-y-auto px-2"
        buttonChildren={buttonChildren}
      >
        <div className="p-4">
          <ul>
            {map(users, (user: UsersSimpleModalButtonUserItem) => (
              <UsersSimpleModalButtonUser user={user} key={user.uuid} />
            ))}
          </ul>
        </div>
      </SimpleModalButton>

      <Tooltip
        referenceElement={referenceTooltipElement}
        placement={TooltipPlacement.BOTTOM}
        withArrow
      >
        {tooltipContent}
      </Tooltip>
    </div>
  );
}

export default UsersSimpleModalButton;
