import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';

import { useTranslate } from '../../../../../common/hooks/useTranslate';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { projectsStatuses } from '../../../projectsConstants';
import { projectsKeys } from '../../../../../locales/keys';

function ProjectStatusesFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const { t } = useTranslate();

  const projectStatusesItems = projectsStatuses.map(({ value, i18nLabel }) => ({
    id: value,
    label: t(i18nLabel)
  }));

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={projectsKeys.status}
      items={projectStatusesItems}
      name={name}
      onRemoveFilter={onRemoveFilter}
    />
  );
}

export default ProjectStatusesFilterBadge;
