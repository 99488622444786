import { ProjectNanoID } from '../../../../../../../projectsTypes';

import { CreateTaskModalButton } from '../../../../../../../../tasks/components/modalButtons/CreateTaskModalButton';

import { tasksKeys } from '../../../../../../../../../locales/keys';

interface ProjectsDropdownAddTaskButtonProps {
  projectNanoId: ProjectNanoID;
}

function ProjectsDropdownAddTaskButton({
  projectNanoId
}: ProjectsDropdownAddTaskButtonProps) {
  return (
    <CreateTaskModalButton
      className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      i18nText={tasksKeys.add}
      projectNanoId={projectNanoId}
    />
  );
}

export default ProjectsDropdownAddTaskButton;
