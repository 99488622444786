import React from 'react';

import { NextLinkHelper } from '../../../../../../../helpers/links/NextLinkHelper';
import { UserAvatarLink } from '../../../../../../common/helpers/UserAvatarLink';

import { UserPath } from '../../../../../UserPath';

import { UsersSimpleModalButtonUserItem } from '../../UsersSimpleModalButton.types';

interface UsersSimpleModalButtonUserProps {
  user: UsersSimpleModalButtonUserItem;
}

function UsersSimpleModalButtonUser({ user }: UsersSimpleModalButtonUserProps) {
  return (
    <li className="group p-2 -mx-2 rounded-lg bg-gray-500 bg-opacity-0 hover:bg-opacity-10">
      <div className="flex items-center space-x-2">
        <div className="flex-1 flex relative items-center space-x-3 truncate rounded-md focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100">
          <div className="flex-shrink-0">
            <UserAvatarLink avatarType="main" user={user} />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900 dark:text-gray-200 truncate">
              <NextLinkHelper
                href={UserPath.userProfile(user.nanoId)}
                className="focus:outline-none"
              >
                {' '}
                {user.fullName}{' '}
                <span className="absolute inset-0" aria-hidden />
              </NextLinkHelper>
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400 truncate space-x-1">
              {user.financeRoleName}
            </p>
          </div>
        </div>
      </div>
    </li>
  );
}

export default UsersSimpleModalButtonUser;
