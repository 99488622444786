import isFunction from 'lodash/isFunction';

import {
  FetchProjectsEditProject,
  FetchProjectsErrorMessage,
  FetchProjectsFetched,
  FetchProjectsIsPlaceholderData,
  FetchProjectsLimit,
  FetchProjectsPage,
  FetchProjectsPaginateProjects,
  FetchProjectsPrefetchProjects,
  FetchProjectsSort,
  FetchProjectsSortProjects,
  FetchProjectsTotalCount,
  ProjectUUID,
  UpdatePaginatedProjectsItemCacheAction
} from '../../../projectsTypes';

import { CheckableProps } from '../../../../../common/hooks/useTableCheckable';

import {
  ProjectsIndexTableBody,
  ProjectsIndexTableBodyProjects,
  ProjectsIndexTableBodyProject
} from '../ProjectsIndexTableBody';
import { ProjectsIndexTableHeader } from '../ProjectsIndexTableHeader';

import { ItemsIndexTableWrapper } from '../../../../common/components/tables/ItemsIndexTableWrapper';

interface ProjectsIndexTableProps {
  projects: ProjectsIndexTableBodyProjects;
  projectsFetched: FetchProjectsFetched;
  projectsError: FetchProjectsErrorMessage;
  projectsIsPlaceholderData: FetchProjectsIsPlaceholderData;
  projectsSort: FetchProjectsSort;
  projectsPage: FetchProjectsPage;
  projectsLimit: FetchProjectsLimit;
  projectsTotalCount: FetchProjectsTotalCount;
  updateProjectCache?: UpdatePaginatedProjectsItemCacheAction<ProjectsIndexTableBodyProject>;
  sortProjects: FetchProjectsSortProjects;
  paginateProjects: FetchProjectsPaginateProjects;
  prefetchProjects: FetchProjectsPrefetchProjects;
  onProjectEdit?: FetchProjectsEditProject;
  onProjectEditMouseOver?: FetchProjectsEditProject;
  selectedProjectUuid?: ProjectUUID | null;
}

function ProjectsIndexTable({
  projects,
  projectsFetched,
  projectsError,
  projectsIsPlaceholderData,
  projectsSort,
  projectsPage,
  projectsLimit,
  projectsTotalCount,
  checkedHash,
  checkedAll,
  onSetCheckedIds,
  onCheckAll,
  updateProjectCache,
  sortProjects,
  paginateProjects,
  prefetchProjects,
  onProjectEdit,
  onProjectEditMouseOver,
  selectedProjectUuid
}: ProjectsIndexTableProps & CheckableProps) {
  return (
    <ItemsIndexTableWrapper
      scope="projects"
      items={projects}
      itemsFetched={projectsFetched}
      itemsError={projectsError}
      itemsIsPlaceholderData={projectsIsPlaceholderData}
      itemsPage={projectsPage}
      itemsLimit={projectsLimit}
      itemsTotalCount={projectsTotalCount}
      paginateItems={paginateProjects}
      prefetchItems={prefetchProjects}
    >
      <ProjectsIndexTableHeader
        editable={isFunction(onProjectEdit)}
        projectsSort={projectsSort}
        checkedAll={checkedAll}
        sortProjects={sortProjects}
        onCheckAll={onCheckAll}
      />
      <ProjectsIndexTableBody
        projects={projects}
        checkedHash={checkedHash}
        onSetCheckedIds={onSetCheckedIds}
        updateProjectCache={updateProjectCache}
        onEdit={onProjectEdit}
        onEditMouseOver={onProjectEditMouseOver}
        selectedProjectUuid={selectedProjectUuid}
      />
    </ItemsIndexTableWrapper>
  );
}

export default ProjectsIndexTable;
