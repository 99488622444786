import { useCallback } from 'react';

import {
  ProjectDescription,
  ProjectFields,
  ProjectUUID
} from '../../../../../projectsTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useUpdateProjectDescription } from '../../../../../hooks/useUpdateProjectDescription';

import {
  UPDATE_PROJECT_DESCRIPTION_QUERY,
  UpdateProjectDescriptionQueryResponse
} from '../../../../../queries/updateProjectDescription.query';

import { ProjectCache } from '../../../../../ProjectCache';

interface UpdateProjectDescriptionFormData {
  description?: string;
}

interface UpdateProjectDescriptionFormOptions {
  description: ProjectDescription;
  uuid: ProjectUUID;
}

function useUpdateProjectDescriptionForm({
  description,
  uuid
}: UpdateProjectDescriptionFormOptions) {
  const { handleSubmitReactHookForm, register, setValue } =
    useReactHookForm<UpdateProjectDescriptionFormData>({
      defaultValues: {
        description
      },
      isModalForm: true
    });

  const handleInitUpdateProjectDescriptionForm = useCallback(
    () => setValue(ProjectFields.DESCRIPTION as 'description', description),
    [description, setValue]
  );

  const {
    updateProjectDescription,
    updateProjectDescriptionLoading,
    updateProjectDescriptionErrorMessage,
    updateProjectDescriptionReset
  } = useUpdateProjectDescription<UpdateProjectDescriptionQueryResponse>({
    query: UPDATE_PROJECT_DESCRIPTION_QUERY,
    cacheKeys: [ProjectCache.showCacheKey(), ProjectCache.indexCacheKey()]
  });

  return {
    updateProjectDescription,
    updateProjectDescriptionLoading,
    updateProjectDescriptionErrorMessage,
    updateProjectDescriptionReset,
    handleInitUpdateProjectDescriptionForm,
    handleUpdateProjectDescription: handleSubmitReactHookForm({
      onSubmit: async (data: UpdateProjectDescriptionFormData) =>
        updateProjectDescription({
          uuid,
          description: data.description
        })
    }),
    registerDescription: register(ProjectFields.DESCRIPTION)
  };
}

export default useUpdateProjectDescriptionForm;
