import * as yup from 'yup';
import toNumber from 'lodash/toNumber';

import { formsErrors } from '../../../../../../../locales/keys';

import { ProjectBillFields } from '../../../../../projectsTypes';

export const createProjectsBillsFormSchema = yup.object({
  [ProjectBillFields.AMOUNT]: yup
    .number()
    .transform((_, value) => (value === '' ? undefined : toNumber(value)))
    .required(formsErrors.required)
    .when([ProjectBillFields.ACCOUNT], {
      is: (value: string) => value === 'payoneer',
      then: yup
        .number()
        .transform((_, value) => (value === '' ? undefined : toNumber(value)))
        .min(20, formsErrors.amount.minAmountPayoneerTwenty)
        .required(formsErrors.required)
    }),
  [ProjectBillFields.CURRENCY]: yup.string().required(formsErrors.required),
  [ProjectBillFields.ACCOUNT]: yup.string().required(formsErrors.required),
  [ProjectBillFields.ALLOCABLE_USER]: yup
    .string()
    .required(formsErrors.required)
});
