import {
  FetchTransactionTagsFilters,
  FetchTransactionTagsLimit,
  FetchTransactionTagsPage,
  FetchTransactionTagsSort,
  TransactionTagType
} from '../../transactionTagsTypes';
import {
  INITIAL_TRANSACTION_TAGS_FILTERS,
  INITIAL_TRANSACTION_TAGS_LIMIT,
  INITIAL_TRANSACTION_TAGS_PAGE,
  INITIAL_TRANSACTION_TAGS_SORT
} from '../../TransactionTagsConstants';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

interface TransactionTagsOptions {
  cacheKey: string;
  query: string;
  initialFilters?: FetchTransactionTagsFilters;
  initialSort?: FetchTransactionTagsSort;
  initialPage?: FetchTransactionTagsPage;
  initialLimit?: FetchTransactionTagsLimit;
  options?: {
    cacheTime?: number;
  };
}

const scope = 'transactionTags';

function usePaginatedTransactionTags<ItemType = TransactionTagType>({
  cacheKey,
  query,
  initialFilters = INITIAL_TRANSACTION_TAGS_FILTERS,
  initialSort = INITIAL_TRANSACTION_TAGS_SORT,
  initialPage = INITIAL_TRANSACTION_TAGS_PAGE,
  initialLimit = INITIAL_TRANSACTION_TAGS_LIMIT,
  options = {}
}: TransactionTagsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useIndexQuery<ItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options,
    query,
    scope
  });

  return {
    transactionTagsData: data,
    transactionTags: items,
    transactionTagsError: itemsError,
    transactionTagsTotalCount: itemsTotalCount,
    transactionTagsFetched: isFetched,
    transactionTagsLoading: isLoading,
    transactionTagsIsPlaceholderData: isPlaceholderData,
    transactionTagsFilters: currentFilters,
    transactionTagsSort: currentSort,
    transactionTagsPage: currentPage,
    transactionTagsLimit: currentLimit,
    filterTransactionTags: filterItems,
    clearTransactionTagsFilters: clearItemsFilters,
    sortTransactionTags: sortItems,
    paginateTransactionTags: paginateItems,
    limitTransactionTags: limitItems,
    prefetchTransactionTags: prefetchItems
  };
}

export default usePaginatedTransactionTags;
