import { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import forEach from 'lodash/forEach';
import map from 'lodash/map';

import { ProjectEstimateCost } from '../../../../../projectsTypes';
import { UpdateProjectsAmountsFormProjects } from '../../UpdateProjectsAmountsForm.types';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useUpdateProjectsAmounts } from '../../../../../hooks/useUpdateProjectsAmounts';

import { UPDATE_PROJECTS_AMOUNTS_QUERY } from '../../../../../queries/updateProjectsAmounts.query';

import { updateProjectsAmountsValidationSchema } from './useUpdateProjectsAmountsForm.schema';
import { UpdateProjectsAmountsCacheKeys } from '../../../../../projectsTypes/useUpdateProjectsAmounts.types';

const commonAmountField = 'commonAmount';

export interface UpdateProjectsAmountsFormData {
  commonAmount: ProjectEstimateCost;
  projects: UpdateProjectsAmountsFormProjects;
}

export interface UpdateProjectsAmountsFormOptions {
  cacheKeys: UpdateProjectsAmountsCacheKeys;
  projects: UpdateProjectsAmountsFormProjects;
}

function useUpdateProjectsAmountsForm({
  cacheKeys,
  projects
}: UpdateProjectsAmountsFormOptions) {
  const {
    control,
    errors,
    getValues,
    handleSubmitReactHookForm,
    isDirty,
    register,
    resetForm,
    setValue
  } = useReactHookForm<UpdateProjectsAmountsFormData>({
    defaultValues: {
      projects
    },
    isModalForm: true,
    schema: updateProjectsAmountsValidationSchema
  });

  const { fields } = useFieldArray<UpdateProjectsAmountsFormData, 'projects'>({
    name: 'projects',
    control
  });

  const handleInitUpdateProjectsAmountsForm = useCallback<() => void>(() => {
    resetForm({
      projects
    });
  }, [projects, resetForm]);

  const {
    updateProjectsAmounts,
    updateProjectsAmountsLoading,
    updateProjectsAmountsErrorMessage,
    updateProjectsAmountsReset
  } = useUpdateProjectsAmounts({
    query: UPDATE_PROJECTS_AMOUNTS_QUERY,
    cacheKeys
  });

  return {
    updateProjectsAmounts,
    updateProjectsAmountsLoading,
    updateProjectsAmountsErrorMessage,
    updateProjectsAmountsReset,
    handleInitUpdateProjectsAmountsForm,
    amountsErrorsMessages: map(
      errors?.projects,
      (project) => project?.estimateCost?.message
    ),
    handleUpdateProjectsAmounts: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data: UpdateProjectsAmountsFormData) =>
        updateProjectsAmounts({
          amounts: map(data.projects, (project) => ({
            amount: project.estimateCost,
            projectId: project.id
          }))
        })
    }),
    fields,
    isDirty,
    register,
    registerCommonAmount: register(commonAmountField),
    resetForm,
    setAllAmounts: useCallback<() => void>(() => {
      const commonAmount = getValues(commonAmountField);

      forEach(fields, (field, index) =>
        setValue(`projects.${index}.estimateCost`, commonAmount)
      );
    }, [getValues, setValue, fields])
  };
}

export default useUpdateProjectsAmountsForm;
