import map from 'lodash/map';

import isEmpty from 'lodash/isEmpty';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { ItemsListAccordionHelperItems } from '../../../../../helpers/ItemsListAccordionHelper/ItemsListAccordionHelper.types';

import { ProjectFields } from '../../../projectsTypes';

import { useInviteUsersToProjectsModalButton } from './hooks/useInviteUsersToProjectsModalButton';

import { InviteUsersModalBody } from '../../../../common/modalButtons/InviteUsersModalButton/components/InviteUsersModalBody';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { ItemsListAccordionHelper } from '../../../../../helpers/ItemsListAccordionHelper';

import { projectsKeys } from '../../../../../locales/keys';

export type InviteUsersToProjectsModalButtonProjects =
  ItemsListAccordionHelperItems;

interface InviteUsersToProjectsModalButtonProps {
  projects: InviteUsersToProjectsModalButtonProjects;
  className: ClassName;
  onClose?: () => void;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  tooltipSingleton?: boolean;
}

const INVITE_USERS_TO_PROJECTS_FORM = 'invite-users-to-projects-form';

function InviteUsersToProjectsModalButton({
  projects,
  className,
  icon,
  iconClassName,
  i18nText,
  tooltipSingleton,
  onClose
}: InviteUsersToProjectsModalButtonProps) {
  const projectIds = map(projects, ProjectFields.ID);

  const {
    inviteUsersToProjectsErrorMessage,
    inviteUsersToProjectsLoading,
    handleDeselectAllUsers,
    handleDeselectUser,
    handleInviteUsersToProjects,
    handleSelectUser,
    selectedUserIds,
    selectedUsers
  } = useInviteUsersToProjectsModalButton({ projectIds });

  return (
    <FormModalButton
      i18nText={i18nText}
      className={className}
      form={INVITE_USERS_TO_PROJECTS_FORM}
      i18nSubmitText={projectsKeys.inviteSelected}
      i18nTitle={projectsKeys.inviteToMultipleProjects}
      isLoading={inviteUsersToProjectsLoading}
      onOpen={handleDeselectAllUsers}
      onClose={onClose}
      onSubmit={handleInviteUsersToProjects}
      submitDisabled={isEmpty(selectedUserIds)}
      icon={icon}
      iconClassName={iconClassName}
      tooltipI18nText={projectsKeys.inviteUser}
      tooltipPlacement={TooltipPlacement.BOTTOM}
      tooltipSingleton={tooltipSingleton}
    >
      <div className="flex-1 overflow-y-auto px-2">
        <div className="px-4">
          <InviteUsersModalBody
            errorMessage={inviteUsersToProjectsErrorMessage}
            selectedUsers={selectedUsers}
            selectedUserIds={selectedUserIds}
            onSelectUser={handleSelectUser}
            onDeselectUser={handleDeselectUser}
          />
        </div>
        <ItemsListAccordionHelper
          items={projects}
          i18nModelName={projectsKeys.pluralLowerCase}
        />
      </div>

      <AlertMessage
        addClassName="mx-4"
        message={inviteUsersToProjectsErrorMessage}
      />
    </FormModalButton>
  );
}

export default InviteUsersToProjectsModalButton;
