import React, { memo } from 'react';

import { ItemStatus } from '../../../../../types';

import { IndexTableCell } from '../IndexTableCell';

import { Progress } from '../../../../../helpers/Progress';
import { Status } from '../../../../../helpers/Status';

interface IndexTableRowStatusProgressProps {
  max: number;
  min: number;
  status: ItemStatus | null;
  clientStatus?: ItemStatus | null;
}

function IndexTableRowStatusProgress({
  max,
  min,
  status,
  clientStatus
}: IndexTableRowStatusProgressProps) {
  return (
    <IndexTableCell>
      {status ? <Status status={status} /> : null}
      {clientStatus ? <Status status={clientStatus} /> : null}
      {max ? (
        <div className="mt-1 flex space-x-2 items-center w-32">
          <div className="text-xs text-gray-500 whitespace-nowrap">
            {`${min}/${max}`}
          </div>
          <Progress min={min} max={max} />
        </div>
      ) : null}
    </IndexTableCell>
  );
}

export default memo<IndexTableRowStatusProgressProps>(
  IndexTableRowStatusProgress
);
