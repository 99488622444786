import React, { memo } from 'react';

import {
  FetchProjectsCheckedHash,
  FetchProjectsEditProject,
  FetchProjectsOnSetCheckedIds,
  ProjectUUID,
  UpdatePaginatedProjectsItemCacheAction
} from '../../../projectsTypes';

import { IndexTableBody } from '../../../../common/components/tables/IndexTableBody';

import {
  ProjectsIndexTableRow,
  ProjectsIndexTableRowProjects,
  ProjectsIndexTableRowProject
} from '../ProjectsIndexTableRow';

interface ProjectsIndexTableBodyProps {
  projects: ProjectsIndexTableRowProjects;
  checkedHash: FetchProjectsCheckedHash;
  onSetCheckedIds: FetchProjectsOnSetCheckedIds;
  updateProjectCache?: UpdatePaginatedProjectsItemCacheAction<ProjectsIndexTableRowProject>;
  onEdit?: FetchProjectsEditProject;
  onEditMouseOver?: FetchProjectsEditProject;
  selectedProjectUuid?: ProjectUUID | null;
}

function ProjectsIndexTableBody({
  projects,
  checkedHash,
  onSetCheckedIds,
  updateProjectCache,
  onEdit,
  onEditMouseOver,
  selectedProjectUuid
}: ProjectsIndexTableBodyProps) {
  return (
    <IndexTableBody>
      {projects.map((project) => (
        <ProjectsIndexTableRow
          key={project.uuid}
          project={project}
          checked={checkedHash[project.id]}
          onCheck={onSetCheckedIds}
          updateProjectCache={updateProjectCache}
          onEdit={onEdit}
          onEditMouseOver={onEditMouseOver}
          selected={project.uuid === selectedProjectUuid}
        />
      ))}
    </IndexTableBody>
  );
}

export default memo<ProjectsIndexTableBodyProps>(ProjectsIndexTableBody);
