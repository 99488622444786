import React, { useCallback } from 'react';

import { ProjectUUID } from '../../../../../../../projectsTypes';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';
import { useRemoveMembersFromProject } from '../../../../../../../hooks/useRemoveMembersFromProject';

import {
  REMOVE_MEMBERS_FROM_PROJECT_QUERY,
  RemoveMembersFromProjectQueryResponse
} from '../../../../../../../queries/removeMembersFromProject.query';

import { projectsKeys, words } from '../../../../../../../../../locales/keys';

import { ConfirmModalButton } from '../../../../../../../../../helpers/buttons/ConfirmModalButton';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';

interface ProjectsDropdownLeaveProjectButtonProps {
  projectUuid: ProjectUUID;
}

function ProjectsDropdownLeaveProjectButton({
  projectUuid
}: ProjectsDropdownLeaveProjectButtonProps) {
  const currentUser = useCurrentUser();

  const {
    removeMembersFromProject,
    removeMembersFromProjectLoading,
    removeMembersFromProjectErrorMessage
  } = useRemoveMembersFromProject<RemoveMembersFromProjectQueryResponse>({
    query: REMOVE_MEMBERS_FROM_PROJECT_QUERY
  });

  const handleRemoveMember = useCallback<() => Promise<unknown>>(
    () =>
      removeMembersFromProject({
        uuid: projectUuid,
        userIds: [currentUser.id]
      }),
    [removeMembersFromProject, currentUser.id, projectUuid]
  );

  return (
    <ConfirmModalButton
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full"
      errorMessage={removeMembersFromProjectErrorMessage}
      i18nButtonText={projectsKeys.remove_from}
      i18nSubmitText={words.remove}
      i18nText={
        projectsKeys.areYouSureYouWantRemoveYouFromProjectThisActionCannotBeUndone
      }
      i18nTitle={words.remove}
      isLoading={removeMembersFromProjectLoading}
      modalIcon={IconsEnum.EXCLAMATION}
      modalIconClassName="h-6 w-6 text-red-600"
      onSubmit={handleRemoveMember}
    />
  );
}

export default ProjectsDropdownLeaveProjectButton;
