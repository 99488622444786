import { UserID } from '../../../users/usersTypes';

import { ProjectNanoID, ProjectUUID } from '../../projectsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface RemoveMembersFromProjectOptions {
  query: string;
  pluralScope?: string;
}

export interface RemoveMembersFromProjectResponse<
  RemoveMembersFromProjectRecordType
> {
  removeMembersFromProject: {
    status: string;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: RemoveMembersFromProjectRecordType;
    errors: RemoveMembersFromProjectError;
  };
}

export interface RemoveMembersFromProjectInput {
  uuid: ProjectUUID;
  userIds: UserID[];
}

export interface RemoveMembersFromProjectError {
  fullMessages: string[] | null;
}

const action = 'removeMembersFromProject';

const scope = 'project';

const defaultPluralScope = 'projects';

function useRemoveMembersFromProject<RemoveMembersFromProjectRecordType>({
  query,
  pluralScope = defaultPluralScope
}: RemoveMembersFromProjectOptions) {
  const {
    updateQuery,
    updateQueryData,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading,
    updateQueryReset
  } = useUpdateQuery<
    RemoveMembersFromProjectInput,
    RemoveMembersFromProjectResponse<RemoveMembersFromProjectRecordType>,
    RemoveMembersFromProjectError,
    RemoveMembersFromProjectRecordType
  >({ action, pluralScope, scope, query });

  return {
    removeMembersFromProject: updateQuery,
    removeMembersFromProjectData: updateQueryData,
    removeMembersFromProjectError: updateQueryError,
    removeMembersFromProjectErrorMessage: updateQueryErrorMessage,
    removeMembersFromProjectLoading: updateQueryLoading,
    removeMembersFromProjectReset: updateQueryReset
  };
}

export default useRemoveMembersFromProject;
