import React, { Fragment } from 'react';

import { ProjectUUID } from '../../../projectsTypes';

import {
  FETCH_INDEX_PROJECT_QUERY,
  FetchIndexProjectQueryResponse
} from '../../../queries/fetchIndexProject.query';

import { useProject } from '../../../hooks/useProject';

import { ItemSidebarBills } from '../../../../bills/components/sidebars/ItemSidebarBills';

import { ItemIndexSidebar } from '../../../../common/components/sidebars/ItemIndexSidebar';
import { ItemIndexSidebarClient } from '../../../../common/components/sidebars/ItemIndexSidebar/components/ItemIndexSidebarClient';
import {
  ItemSidebarDates,
  ItemSidebarDatesScopeEnum
} from '../../../../common/components/sidebars/ItemSidebarDates';
import { ItemSidebarPayments } from '../../../../common/components/sidebars/ItemSidebarPayments';

import { ProjectIdsSidebar } from '../ProjectIdsSidebar';
import { ProjectsIndexSidebarDescription } from './components/ProjectsIndexSidebarDescription';
import { ProjectsIndexSidebarDropdown } from './components/ProjectsIndexSidebarDropdown';
import { ProjectsIndexSidebarInfo } from './components/ProjectsIndexSidebarInfo';
import { ProjectsIndexSidebarTasks } from './components/ProjectsIndexSidebarTasks';
import { ProjectsIndexSidebarTeam } from './components/ProjectsIndexSidebarTeam';
import { ProjectSidebarActionsBlock } from '../ProjectSidebarActionsBlock';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { projectsKeys } from '../../../../../locales/keys';

import { ProjectCache } from '../../../ProjectCache';
import { ProjectPath } from '../../../ProjectPath';
import { ProjectsPermissions } from '../../../projectsConstants';

interface ProjectsIndexSidebarProps {
  projectUuid: ProjectUUID;
  projects: FetchIndexProjectQueryResponse[];
  onClose: () => void;
}

function ProjectsIndexSidebar({
  projectUuid,
  projects,
  onClose
}: ProjectsIndexSidebarProps) {
  const { project, projectError, projectFetched, projectIsPlaceholderData } =
    useProject<FetchIndexProjectQueryResponse>({
      projects,
      cacheKey: ProjectCache.showCacheKey(),
      query: FETCH_INDEX_PROJECT_QUERY,
      uuid: projectUuid
    });

  return (
    <ItemIndexSidebar
      itemError={projectError}
      itemFetched={projectFetched}
      itemIsPlaceholderData={projectIsPlaceholderData}
      onClose={onClose}
      viewLinkHref={project ? ProjectPath.show(project.nanoId) : undefined}
      viewLinkI18nText={projectsKeys.view}
      dropdownContent={
        project ? <ProjectsIndexSidebarDropdown project={project} /> : undefined
      }
    >
      {project ? (
        <Fragment>
          <ProjectsIndexSidebarInfo project={project} />
          <ProjectsIndexSidebarDescription project={project} />
          <ItemSidebarPayments item={project} scope="projects" />
          <ProjectIdsSidebar project={project} />
          <ItemSidebarDates
            item={project}
            scope={ItemSidebarDatesScopeEnum.PROJECTS}
          />
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECT_BILLS_BLOCK}
          >
            <ItemSidebarBills projectNanoId={project.nanoId} />
          </CheckPermissions>
          <ProjectSidebarActionsBlock project={project} />
          <ProjectsIndexSidebarTasks project={project} />
          <ItemIndexSidebarClient item={project} />
          <ProjectsIndexSidebarTeam project={project} />
        </Fragment>
      ) : null}
    </ItemIndexSidebar>
  );
}

export default ProjectsIndexSidebar;
