import React, { Fragment } from 'react';

import { ProjectNanoID } from '../../../../projects/projectsTypes';

import { MoneyHelper } from '../../../../../helpers/MoneyHelper';
import { Translate } from '../../../../../helpers/Translate';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';

import { billsKeys, words } from '../../../../../locales/keys';

import { PayBillsModalButton } from '../../modalButtons/PayBillsModalButton';

import { useProject } from '../../../../projects/hooks/useProject';

import { ProjectCache } from '../../../../projects/ProjectCache';

import { ProjectPath } from '../../../../projects/ProjectPath';

import {
  FetchProjectBillsQueryResponse,
  FETCH_PROJECT_BILLS_QUERY
} from '../../../../projects/queries/fetchProjectBills.query';

interface ItemSidebarBillsProps {
  projectNanoId: ProjectNanoID;
}

function ItemSidebarBills({ projectNanoId }: ItemSidebarBillsProps) {
  const { project, projectError, projectFetched } =
    useProject<FetchProjectBillsQueryResponse>({
      projects: [],
      cacheKey: ProjectCache.billsCacheKey(projectNanoId),
      query: FETCH_PROJECT_BILLS_QUERY,
      uuid: projectNanoId
    });

  if (!project || !project.unpaidBillsSum) return null;

  return (
    <Fragment>
      <AlertMessage message={projectError} />
      <LoadingSkeleton loaded={projectFetched}>
        <div className="p-4">
          <div className="flex justify-between items-center mb-2">
            <div>
              <h6 className="font-light uppercase">
                <Translate id={billsKeys.plural} />
              </h6>
            </div>
            <div className="flex space-x-1">
              <NextPureLinkHelper
                i18nText={words.viewAll}
                href={ProjectPath.invoicesByNanoId(projectNanoId)}
                className="hover:underline"
              />
            </div>
          </div>

          <div className="flex justify-between mt-2">
            <div className="flex flex-col">
              <span className="text-lg text-gray-700 dark:text-gray-100 font-semibold">
                <MoneyHelper value={project.unpaidBillsSum} />
              </span>
            </div>
            <PayBillsModalButton
              className="inline-flex self-start px-2 sm:px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm hover:shadow-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 dark:focus:ring-gray-100 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900"
              i18nText={words.pay}
              bills={project.unpaidBills}
            />
          </div>
        </div>
      </LoadingSkeleton>
    </Fragment>
  );
}

export default ItemSidebarBills;
