import React, { Fragment, useEffect, useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';

import { useBillPayment } from '../../../../../hooks/useBillPayment';

import { PayoneerCheckoutPayButton } from '../../../../../../payoneerCheckout/component/buttons/PayoneerCheckoutPayButton';

import { BillCard } from '../../../../cards/BillCard';

import { BillCache } from '../../../../../BillCache';

import { AlertMessage } from '../../../../../../../helpers/AlertMessage';

import {
  FetchBillQueryResponse,
  FETCH_BILL_QUERY
} from '../../../../../queries/fetchBill.query';

import { words, stringsKeys } from '../../../../../../../locales/keys';

import { PayBillModalButtonBillItem } from '../../../PayBillModalButton';

interface PayBillsModalButtonBillProps {
  bill: PayBillModalButtonBillItem;
}

function generateHtmlForm(str: string) {
  const iOS =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;
  return iOS && isSafari && str ? str.replace(/target="_blank"/gi, '') : str;
}

function PayBillsModalButtonBill({ bill }: PayBillsModalButtonBillProps) {
  const {
    billPayment,
    billPaymentLoading,
    billPaymentError,
    billPaymentFetched,
    toggleBillPaymentEnabled,
    handleBillPaymentEnabled
  } = useBillPayment<FetchBillQueryResponse>({
    uuid: bill.uuid,
    query: FETCH_BILL_QUERY,
    cacheKey: BillCache.showPaymentCacheKey(bill.uuid)
  });

  const openPaymentForm = useCallback<() => Promise<string>>(async () => {
    if (billPayment?.paymentUrl) {
      toggleBillPaymentEnabled();
      window.open(billPayment.paymentUrl, billPayment.paymentUrl);
      return billPayment.paymentUrl;
    }

    if (billPayment?.paymentForm) {
      toggleBillPaymentEnabled();

      const paymentFormElement = document.getElementById(
        '2checkout'
      ) as HTMLFormElement;
      paymentFormElement.setAttribute('target', '_self');
      paymentFormElement.submit();

      return billPayment.paymentForm;
    }
    return;
  }, [
    billPayment?.paymentForm,
    billPayment?.paymentUrl,
    toggleBillPaymentEnabled
  ]);

  useEffect(() => {
    if (billPayment) {
      openPaymentForm();
    }
  }, [billPayment, openPaymentForm]);

  const isPayoneerCheckoutForm = bill?.account === 'payoneer_checkout';

  return (
    <div className="max-w-xl mx-auto pt-4 pb-6 relative">
      <Fragment>
        <BillCard withoutTitle bill={bill} />
        <div className="flex justify-center space-x-4">
          {!isPayoneerCheckoutForm ? (
            <PureIconButtonHelper
              className="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:bg-blue-500 hover:shadow-md w-72 max-w-full justify-center"
              i18nText={words.pay}
              icon={IconsEnum.CASH}
              disabled={!bill.payable || billPaymentLoading}
              iconClassName="h-6 w-6 p-0.5"
              onClick={toggleBillPaymentEnabled}
            />
          ) : null}
          {isPayoneerCheckoutForm && bill?.payer?.payoneerCheckoutAddress ? (
            <PayoneerCheckoutPayButton
              bill={billPayment}
              showClassName="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:bg-blue-500 hover:shadow-md w-72 max-w-full justify-center"
              hideClassName="py-2 pl-2 pr-4 space-x-1 rounded-md flex m-auto items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base shadow-sm hover:shadow-md w-72 max-w-full justify-center bg-gray-100 dark:bg-gray-850 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 mb-4"
              disabled={!bill.payable}
              showI18nText={words.pay}
              hideI18nText={words.cancel}
              onMouseEnter={handleBillPaymentEnabled}
              billError={billPaymentError}
              billFetched={billPaymentFetched}
            />
          ) : null}
          {isPayoneerCheckoutForm && !bill?.payer?.payoneerCheckoutAddress ? (
            <AlertMessage i18nMessage={stringsKeys.pleaseProvideYourAddress} />
          ) : null}
        </div>
        {billPayment?.paymentForm ? (
          <span
            className="hidden"
            dangerouslySetInnerHTML={{
              __html: generateHtmlForm(billPayment.paymentForm)
            }}
          />
        ) : null}
      </Fragment>
    </div>
  );
}

export default PayBillsModalButtonBill;
