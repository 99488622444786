import { FieldArrayWithId, UseFormRegister } from 'react-hook-form';

import {
  ErrorMessages,
  FieldIcons,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';
import { UpdateProjectsAmountsFormData } from './hooks/useUpdateProjectsAmountsForm/useUpdateProjectsAmountsForm';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';

import { ProjectPath } from '../../../ProjectPath';

import { stringsKeys } from '../../../../../locales/keys';

interface UpdateProjectsAmountsFormProps {
  amountsErrorsMessages?: ErrorMessages;
  fields: FieldArrayWithId<UpdateProjectsAmountsFormData, 'projects'>[];
  form: string;
  isLoading?: IsLoading;
  register: UseFormRegister<UpdateProjectsAmountsFormData>;
  registerCommonAmount: RegisterFormFieldType<HTMLInputElement>;
  setAllAmounts: () => void;
}

function UpdateProjectsAmountsForm({
  amountsErrorsMessages,
  fields,
  form,
  isLoading,
  register,
  registerCommonAmount,
  setAllAmounts
}: UpdateProjectsAmountsFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2 z-0" id={form}>
      <div className="p-4">
        <div className="flex justify-between items-center space-x-2">
          <div className="flex-1">
            <div className="w-full">
              <InputField
                className="relative"
                inputClassName="basic-input pl-7 pr-12 number-appearance-none"
                disabled={isLoading}
                id="batch_estimate_date_all"
                labelClassName="font-light uppercase"
                leftIcon={FieldIcons.DOLLAR}
                name={registerCommonAmount.name}
                onChange={registerCommonAmount.onChange}
                ref={registerCommonAmount.ref}
                type="number"
              />
            </div>
          </div>
          <div>
            <PureButtonHelper
              className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
              i18nText={stringsKeys.applyToAll}
              onClick={setAllAmounts}
            />
          </div>
        </div>
      </div>
      <hr className="border-dashed m-4 dark:border-gray-700" />
      <div className="p-4">
        <div className="space-y-3">
          {fields.map((field, index) => (
            <div className="flex space-x-2 items-start" key={field.id}>
              <div className="flex-1 min-w-0">
                <div className="text-sm sm:text-base line-clamp-2 leading-tight sm:leading-tight">
                  <NextPureLinkHelper
                    className="hover:underline break-wrap"
                    href={ProjectPath.show(field.nanoId)}
                    text={field.name}
                  />
                </div>
              </div>
              <div>
                <div className="w-36">
                  <InputField
                    disabled={isLoading}
                    error={amountsErrorsMessages?.[index]}
                    inputClassName="basic-input pl-7 pr-12 number-appearance-none"
                    inputWrapperClassName="relative"
                    labelClassName="font-light uppercase"
                    leftIcon={FieldIcons.DOLLAR}
                    name={register(`projects.${index}.estimateCost`).name}
                    onChange={
                      register(`projects.${index}.estimateCost`).onChange
                    }
                    ref={register(`projects.${index}.estimateCost`).ref}
                    showErrorIcon={false}
                    type="number"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Form>
  );
}

export default UpdateProjectsAmountsForm;
