import React from 'react';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { Translate } from '../../../../../helpers/Translate';

import { formsFields } from '../../../../../locales/keys';

import { ProjectsPermissions } from '../../../projectsConstants';

import { ProjectIdsSidebarProject } from './ProjectIdsSidebar.types';

interface ProjectIdsSidebarProps {
  project: ProjectIdsSidebarProject;
}

function ProjectIdsSidebar({ project }: ProjectIdsSidebarProps) {
  return (
    <CheckPermissions action={ProjectsPermissions.READ_PROJECT_ID}>
      <div className="p-4">
        <div className="space-y-1.5">
          <div className="flex space-x-2 justify-between">
            <div>
              <Translate id={formsFields.id} />:
            </div>
            <div>{project.id}</div>
          </div>
        </div>
        <CheckPermissions action={ProjectsPermissions.READ_PROJECT_UUID}>
          <div className="space-y-1.5">
            <div className="flex space-x-2 justify-between">
              <div>
                <Translate id={formsFields.uuid} />:
              </div>
              <div>{project.uuid}</div>
            </div>
          </div>
        </CheckPermissions>
        <CheckPermissions action={ProjectsPermissions.READ_PROJECT_NANO_ID}>
          <div className="space-y-1.5">
            <div className="flex space-x-2 justify-between">
              <div>
                <Translate id={formsFields.nanoId} />:
              </div>
              <div>{project.nanoId}</div>
            </div>
          </div>
        </CheckPermissions>
      </div>
    </CheckPermissions>
  );
}

export default ProjectIdsSidebar;
