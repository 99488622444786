import React from 'react';

import { ProjectSidebarCreateBillProject } from '../../../../../ProjectSidebarCreateBill';

import { CreateProjectBillModalButton } from '../../../../../../../../bills/components/modalButtons/CreateProjectBillModalButton';

interface ProjectsDropdownSendInvoiceButtonProps {
  project: ProjectSidebarCreateBillProject;
}

function ProjectsDropdownSendInvoiceButton({
  project
}: ProjectsDropdownSendInvoiceButtonProps) {
  return (
    <CreateProjectBillModalButton
      projectId={project.id}
      projectNanoId={project.nanoId}
      className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
    />
  );
}

export default ProjectsDropdownSendInvoiceButton;
