import React from 'react';

import { IsSelected } from '../../../../../types';

import {
  FetchProjectsChecked,
  FetchProjectsEditProject,
  FetchProjectsOnSetCheckedIds,
  UpdatePaginatedProjectsItemCacheAction
} from '../../../projectsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { IndexTableCellDate } from '../../../../common/components/tables/IndexTableCellDate';
import { IndexTableCellUserAvatar } from '../../../../common/components/tables/IndexTableCellUserAvatar';
import { IndexTableCellUsers } from '../../../../common/components/tables/IndexTableCellUsers';
import { IndexTableCellMoney } from '../../../../common/components/tables/IndexTableCellMoney';
import { IndexTableRow } from '../../../../common/components/tables/IndexTableRow';
import { IndexTableRowDateBurning } from '../../../../common/components/tables/IndexTableRowDateBurning';
import { IndexTableRowNameDescriptionDate } from '../../../../common/components/tables/IndexTableRowNameDescriptionDate';
import { IndexTableRowPaymentsProgress } from '../../../../common/components/tables/IndexTableRowPaymentsProgress';
import { IndexTableRowStatusProgress } from '../../../../common/components/tables/IndexTableRowStatusProgress';
import { IndexTableRowTags } from '../../../../common/components/tables/IndexTableRowTags';

import { ProjectsIndexTableCellFavorite } from '../ProjectsIndexTableCellFavorite';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { ProjectPath } from '../../../ProjectPath';

import { ProjectsIndexTableRowProject } from './ProjectsIndexTableRow.types';

import { projectsKeys } from '../../../../../locales/keys';

import { ProjectsPermissions } from '../../../projectsConstants';

interface ProjectsIndexTableRowProps {
  project: ProjectsIndexTableRowProject;
  checked: FetchProjectsChecked;
  onCheck: FetchProjectsOnSetCheckedIds;
  updateProjectCache?: UpdatePaginatedProjectsItemCacheAction<ProjectsIndexTableRowProject>;
  onEdit: FetchProjectsEditProject;
  onEditMouseOver: FetchProjectsEditProject;
  selected?: IsSelected;
}

function ProjectsIndexTableRow({
  project,
  checked,
  onCheck,
  updateProjectCache,
  onEdit,
  onEditMouseOver,
  selected
}: ProjectsIndexTableRowProps) {
  const currentUser = useCurrentUser();

  return (
    <IndexTableRow
      expandable
      scope="project"
      selected={selected}
      checked={checked}
      itemId={project.id}
      itemUuid={project.uuid}
      onCheck={onCheck}
      withoutCheckbox={
        !currentUser.hasPermissions(
          ProjectsPermissions.READ_PROJECTS_TABLE_CHECKBOXES
        )
      }
      onEdit={onEdit}
      onEditMouseOver={onEditMouseOver}
    >
      <ProjectsIndexTableCellFavorite
        project={project}
        addTdClassName={
          currentUser.hasPermissions(
            ProjectsPermissions.READ_PROJECTS_TABLE_CHECKBOXES
          )
            ? undefined
            : 'px-5'
        }
        updateProjectCache={updateProjectCache}
      />
      <IndexTableRowNameDescriptionDate
        href={ProjectPath.show(project.nanoId)}
        itemId={project.id}
        itemUuid={project.uuid}
        itemNanoId={project.nanoId}
        name={project.name}
        description={project.description}
        date={project.createdAt}
        locale={currentUser.locale}
        onEditMouseOver={onEditMouseOver}
      />
      <IndexTableCellUserAvatar user={project.user} />
      <IndexTableCellUsers
        ignoreUser={project.user}
        users={project.members}
        i18nModalTitle={projectsKeys.projectTeam}
      />
      <IndexTableRowDateBurning
        date={project.implementationDate}
        burning={project.burning}
        locale={currentUser.locale}
      />
      <IndexTableRowStatusProgress
        status={
          currentUser.hasPermissions(
            ProjectsPermissions.READ_PROJECTS_TABLE_STATUS_FIELD
          )
            ? project.status
            : null
        }
        clientStatus={
          currentUser.hasPermissions(
            ProjectsPermissions.READ_PROJECTS_TABLE_TASKS_CLIENT_STATUS_FIELD
          )
            ? project.tasksClientStatus
            : null
        }
        max={project.activeTasksCount}
        min={project.performedTasksCount}
      />
      <CheckPermissions
        action={ProjectsPermissions.READ_PROJECT_TASKS_AMOUNTS_SUM}
      >
        <IndexTableRowPaymentsProgress
          max={project.tasksAmountsSum}
          min={project.tasksPrepaymentsSum}
        />
      </CheckPermissions>
      <CheckPermissions
        action={ProjectsPermissions.READ_PROJECT_TASKS_CLIENT_PREPAYMENTS_SUM}
      >
        <IndexTableRowPaymentsProgress
          max={project.tasksAmountsSum}
          min={project.tasksClientPrepaymentsSum}
        />
      </CheckPermissions>
      <CheckPermissions action={ProjectsPermissions.READ_PROJECT_ESTIMATE_COST}>
        <IndexTableCellMoney value={project.estimateCost} />
      </CheckPermissions>
      <CheckPermissions action={ProjectsPermissions.READ_PROJECT_TAGS}>
        <IndexTableRowTags tags={project.tags} />
      </CheckPermissions>
      <IndexTableCellDate date={project.createdAt} />
    </IndexTableRow>
  );
}

export default ProjectsIndexTableRow;
