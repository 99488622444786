import React, { memo } from 'react';

import { IndexTableCell } from '../IndexTableCell';

import { MoneyHelper } from '../../../../../helpers/MoneyHelper';
import { Progress } from '../../../../../helpers/Progress';

interface IndexTableRowPaymentsProgressProps {
  max: number;
  min: number;
}

function IndexTableRowPaymentsProgress({
  max,
  min
}: IndexTableRowPaymentsProgressProps) {
  return (
    <IndexTableCell>
      {max ? (
        <>
          <div className="text-gray-900 dark:text-gray-200 font-normal text-sm mt-1">
            <span className="mt-1">
              <MoneyHelper value={max} />
            </span>
          </div>
          <div className="mt-1 flex space-x-2 items-center w-32">
            <div className="text-xs text-gray-500 whitespace-nowrap">
              <MoneyHelper value={min} />
            </div>
            <Progress min={min} max={max} />
          </div>
        </>
      ) : null}
    </IndexTableCell>
  );
}

export default memo<IndexTableRowPaymentsProgressProps>(
  IndexTableRowPaymentsProgress
);
