import { gql } from 'graphql-request';

import { ProjectNanoID, ProjectUUID } from '../projectsTypes';

export interface InviteUsersToProjectQueryResponse {
  uuid: ProjectUUID;
  nanoId: ProjectNanoID;
}

export const INVITE_USERS_TO_PROJECT_QUERY = gql`
  mutation InviteUsersToProject($uuid: ID!, $userIds: [ID!]!) {
    inviteUsersToProject(input: { uuid: $uuid, userIds: $userIds }) {
      errors {
        fullMessages
      }
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
    }
  }
`;
