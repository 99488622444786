import React from 'react';

import { ProjectNanoID } from '../../../../../projectsTypes';

import {
  FETCH_PROJECT_MEMBERS_QUERY,
  FetchProjectMembersQueryResponse
} from '../../../../../../projectMembers/queries/projectMembers.query';
import { ASSIGN_USER_TO_PROJECT_QUERY } from '../../../../../queries/assignUserToProject.query';
import { REMOVE_MEMBERS_FROM_PROJECT_QUERY } from '../../../../../queries/removeMembersFromProject.query';
import { INVITE_USERS_TO_PROJECT_QUERY } from '../../../../../queries/inviteUsersToProject.query';

import { useProjectMembers } from '../../../../../../projectMembers/hooks/useProjectMembers';
import { useAssignUserToProject } from '../../../../../hooks/useAssignUserToProject';
import { useRemoveMembersFromProject } from '../../../../../hooks/useRemoveMembersFromProject';
import { useInviteUsersToProject } from '../../../../../hooks/useInviteUsersToProject';

import {
  ItemIndexSidebarTeam,
  ItemIndexSidebarTeamItem
} from '../../../../../../common/components/sidebars/ItemIndexSidebar/components/ItemIndexSidebarTeam';

import { ProjectCache } from '../../../../../ProjectCache';

interface ProjectsIndexSidebarTeamProps {
  project: ItemIndexSidebarTeamItem;
}

const projectsScope = 'projects';

function ProjectsIndexSidebarTeam({ project }: ProjectsIndexSidebarTeamProps) {
  const projectNanoId = project.nanoId as ProjectNanoID;

  const { projectMembers, projectMembersError } =
    useProjectMembers<FetchProjectMembersQueryResponse>({
      cacheKey: ProjectCache.projectMembersCacheKey(projectNanoId),
      query: FETCH_PROJECT_MEMBERS_QUERY,
      initialFilters: { projectNanoId }
    });

  const {
    inviteUsersToProject,
    inviteUsersToProjectErrorMessage,
    inviteUsersToProjectLoading
  } = useInviteUsersToProject({ query: INVITE_USERS_TO_PROJECT_QUERY });

  const {
    removeMembersFromProject,
    removeMembersFromProjectErrorMessage,
    removeMembersFromProjectLoading
  } = useRemoveMembersFromProject({ query: REMOVE_MEMBERS_FROM_PROJECT_QUERY });

  const {
    assignUserToProject,
    assignUserToProjectLoading,
    assignUserToProjectErrorMessage
  } = useAssignUserToProject({ query: ASSIGN_USER_TO_PROJECT_QUERY });

  return (
    <ItemIndexSidebarTeam
      inviteUsersErrorMessage={
        inviteUsersToProjectErrorMessage || projectMembersError
      }
      isMemberRemoving={removeMembersFromProjectLoading}
      isUserAssigning={assignUserToProjectLoading}
      isUsersInviting={inviteUsersToProjectLoading}
      item={project}
      itemMembers={projectMembers}
      onAssignUser={assignUserToProject}
      onInviteUsers={inviteUsersToProject}
      onRemoveMember={removeMembersFromProject}
      scope={projectsScope}
      assignUserErrorMessage={assignUserToProjectErrorMessage}
      removeMembersErrorMessage={removeMembersFromProjectErrorMessage}
    />
  );
}

export default ProjectsIndexSidebarTeam;
