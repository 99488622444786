import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';

import {
  FetchProjectsGqlQuery,
  FetchProjectsCacheKey,
  FetchProjectsFilters,
  FetchProjectsSort,
  FetchProjectsPage,
  FetchProjectsLimit
} from '../../projectsTypes';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_PROJECTS_FILTERS,
  INITIAL_PROJECTS_LIMIT,
  INITIAL_PROJECTS_PAGE,
  INITIAL_PROJECTS_SORT
} from '../../projectsConstants';

interface ProjectsDefaultOptions {
  cacheKey: FetchProjectsCacheKey;
  query: FetchProjectsGqlQuery;
  initialFilters?: FetchProjectsFilters;
  initialSort?: FetchProjectsSort;
  initialPage?: FetchProjectsPage;
  initialLimit?: FetchProjectsLimit;
  options?: {
    cacheTime?: number;
  };
}

interface ProjectsWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface ProjectsWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type ProjectsOptions = ProjectsDefaultOptions &
  (ProjectsWithPrefetchItemOptions | ProjectsWithoutPrefetchItemOptions);

const scope = 'projects';

function usePaginatedProjects<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_PROJECTS_FILTERS,
  initialSort = INITIAL_PROJECTS_SORT,
  initialPage = INITIAL_PROJECTS_PAGE,
  initialLimit = INITIAL_PROJECTS_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: ProjectsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    prefetchItem
  } = useIndexQuery<ItemType>({
    scope,
    query,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options
  });

  return {
    projectsData: data,
    projects: items,
    projectsError: itemsError,
    projectsTotalCount: itemsTotalCount,
    projectsFetched: isFetched,
    projectsLoading: isLoading,
    projectsIsPlaceholderData: isPlaceholderData,
    projectsFilters: currentFilters,
    projectsSort: currentSort,
    projectsPage: currentPage,
    projectsLimit: currentLimit,
    updateProjectCache: updateItemCache,
    filterProjects: filterItems,
    changeProjectsFilters: changeItemsFilters,
    clearProjectsFilters: clearItemsFilters,
    sortProjects: sortItems,
    paginateProjects: paginateItems,
    limitProjects: limitItems,
    prefetchProjects: prefetchItems,
    prefetchProject: prefetchItem
  };
}

export default usePaginatedProjects;
