import { gql } from 'graphql-request';

import { ProjectNanoID, ProjectUUID } from '../projectsTypes';

export interface AssignUserToProjectQueryResponse {
  uuid: ProjectUUID;
  nanoId: ProjectNanoID;
}

export const ASSIGN_USER_TO_PROJECT_QUERY = gql`
  mutation AssignUserToProject($uuid: ID!, $userId: ID!) {
    assignUserToProject(input: { uuid: $uuid, userId: $userId }) {
      errors {
        fullMessages
      }
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
    }
  }
`;
