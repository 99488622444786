import map from 'lodash/map';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { ItemsListAccordionHelperItems } from '../../../../../helpers/ItemsListAccordionHelper/ItemsListAccordionHelper.types';

import { useCreateProjectsBillsForm } from '../../forms/CreateProjectsBillsForm/hooks/useCreateTasksBillsForm';

import { CreateProjectsBillsForm } from '../../forms/CreateProjectsBillsForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { ItemsListAccordionHelper } from '../../../../../helpers/ItemsListAccordionHelper';

import { ProjectFields } from '../../../projectsTypes';
import { projectsKeys, words } from '../../../../../locales/keys';

export type CreateProjectsBillsModalButtonProjects =
  ItemsListAccordionHelperItems;

interface CreateProjectsBillsModalButtonProps {
  projects: CreateProjectsBillsModalButtonProjects;
  className: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  tooltipSingleton?: boolean;
}

const CREATE_PROJECTS_BILLS_FORM = 'create-projects-bills-form';

function CreateProjectsBillsModalButton({
  projects,
  className,
  icon,
  iconClassName,
  tooltipSingleton,
  i18nText
}: CreateProjectsBillsModalButtonProps) {
  const projectIds = map(projects, ProjectFields.ID);

  const {
    control,
    registerFields,
    watchAccount,
    validationErrors,
    resetCreateProjectsBillForm,
    handleCreateProjectsBills,
    createProjectsBillsErrorMessage,
    createProjectsBillsLoading,
    createProjectsBillsReset
  } = useCreateProjectsBillsForm({ projectIds });

  return (
    <FormModalButton
      i18nText={i18nText}
      className={className}
      form={CREATE_PROJECTS_BILLS_FORM}
      i18nSubmitText={words.send}
      i18nTitle={projectsKeys.sendInvoice}
      isLoading={createProjectsBillsLoading}
      onOpen={resetCreateProjectsBillForm}
      onClose={createProjectsBillsReset}
      onSubmit={handleCreateProjectsBills}
      icon={icon}
      iconClassName={iconClassName}
      tooltipI18nText={projectsKeys.sendInvoice}
      tooltipPlacement={TooltipPlacement.BOTTOM}
      tooltipSingleton={tooltipSingleton}
    >
      <div className="flex-1 overflow-y-auto px-2">
        <CreateProjectsBillsForm
          form={CREATE_PROJECTS_BILLS_FORM}
          isLoading={createProjectsBillsLoading}
          control={control}
          watchAccount={watchAccount}
          registerAmount={registerFields.registerAmount}
          registerBody={registerFields.registerBody}
          amountValidationError={validationErrors.amountValidationError}
          bodyValidationError={validationErrors.bodyValidationError}
        />
        <ItemsListAccordionHelper
          items={projects}
          i18nModelName={projectsKeys.pluralLowerCase}
        />
      </div>
      <AlertMessage
        addClassName="mx-4"
        message={createProjectsBillsErrorMessage}
      />
    </FormModalButton>
  );
}

export default CreateProjectsBillsModalButton;
