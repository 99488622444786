import {
  CreateProjectGqlQuery,
  CreateProjectGqlStatus,
  FetchProjectsCacheKey,
  ProjectDescription,
  ProjectEstimateCost,
  ProjectFileAttachmentIDs,
  ProjectGqlError,
  ProjectImplementationDate,
  ProjectName,
  ProjectTaskNames,
  ProjectUUID
} from '../../projectsTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface CreateProjectOptions {
  query: CreateProjectGqlQuery;
  cacheKeys?: FetchProjectsCacheKey[];
}

interface CreateProjectRecordResponse {
  uuid: ProjectUUID;
}

export interface CreateProjectResponse {
  createProject: {
    status: CreateProjectGqlStatus;
    recordUuid: ProjectUUID;
    record: CreateProjectRecordResponse;
    errors: CreateProjectError;
  };
}

export interface CreateProjectInput {
  name: ProjectName;
  description?: ProjectDescription | null;
  estimateCost?: ProjectEstimateCost | null;
  implementationDate?: ProjectImplementationDate | null;
  fileAttachmentIds?: ProjectFileAttachmentIDs | null;
  taskNames?: ProjectTaskNames | null;
}

export interface CreateProjectError {
  name: ProjectGqlError;
  description: ProjectGqlError;
  estimateCost: ProjectGqlError;
  implementationDate: ProjectGqlError;
  fileAttachmentIds: ProjectGqlError;
  taskNames: ProjectGqlError;
  fullMessages: ProjectGqlError;
}

const action = 'createProject';

function useCreateProject({ query, cacheKeys }: CreateProjectOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateProjectInput,
    CreateProjectResponse,
    CreateProjectError,
    CreateProjectRecordResponse
  >({ action, cacheKeys, query });

  return {
    createProjectData: createQueryData,
    createProjectError: createQueryError,
    createProjectLoading: createQueryLoading,
    createProjectErrorMessage: createQueryErrorMessage,
    createProject: createQuery,
    createProjectReset: createQueryReset
  };
}

export default useCreateProject;
