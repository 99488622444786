import React, { useCallback } from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';

import { CheckboxGroup, CheckboxGroupProps } from '../../CheckboxGroup';

export interface CheckboxGroupFieldProps<T>
  extends Omit<CheckboxGroupProps, 'onChange' | 'value'> {
  control: Control<T>;
  name: FieldPath<T>;
}

function CheckboxGroupField<T>({
  checkboxClassName,
  control,
  disabled,
  i18nLabel,
  inputWrapperClassName,
  items,
  labelClassName,
  name
}: CheckboxGroupFieldProps<T>) {
  const handleRenderCheckboxGroup = useCallback(
    ({ field: { onChange, value } }) => (
      <CheckboxGroup
        checkboxClassName={checkboxClassName}
        disabled={disabled}
        i18nLabel={i18nLabel}
        inputWrapperClassName={inputWrapperClassName}
        labelClassName={labelClassName}
        onChange={onChange}
        items={items}
        name={name}
        value={value}
      />
    ),
    [
      checkboxClassName,
      disabled,
      i18nLabel,
      inputWrapperClassName,
      items,
      labelClassName,
      name
    ]
  );
  return (
    <Controller
      control={control}
      name={name}
      render={handleRenderCheckboxGroup}
    />
  );
}

export default CheckboxGroupField;
