import { gql } from 'graphql-request';

import {
  ProjectID,
  ProjectUUID,
  ProjectName,
  ProjectDescription,
  ProjectStatus,
  ProjectCreatedAt,
  ProjectUserFullName,
  ProjectUserImageFile,
  ProjectUserImageUUID,
  ProjectUserUUID,
  ProjectSetDate,
  ProjectImplementationDate,
  ProjectBurning,
  ProjectFavorite,
  ProjectNotificationsEnabled,
  ProjectActiveTasksCount,
  ProjectPerformedTasksCount,
  ProjectCanceledTasksCount,
  ProjectAcceptanceTasksCount,
  ProjectDefinitionTasksCount,
  ProjectIncompleteSpecificationTasksCount,
  ProjectQaTasksCount,
  ProjectImplementationTasksCount,
  ProjectTagsID,
  ProjectTagsUUID,
  ProjectTagsLocalizedName,
  ProjectEstimateCost,
  ProjectDebt,
  ProjectProjectAccount,
  ProjectTasksAmountsSum,
  ProjectRequirements,
  ProjectUserID,
  ProjectMembersID,
  ProjectMembersUUID,
  ProjectMembersFullName,
  ProjectMembersImageUUID,
  ProjectMembersImageFile,
  ProjectTasksPrepaymentsSum,
  ProjectCommission,
  ProjectCreditCommission,
  ProjectHostingCommission,
  ProjectNanoID,
  ProjectUserNanoID,
  ProjectMembersNanoID,
  ProjectTasksClientPrepaymentsSum,
  ProjectMembersRoleName,
  ProjectUserRoleName,
  ProjectClientDebt,
  ProjectTasksClientStatus,
  ProjectUserFinanceRoleName,
  ProjectMembersFinanceRoleName
} from '../projectsTypes';

export interface FetchProjectsQueryResponse {
  id: ProjectID;
  uuid: ProjectUUID;
  nanoId: ProjectNanoID;
  favorite: ProjectFavorite;
  notificationsEnabled: ProjectNotificationsEnabled;
  name: ProjectName;
  burning: ProjectBurning;
  description: ProjectDescription;
  setDate: ProjectSetDate;
  implementationDate: ProjectImplementationDate;
  status: ProjectStatus;
  tasksClientStatus: ProjectTasksClientStatus;
  activeTasksCount: ProjectActiveTasksCount;
  performedTasksCount: ProjectPerformedTasksCount;
  canceledTasksCount: ProjectCanceledTasksCount;
  acceptanceTasksCount: ProjectAcceptanceTasksCount;
  definitionTasksCount: ProjectDefinitionTasksCount;
  incompleteSpecificationTasksCount: ProjectIncompleteSpecificationTasksCount;
  qaTasksCount: ProjectQaTasksCount;
  implementationTasksCount: ProjectImplementationTasksCount;
  tasksAmountsSum: ProjectTasksAmountsSum;
  tasksPrepaymentsSum: ProjectTasksPrepaymentsSum;
  tasksClientPrepaymentsSum: ProjectTasksClientPrepaymentsSum;
  createdAt: ProjectCreatedAt;
  projectAccount: ProjectProjectAccount;
  debt: ProjectDebt;
  clientDebt: ProjectClientDebt;
  estimateCost: ProjectEstimateCost;
  commission: ProjectCommission;
  creditCommission: ProjectCreditCommission;
  hostingCommission: ProjectHostingCommission;
  requirements: ProjectRequirements;
  user: {
    id: ProjectUserID;
    uuid: ProjectUserUUID;
    nanoId: ProjectUserNanoID;
    fullName: ProjectUserFullName;
    financeRoleName: ProjectUserFinanceRoleName;
    image: {
      uuid: ProjectUserImageUUID;
      file: ProjectUserImageFile;
    };
  };
  members: {
    id: ProjectMembersID;
    uuid: ProjectMembersUUID;
    nanoId: ProjectMembersNanoID;
    fullName: ProjectMembersFullName;
    financeRoleName: ProjectMembersFinanceRoleName;
    image: {
      uuid: ProjectMembersImageUUID;
      file: ProjectMembersImageFile;
    };
  }[];
  tags: {
    id: ProjectTagsID;
    uuid: ProjectTagsUUID;
    localizedName: ProjectTagsLocalizedName;
  }[];
}

export const FETCH_PROJECTS_QUERY = gql`
  query IndexProjects(
    $filters: ProjectsFilters
    $sort: [ProjectsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    projects(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        nanoId
        favorite
        notificationsEnabled
        name
        burning
        description
        setDate
        implementationDate
        status
        statusNumber
        tasksClientStatus
        activeTasksCount
        performedTasksCount
        canceledTasksCount
        acceptanceTasksCount
        definitionTasksCount
        incompleteSpecificationTasksCount
        qaTasksCount
        implementationTasksCount
        tasksAmountsSum
        tasksPrepaymentsSum
        tasksClientPrepaymentsSum
        createdAt
        projectAccount
        debt
        clientDebt
        estimateCost
        commission
        creditCommission
        hostingCommission
        requirements
        user {
          id
          uuid
          nanoId
          fullName
          financeRoleName
          image {
            uuid
            file
          }
        }
        members {
          id
          uuid
          nanoId
          fullName
          financeRoleName
          image {
            uuid
            file
          }
        }
        tags {
          id
          uuid
          localizedName
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
