import { gql } from 'graphql-request';

import { BillID, BillUUID, BillAccount, BillCurrency } from '../billsTypes';

export interface CreateBillInProjectQueryResponse {
  id: BillID;
  uuid: BillUUID;
  amount: BillAccount;
  currency: BillCurrency;
}

export const CREATE_BILL_IN_PROJECT_QUERY = gql`
  mutation CreateBillInProject(
    $projectId: ID!
    $amount: Float!
    $body: String
    $account: String!
    $allocableUser: String!
    $buttons: String!
    $currency: String!
    $transactionTagId: ID
  ) {
    createBillInProject(
      input: {
        projectId: $projectId
        amount: $amount
        body: $body
        account: $account
        allocableUser: $allocableUser
        buttons: $buttons
        currency: $currency
        transactionTagId: $transactionTagId
      }
    ) {
      status
      recordUuid
      recordNanoId
      record {
        uuid
        amount
        currency
        createdAt
        updatedAt
      }
      errors {
        projectId
        amount
        body
        account
        allocableUser
        buttons
        currency
        transactionTagId
        fullMessages
      }
    }
  }
`;
