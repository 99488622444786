import { UserID } from '../../../users/usersTypes';

import { ProjectNanoID, ProjectUUID } from '../../projectsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface AssignUserToProjectOptions {
  query: string;
}

export interface AssignUserToProjectResponse<AssignUserToProjectRecordType> {
  assignUserToProject: {
    status: string;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: AssignUserToProjectRecordType;
    errors: AssignUserToProjectError;
  };
}

export interface AssignUserToProjectInput {
  uuid: ProjectUUID;
  userId: UserID;
}

export interface AssignUserToProjectError {
  fullMessages: string[] | null;
}

const action = 'assignUserToProject';

const scope = 'project';

const pluralScope = 'projects';

function useAssignUserToProject<AssignUserToProjectRecordType>({
  query
}: AssignUserToProjectOptions) {
  const {
    updateQuery,
    updateQueryData,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading,
    updateQueryReset
  } = useUpdateQuery<
    AssignUserToProjectInput,
    AssignUserToProjectResponse<AssignUserToProjectRecordType>,
    AssignUserToProjectError,
    AssignUserToProjectRecordType
  >({ action, pluralScope, scope, query });

  return {
    assignUserToProject: updateQuery,
    assignUserToProjectData: updateQueryData,
    assignUserToProjectError: updateQueryError,
    assignUserToProjectErrorMessage: updateQueryErrorMessage,
    assignUserToProjectLoading: updateQueryLoading,
    assignUserToProjectReset: updateQueryReset
  };
}

export default useAssignUserToProject;
