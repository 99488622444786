import React from 'react';

function IndexTableHeaderEmptyCell() {
  return (
    <th
      scope="col"
      className="w-0 p-0 dark:border-gray-700 border-gray-200 border-b"
    />
  );
}

export default IndexTableHeaderEmptyCell;
