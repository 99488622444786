import React, { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  FetchProjectsCacheKey,
  FetchProjectsFilters,
  FetchProjectsLimit,
  FetchProjectsPage,
  ProjectUUID
} from '../../projectsTypes';
import { SearchFilterNanoID } from '../../../searchFilters/searchFiltersTypes';

import {
  FETCH_PROJECTS_QUERY,
  FetchProjectsQueryResponse
} from '../../queries/fetchProjects.query';
import { FETCH_SHOW_PROJECT_QUERY } from '../../queries/fetchShowProject.query';

import { usePaginatedProjects } from '../../hooks/usePaginatedProjects';
import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';
import { useTableCheckable } from '../../../../common/hooks/useTableCheckable';
import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { IndexLayout } from '../../../common/layouts/IndexLayout';

import { IndexPagePopover } from '../../../common/components/popovers/IndexPagePopover';
import { FilterProjectsPopover } from '../../components/popovers/FilterProjectsPopover';
import { ProjectsIndexPageHeader } from '../../components/headers/ProjectsIndexPageHeader';
import { ProjectsIndexPageSubHeader } from '../../components/headers/ProjectsIndexPageSubHeader';
import { ProjectsIndexSidebar } from '../../components/sidebars/ProjectsIndexSidebar';
import { ProjectsIndexTable } from '../../components/tables/ProjectsIndexTable';
import { WorkspaceSecondaryMenu } from '../../../common/components/menus/WorkspaceSecondaryMenu';
import { ProjectsIndexPageBatchActionsHeader } from '../../components/headers/ProjectsIndexPageBatchActionsHeader';

import { ProjectCache } from '../../ProjectCache';
import {
  INITIAL_PROJECTS_FILTERS,
  ProjectsPermissions
} from '../../projectsConstants';
import { projectsKeys } from '../../../../locales/keys';
import { SearchFiltersScopes } from '../../../searchFilters/searchFiltersConstants';

const searchFiltersProjectsPopoverTargetId = 'projects-text-filter-input';

interface ProjectsIndexPageProps {
  cacheKey: FetchProjectsCacheKey;
  initialPage: FetchProjectsPage;
  initialLimit: FetchProjectsLimit;
  initialFilters?: FetchProjectsFilters;
  searchFilterNanoId?: SearchFilterNanoID;
  onResetFilters?: () => void;
  onCreateSearchFilter?: (searchFilterNanoId: SearchFilterNanoID) => void;
}

function ProjectsIndexPage({
  cacheKey,
  initialPage,
  initialLimit,
  initialFilters = INITIAL_PROJECTS_FILTERS,
  searchFilterNanoId,
  onResetFilters,
  onCreateSearchFilter
}: ProjectsIndexPageProps) {
  const {
    projects,
    projectsError,
    projectsTotalCount,
    projectsFetched,
    projectsFilters,
    projectsSort,
    projectsPage,
    projectsLimit,
    projectsLoading,
    projectsIsPlaceholderData,
    updateProjectCache,
    filterProjects,
    clearProjectsFilters,
    sortProjects,
    paginateProjects,
    prefetchProjects,
    prefetchProject
  } = usePaginatedProjects<FetchProjectsQueryResponse>({
    cacheKey,
    query: FETCH_PROJECTS_QUERY,
    fetchItemCacheKey: ProjectCache.showCacheKey(),
    fetchItemQuery: FETCH_SHOW_PROJECT_QUERY,
    initialPage,
    initialLimit,
    initialFilters
  });

  const prevFilters = usePreviousValue(initialFilters);
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (prevFilters !== initialFilters) {
      filterProjects(initialFilters);
    }
  }, [prevFilters, filterProjects, initialFilters]);

  const {
    checkedHash,
    checkedAll,
    checkedItems,
    handleSetCheckedIds,
    handleCheckAll,
    handleUncheckAll
  } = useTableCheckable({ items: projects });

  const [editableProjectUuid, setEditableProjectUuid] =
    useState<ProjectUUID | null>(null);

  const handleProjectEdit = useCallback(
    (projectUuid: ProjectUUID) => {
      setEditableProjectUuid((prevState) => {
        if (prevState === projectUuid) {
          return null;
        }

        return projectUuid;
      });
    },
    [setEditableProjectUuid]
  );

  const closeProjectEdit = useCallback(
    () => setEditableProjectUuid(null),
    [setEditableProjectUuid]
  );

  return (
    <IndexLayout
      action={ProjectsPermissions.READ_PROJECTS_INDEX_PAGE}
      i18nTitle={projectsKeys.plural}
      customSecondaryMenu={
        <WorkspaceSecondaryMenu onDeleteActiveFilter={onResetFilters} />
      }
      header={
        isEmpty(checkedItems) ? (
          <ProjectsIndexPageHeader
            projectsFilters={projectsFilters}
            filterProjects={filterProjects}
          />
        ) : (
          <ProjectsIndexPageBatchActionsHeader
            checkedProjects={checkedItems}
            onUncheckAll={handleUncheckAll}
          />
        )
      }
      popover={
        <IndexPagePopover
          searchFilterNanoId={searchFilterNanoId}
          filtersPopover={
            <FilterProjectsPopover
              clearProjectsFilters={clearProjectsFilters}
              filterProjects={filterProjects}
              isLoading={projectsLoading}
              onResetFilters={onResetFilters}
              onCreateSearchFilter={onCreateSearchFilter}
              projectsFilters={projectsFilters}
            />
          }
          onDeleteActiveFilter={onResetFilters}
          popoverTargetId={searchFiltersProjectsPopoverTargetId}
          scope={SearchFiltersScopes.PROJECTS_INDEX}
          withSearchFiltersPopover={currentUser.hasPermissions(
            ProjectsPermissions.READ_PROJECTS_SEARCH_FILTERS_POPOVER
          )}
        />
      }
      sidebar={
        editableProjectUuid ? (
          <ProjectsIndexSidebar
            projectUuid={editableProjectUuid}
            projects={projects}
            onClose={closeProjectEdit}
          />
        ) : null
      }
      onCloseSidebar={closeProjectEdit}
    >
      <ProjectsIndexPageSubHeader
        projectsFilters={projectsFilters}
        filterProjects={filterProjects}
      />
      <ProjectsIndexTable
        projects={projects}
        projectsFetched={projectsFetched}
        projectsError={projectsError}
        projectsIsPlaceholderData={projectsIsPlaceholderData}
        projectsSort={projectsSort}
        projectsPage={projectsPage}
        projectsLimit={projectsLimit}
        projectsTotalCount={projectsTotalCount}
        checkedHash={checkedHash}
        checkedAll={checkedAll}
        onSetCheckedIds={handleSetCheckedIds}
        onCheckAll={handleCheckAll}
        updateProjectCache={updateProjectCache}
        paginateProjects={paginateProjects}
        prefetchProjects={prefetchProjects}
        sortProjects={sortProjects}
        onProjectEdit={handleProjectEdit}
        onProjectEditMouseOver={prefetchProject}
        selectedProjectUuid={editableProjectUuid}
      />
    </IndexLayout>
  );
}

export default ProjectsIndexPage;
