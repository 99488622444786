import {
  FetchProjectsFilterProjects,
  FetchProjectsFilters,
  ProjectFields,
  FetchProjectsFilterFields
} from '../../../projectsTypes';

import { IndexTablePageSubHeader } from '../../../../common/components/headers/IndexTablePageSubHeader';

import { BooleanFilterBadge } from '../../../../../helpers/filterBadges/BooleanFilterBadge';
import { DateRangeFilterBadge } from '../../../../../helpers/filterBadges/DateRangeFilterBadge';
import { StringFilterBadge } from '../../../../../helpers/filterBadges/StringFilterBadge';
import { TagsFilterBadge } from '../../../../../helpers/filterBadges/TagsFilterBadge';
import { ProjectStatusesFilterBadge } from '../../../helpers/filterBadges/ProjectStatusesFilterBadge';
import { UsersFilterBadge } from '../../../../../helpers/filterBadges/UsersFilterBadge';

import { isEveryPropEmpty } from '../../../../../utils/isEveryPropEmpty';

import { formsFields, words } from '../../../../../locales/keys';

export const ignoredProjectsFilters = [ProjectFields.NAME];

interface ProjectsIndexPageSubHeaderProps {
  projectsFilters: FetchProjectsFilters;
  filterProjects: FetchProjectsFilterProjects;
}

function ProjectsIndexPageSubHeader({
  projectsFilters,
  filterProjects
}: ProjectsIndexPageSubHeaderProps) {
  if (isEveryPropEmpty(projectsFilters, ignoredProjectsFilters)) {
    return null;
  }

  return (
    <IndexTablePageSubHeader>
      <StringFilterBadge
        filters={projectsFilters}
        i18nLabel={formsFields.rankedName}
        name={FetchProjectsFilterFields.RANKED_NAME}
        onRemoveFilter={filterProjects}
      />
      <TagsFilterBadge
        filters={projectsFilters}
        name={FetchProjectsFilterFields.TAG_IDS}
        onRemoveFilter={filterProjects}
      />
      <UsersFilterBadge
        filters={projectsFilters}
        name={FetchProjectsFilterFields.PERFORMER_IDS}
        onRemoveFilter={filterProjects}
      />
      <ProjectStatusesFilterBadge
        filters={projectsFilters}
        name={FetchProjectsFilterFields.STATUS}
        onRemoveFilter={filterProjects}
      />
      <DateRangeFilterBadge
        filters={projectsFilters}
        name={FetchProjectsFilterFields.CREATED_AT}
        i18nLabel={formsFields.createdAt}
        onRemoveFilter={filterProjects}
      />
      <DateRangeFilterBadge
        filters={projectsFilters}
        name={FetchProjectsFilterFields.IMPLEMENTATION_DATE}
        i18nLabel={formsFields.implementationDate}
        onRemoveFilter={filterProjects}
      />
      <BooleanFilterBadge
        filters={projectsFilters}
        i18nLabel={formsFields.burning}
        name={FetchProjectsFilterFields.BURNING}
        onRemoveFilter={filterProjects}
      />
      <BooleanFilterBadge
        filters={projectsFilters}
        i18nLabel={words.favorite}
        name={FetchProjectsFilterFields.FAVORITE}
        onRemoveFilter={filterProjects}
      />
    </IndexTablePageSubHeader>
  );
}

export default ProjectsIndexPageSubHeader;
