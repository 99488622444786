import { gql } from 'graphql-request';

export const CREATE_PROJECT_QUERY = gql`
  mutation CreateProject(
    $name: String!
    $description: String
    $estimateCost: Float
    $implementationDate: DateTime
    $fileAttachmentIds: [ID!]
    $taskNames: [String!]
  ) {
    createProject(
      input: {
        name: $name
        description: $description
        estimateCost: $estimateCost
        implementationDate: $implementationDate
        fileAttachmentIds: $fileAttachmentIds
        taskNames: $taskNames
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
        nanoId
      }
      errors {
        name
        description
        estimateCost
        implementationDate
        fileAttachmentIds
        taskNames
        fullMessages
      }
    }
  }
`;
